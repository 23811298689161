import { FaWallet, FaRegClock, FaRegUser, FaDollarSign } from "react-icons/fa";
import { BiWalletAlt, Bi, BiTransfer } from "react-icons/bi";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { CgKeyhole } from "react-icons/cg";
import { TbTransferIn } from "react-icons/tb";
import { IoMdSettings } from "react-icons/io";
import { MdOutlineAccountBalance, MdOutlineWhatsapp } from "react-icons/md";
import HomeIcon from "../assets/images/home.png";
import casinoIcon from "../assets/images/casino.png";
import sportIcon from "../assets/images/sports.png";
import leadgeIcon from "../assets/images/leadge.png";
import betsIcon from "../assets/images/mybets.png";
import banner1 from "../assets/images/CRICKET/1.webp";
import banner2 from "../assets/images/CRICKET/2.webp";
import banner3 from "../assets/images/CRICKET/3.webp";
import banner4 from "../assets/images/CRICKET/banner.webp";
import { RiLuggageDepositLine } from "react-icons/ri";
import { BiMoneyWithdraw } from "react-icons/bi";
import { MdSupportAgent } from "react-icons/md";
import c1 from "../assets/images/c1.webp";
import c2 from "../assets/images/c2.webp";
import c3 from "../assets/images/c3.webp";
import c4 from "../assets/images/c4.webp";
import c5 from "../assets/images/c5.webp";
import c6 from "../assets/images/c6.webp";
import c7 from "../assets/images/c7.webp";
import c8 from "../assets/images/c8.webp";
import c9 from "../assets/images/c9.webp";
import c10 from "../assets/images/c10.webp";
import c11 from "../assets/images/c11.webp";
import c12 from "../assets/images/c12.webp";
import { IoMdLogIn } from "react-icons/io";

import Cricket from "../assets/images/Cricket.webp";
import Inplay from "../assets/images/InPlay.webp";
import Sports from "../assets/images/Sports.webp";
import Soccer from "../assets/images/Soccer.webp";
import IndianPoker from "../assets/images/IndianPoker.webp";
import Vivo from "../assets/images/Roulette.webp";
import Ezugi from "../assets/images/Ezugi.webp";
import Evoultion from "../assets/images/casino-icon.svg";
import Aviator from "../assets/images/Aviator.webp";

import spribe from "../assets/NewImages/SPRIBE.webp";
import Jili from "../assets/NewImages/jilii.png";
import fc from "../assets/NewImages/fc.png";
import jdb from "../assets/NewImages/jdb.png";
import km from "../assets/NewImages/km.png";
import pg from "../assets/NewImages/pg.png";
import pt from "../assets/NewImages/pt.png";
import rt from "../assets/NewImages/REDTIGER.webp";
import sexy from "../assets/NewImages/SEXYBCRT.webp";
import sg from "../assets/NewImages/SPADEGAMING.webp";
import evo from "../assets/NewImages/evo.png";
import sv388 from "../assets/NewImages/SV388.webp";
import pp from "../assets/NewImages/pp.png";
import yesbingo from "../assets/NewImages/provider-awcmyesbingo.png";
import netent from "../assets/NewImages/provider-netent.png";
import nlcLogo from "../assets/NewImages/nl-yellow.svg";
import betgames from "../assets/NewImages/BETGAMES.webp";
import esport from "../assets/NewImages/E1SPORT.webp";
import fastspin from "../assets/NewImages/FASTSPIN.webp";
import iconhotroad from "../assets/NewImages/hotroad.png";
import joker from "../assets/NewImages/provider-joker.png";
import p8 from "../assets/NewImages/provider-awcmp8.png";
import btg from "../assets/NewImages/btg.png";
import dragonsoft from "../assets/NewImages/DRAGONSOFT.webp";
import iconSportbook from "../assets/NewImages/icon-sportbook.png";
import VivoImage from "../assets/images/vivo.png";
export const NavDepositWithrawData = [
  {
    icons: Inplay,
    title: "In Play",
    firstTab: "InPlay",
    SecondTab: "all",
  },
  {
    icons: Cricket,
    title: "Cricket",
    firstTab: "Cricket",
    SecondTab: "cricket",
  },
  {
    icons: Sports,
    title: "Tennis",
    firstTab: "Tennis",
    SecondTab: "tennis",
  },
  {
    icons: Soccer,
    title: "Soccer",
    firstTab: "Soccer",
    SecondTab: "soccer",
  },
  {
    icons: IndianPoker,
    title: "Indian Poker",
    firstTab: "poker",
  },
  // {
  //   icons: Vivo,
  //   title: "Vivo",
  //   firstTab: "vivo",
  // },
  // {
  //   icons: Ezugi,
  //   title: "Ezugi",
  //   firstTab: "ezugi",
  // },
  // {
  //   icons: Evoultion,
  //   title: "Evoultion",
  //   firstTab: "evolution",
  // },
  {
    icons: Aviator,
    title: "Aviator",
    firstTab: "aviator",
  },
  // {
  //   icons: Casino,
  //   title: "Live",
  //   firstTab: "live",
  // },
  {
    icons: "../assets/images/casino-icon/hover-table.svg",
    title: "Table",
    firstTab: "TABLE",
  },
  {
    icons: "../assets/images/casino-icon/hover-slot.svg",
    title: "Slot",
    firstTab: "SLOT",
  },
  {
    icons: "../assets/images/casino-icon/hover-fishing.svg",
    title: "Fishing",
    firstTab: "FISHING",
  },
  {
    icons: "../assets/images/casino-icon/hover-awc.svg",
    title: "AWC",
    firstTab: "AWC",
  },
  {
    icons: "../assets/images/casino-icon/hover-egame.svg",
    title: "Egame",
    firstTab: "EGAME",
  },

  {
    icons: "../assets/images/casino-icon/hover-live.svg",
    title: "Live",
    firstTab: "LIVE",
  },
];

export const NavDepositWithrawData2 = [
  {
    icons: IndianPoker,
    title: "Indian Poker",
    firstTab: "poker",
  },
  {
    icons: Aviator,
    title: "Evolution",
    firstTab: "Evolution",
  },
  {
    icons: Aviator,
    title: "Casino",
    firstTab: "Casino",
  },
  {
    icons: Aviator,
    title: "Ezugi",
    firstTab: "Ezugi",
  },
  {
    icons: Aviator,
    title: "Royal Gamming",
    firstTab: "royal",
  }, {
    icons: Aviator,
    title: "Vivo",
    firstTab: "Vivo",
  },
  {
    icons: Aviator,
    title: "Aviator",
    firstTab: "aviator",
  },
  {
    icons: Aviator,
    title: "Sexy",
    firstTab: "Sexy",
  },  {
    icons: Aviator,
    title: "Bet Games",
    firstTab: "BetGames",
  },
  {
    icons: "../assets/images/casino-icon/hover-table.svg",
    title: "Table",
    firstTab: "TABLE",
  },
  {
    icons: "../assets/images/casino-icon/hover-slot.svg",
    title: "Slot",
    firstTab: "SLOT",
  },
  {
    icons: "../assets/images/casino-icon/hover-fishing.svg",
    title: "Fishing",
    firstTab: "FISHING",
  },
  {
    icons: "../assets/images/casino-icon/hover-awc.svg",
    title: "AWC",
    firstTab: "AWC",
  },
  {
    icons: "../assets/images/casino-icon/hover-egame.svg",
    title: "Egame",
    firstTab: "EGAME",
  },
  {
    icons: "../assets/images/casino-icon/hover-live.svg",
    title: "Live",
    firstTab: "LIVE",
  },
];

export const bannerSlider = [
  { id: 1, img: banner1 },
  { id: 2, img: banner2 },
  {
    id: 3,
    img: banner3,
  },
  { id: 4, img: banner4 },
  // { id: 5, img: banner5 },
];
export const PokerGames = [
  {
    image: "../assets/images/Aura/auraLobby.webp",
    title: "Lobby",
    gameCode: "98789",
    legacy: "",
  },

  {
    image: "../assets/images/poker/1.webp",
    title: "7 up & 7 down",
    gameCode: "98789",
    legacy: "98789_2",
  },
  {
    image: "../assets/images/poker/2.webp",
    title: "Live Teenpati",
    gameCode: "56767",
    legacy: "56767_2",
  },
  {
    image: "../assets/images/poker/3.webp",
    title: "Teenpatti T20",
    gameCode: "56768",
    legacy: "56768_2",
  },
  {
    image: "../assets/images/poker/4.webp",
    title: "Dragon Tiger",
    gameCode: "98790",
    legacy: "98790_2",
  },
  {
    image: "../assets/images/poker/5.webp",
    title: "Hi Low",
    gameCode: "56968",
    legacy: "56968_2",
  },
  {
    image: "../assets/images/poker/6.webp",
    title: "Worli Matka",
    gameCode: "92037",
    legacy: "92037_2",
  },
  {
    image: "../assets/images/poker/7.webp",
    title: "Poker",
    gameCode: "67564",
    legacy: "67564_2",
  },
  {
    image: "../assets/images/poker/8.webp",
    title: "32 Cards Casino",
    gameCode: "56967",
    legacy: "56967_2",
  },
  {
    image: "../assets/images/poker/9.webp",
    title: "Andhar Bahar",
    gameCode: "87564",
    legacy: "87564_2",
  },
  {
    image: "../assets/images/poker/10.webp",
    title: "Amar Akbar Anthony",
    gameCode: "98791",
    legacy: "98791_2",
  },
  {
    image: "../assets/images/poker/11.webp",
    title: "Race 20-20",
    gameCode: "90100",
    legacy: "90100_2",
  },
  {
    image: "../assets/images/poker/12.webp",
    title: "Bollywood Casino",
    gameCode: "67570",
    legacy: "67570_2",
  },
  {
    image: "../assets/images/poker/13.webp",
    title: "Six Player Poker",
    gameCode: "67565",
    legacy: "67565_2",
  },
  {
    image: "../assets/images/poker/14.webp",
    title: "Roulette",
    gameCode: "98788",
    legacy: "98788_2",
  },
  {
    image: "../assets/images/poker/15.webp",
    title: "Sic-bo",
    gameCode: "98566",
    legacy: "98566_2",
  },
  {
    image: "../assets/images/poker/16.webp",
    title: "Mufflis Teenpati",
    gameCode: "67601",
    legacy: "67601_3",
  },
  {
    image: "../assets/images/poker/17.webp",
    title: "Trio",
    gameCode: "67610",
    legacy: "67610_2",
  },
  {
    image: "../assets/images/poker/18.webp",
    title: "Baccarat",
    gameCode: "92038",
    legacy: "92038_2",
  },
  {
    image: "../assets/images/poker/19.webp",
    title: "Casino Meter",
    gameCode: "67575",
    legacy: "67575_2",
  },
  {
    image: "../assets/images/poker/20.webp",
    title: "Poker 20-20",
    gameCode: "67567",
    legacy: "67567_2",
  },
  {
    image: "../assets/images/poker/21.webp",
    title: "2 Card Teenpati",
    gameCode: "67660",
    legacy: "67660_2",
  },
  {
    image: "../assets/images/poker/22.webp",
    title: "Queen Race",
    gameCode: "67620",
    legacy: "67620_2",
  },
  {
    image: "../assets/images/poker/23.webp",
    title: "Teenpati Test",
    gameCode: "67630",
    legacy: "67630_2",
  },
  {
    image: "../assets/images/poker/24.webp",
    title: "Casino War",
    gameCode: "67580",
    legacy: "67580_2",
  },
  {
    image: "../assets/images/poker/25.webp",
    title: "The Trap",
    gameCode: "67680",
    legacy: "67680_2",
  },
  {
    image: "../assets/images/poker/26.webp",
    title: "Teenpati Open",
    gameCode: "67640",
    legacy: "67640_2",
  },
  {
    image: "../assets/images/poker/27.webp",
    title: "29 Card Baccarat",
    gameCode: "67690",
    legacy: "67690_2",
  },
  {
    image: "../assets/images/poker/28.webp",
    title: "Race to 17",
    gameCode: "67710",
    legacy: "67710_2",
  },
  {
    image: "../assets/images/poker/29.webp",
    title: "Roulette (Virtual)",
    gameCode: "98792",
    legacy: "98792_3",
  },
  {
    image: "../assets/images/poker/30.webp",
    title: "Dragon Tiger (Virtual)",
    gameCode: "98794",
    legacy: "98794_3",
  },
  {
    image: "../assets/images/poker/31.webp",
    title: "Amar Akbar Anthony (Virtual)",
    gameCode: "98795",
    legacy: "98795_3",
  },
  {
    image: "../assets/images/poker/32.webp",
    title: "Six Player Poker (Virtual)",
    gameCode: "67566",
    legacy: "67566_3",
  },
  {
    image: "../assets/images/poker/33.webp",
    title: "Poker (Virtual)",
    gameCode: "67563",
    legacy: "67563_3",
  },
  {
    image: "../assets/images/poker/34.webp",
    title: "32 Card Casino (Virtual)",
    gameCode: "56966",
    legacy: "56966_3",
  },
  {
    image: "../assets/images/poker/35.webp",
    title: "Teenpatti One-Day (Virtual)",
    gameCode: "56766",
    legacy: "56766_3",
  },
  {
    image: "../assets/images/poker/36.webp",
    title: "Andhar Bahar (Virtual)",
    gameCode: "87565",
    legacy: "87565_3",
  },
  {
    image: "../assets/images/poker/37.webp",
    title: "Teenpatti T20 (Virtual)",
    gameCode: "56769",
    legacy: "56769_3",
  },
  {
    image: "../assets/images/poker/38.webp",
    title: "Hi Low (Virtual)",
    gameCode: "56969",
    legacy: "56969_3",
  },
  {
    image: "../assets/images/poker/39.webp",
    title: "Matka (Virtual)",
    gameCode: "92036",
    legacy: "92036_3",
  },
  {
    image: "../assets/images/poker/40.webp",
    title: "7 Up & Down (Virtual)",
    gameCode: "98793",
    legacy: "98793_3",
  },
];
export const Aura = [
  { image: "../assets/images/Aura/auraLobby.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/2CardTeenPatti.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/20Poker.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/29Card.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/32CardCasino.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/amarAkbarAnthony.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/bollywoodCasino.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/matka.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/poker.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/roulette.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/teenPatti.webp", gameCode: "77409" },
  { image: "../assets/images/Aura/teenPatti2.webp", gameCode: "77409" },

  { image: "../assets/images/AuraNew/6PlayerPoker.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/7UpandDown.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/7UpDown.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/andharBahar.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/baccarat.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/casinoMeter.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/casinoWar.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/dragonTiger.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/higherLower.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/higherLower2.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/muflisTeenPatti.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/queenRace.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/race.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/sicbo.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/teenPatti.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/teenPattiTest.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/theTrap.webp", gameCode: "77409" },
  { image: "../assets/images/AuraNew/trio.webp", gameCode: "77409" },
];
export const EvolutionGames = [
  // { image: "../assets/images/poker/evoluation1.webp", title: "7 up & 7 down",gameCode:"21002" },
  // { image: "../assets/images/poker/evoluation2.webp", title: "7 up & 7 down",gameCode:"21300" },
  {
    image: "../assets/images/poker/evoluation3.webp",
    title: "",
    gameCode: "103518",
  },

  // {
  //   image: "../assets/images/poker/DICE-7.webp",
  //   title: "",
  //   gameCode: "21464",
  //   id: "RngSicbo00000001",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-19.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "frenchroulettene",
  // },
  // {
  //   image: "../assets/images/poker/PT-LIVE-017.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "casinoholdemne00",
  // },
  // {
  //   image: "../assets/images/poker/BACCARAT-7.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "baccarat2ne00000",
  // },
  // {
  //   image: "../assets/images/poker/EVOLUTION-LIVE-204.png",
  //   title: "",
  //   gameCode: "21296",
  //   id: "AndarBahar000001",
  // },
  // {
  //   image: "../assets/images/poker/BACCARAT-5.webp",
  //   title: "",
  //   gameCode: "21280",
  //   id: "rng-lbaccarat000",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-12.webp",
  //   title: "",
  //   gameCode: "21257",
  //   id: "SpeedAutoRo00001",
  // },
  // {
  //   image: "../assets/images/poker/GAME-SHOW-2.webp",
  //   title: "",
  //   gameCode: "21254",
  //   id: "CashOrCrash00001",
  // },
  // {
  //   image: "../assets/images/poker/GAME-SHOW-14.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "MegaBall00000001",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-11.webp",
  //   title: "",
  //   gameCode: "21190",
  //   id: "01rb77cq1gtenhmo",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-12.webp",
  //   title: "",
  //   gameCode: "21187",
  //   id: "lkcbrbdckjxajdol",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-18.webp",
  //   title: "",
  //   gameCode: "21027",
  //   id: "wzg6kdkad1oe7m5k",
  // },
  // {
  //   image: "../assets/images/poker/EVOLUTION-LIVE-009.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "n5emwq5c5dwepwam",
  // },
  // {
  //   image: "../assets/images/poker/GAME-SHOW-6.webp",
  //   title: "",
  //   gameCode: "21018",
  //   id: "LightningDice001",
  // },
  // {
  //   image: "../assets/images/poker/TRADINATIONAL-4.webp",
  //   title: "",
  //   gameCode: "21012",
  //   id: "DragonTiger00001",
  // },
  // {
  //   image: "../assets/images/poker/EVOLUTION-LIVE-015.webp",
  //   title: "",
  //   gameCode: "21010",
  //   id: "HoldemTable00001",
  // },
  // {
  //   image: "../assets/images/poker/ROULETTE-16.webp",
  //   title: "",
  //   gameCode: "21002",
  //   id: "AmericanTable001",
  // },
  // {
  //   image: "../assets/images/poker/Ultimate-Texas-Hold-em.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "UTHTable00000001",
  // },
  // {
  //   image: "../assets/images/poker/Lightning-lotto.webp",
  //   title: "",
  //   gameCode: "21456",
  //   id: "Lightning6000001",
  // },
  // {
  //   image: "../assets/images/poker/King-of-Slot.webp",
  //   title: "",
  //   gameCode: "21000",
  //   id: "kingofslots00000",
  // },
];
export const LiveGames = [
  { image: "../assets/images/poker/live1.webp", gameCode: "77409" },
  { image: "../assets/images/poker/live2.webp", gameCode: "5268" },
  {
    image: "../assets/images/poker/live3.webp",
    gameCode: "531914",
    message: "Comming Soon",
  },
  { image: "../assets/images/poker/live4.webp", gameCode: "523474" },
  {
    image: "../assets/images/poker/live5.webp",
    gameCode: "521401",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live6.webp",
    gameCode: "5115",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live7.webp",
    gameCode: "534714",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live8.webp",
    gameCode: "77409",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live9.webp",
    gameCode: "77409",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live10.webp",
    gameCode: "531914",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live11.webp",
    gameCode: "65068",
    message: "Comming Soon",
  },
  {
    image: "../assets/images/poker/live12.webp",
    gameCode: "77409",
    message: "Comming Soon",
  },
  { image: "../assets/images/poker/live13.webp", gameCode: "531932" },
];
export const VivoGames = [
  // {
  //   image: "../assets/images/poker/vivoGamming.png",
  //   gameCode: "77257",
  //   size: "size",
  // },
  { image: "../assets/images/poker/vivo2.webp", gameCode: "75695" },
  { image: "../assets/images/poker/vivo3.webp", gameCode: "250416" },
  { image: "../assets/images/poker/vivo4.webp", gameCode: "250413" },
  { image: "../assets/images/poker/vivo5.webp", gameCode: "75695" },
  { image: "../assets/images/poker/vivo6.webp", gameCode: "82701" },
  { image: "../assets/images/poker/vivo7.webp", gameCode: "82690" },
  { image: "../assets/images/poker/vivo8.webp", gameCode: "82703" },
  { image: "../assets/images/poker/vivo9.webp", gameCode: "75694" },
  { image: "../assets/images/poker/vivo10.webp", gameCode: "75694" },
  { image: "../assets/images/poker/vivo11.webp", gameCode: "75694" },
  { image: "../assets/images/poker/vivo12.webp", gameCode: "75694" },
  { image: "../assets/images/poker/vivo13.webp", gameCode: "75694" },
];

export const AisaGames = [
  {
    image: "../assets/images/poker/asiaGamming.png",
    gameCode: "77257",
    size: "contain",
  },
  // { image: "../assets/images/poker/betGames2.webp",gameCode:"4005"},
  // { image: "../assets/images/poker/betGames3.webp",gameCode:"4004"},
  // { image: "../assets/images/poker/betGames4.webp",gameCode:"4003"},
  // { image: "../assets/images/poker/betGames5.webp",gameCode:"4002"},
  // { image: "../assets/images/poker/betGames6.webp",gameCode:"4002"},
  // { image: "../assets/images/poker/betGames7.webp",gameCode:"4001"},
  // { image: "../assets/images/poker/betGames8.webp",gameCode:"4001"},
  // { image: "../assets/images/poker/betGames9.webp",gameCode:"4001"},
  // { image: "../assets/images/poker/betGames10.webp",gameCode:"4001"},
  // { image: "../assets/images/poker/betGames11.webp",gameCode:"4007"},
  // { image: "../assets/images/poker/betGames12.webp",gameCode:"4006"},
];

export const AviatorGames = [
  {
    image: "../assets/images/poker/avaitrix.jpg",
    gameCode: "9999",
    legacy: "67722-1_8",
  },
  {
    image: "../assets/images/poker/aviator1.webp",
    gameCode: "9999",
    legacy: "67722-2_8",
  },
  // { image: "../assets/images/poker/aviator2.webp", gameCode: "1190530" },
  // { image: "../assets/images/poker/aviator3.webp", gameCode: "1190535" },
  // { image: "../assets/images/poker/aviator4.webp", gameCode: "1190534" },
  // { image: "../assets/images/poker/aviator5.webp", gameCode: "1190532" },
  // { image: "../assets/images/poker/aviator6.webp", gameCode: "1190529" },
  // { image: "../assets/images/poker/aviator7.webp", gameCode: "1190528" },
  // { image: "../assets/images/poker/aviator8.webp", gameCode: "1190531" },
];
export const sidebarData = [
  {
    firstLevel: [
      // {id: 1,menu: "Upline Whatsapp Number",icon: <MdOutlineWhatsapp />,link: "/upline-whatsapp-number"},
      {
        id: 2,
        menu: "Balance Overview",
        icon: <FaWallet />,
        link: "/balance-overview",
      },
      {
        id: 3,
        menu: "Account Statement",
        icon: <MdOutlineAccountBalance />,
        link: "/account-statement",
      },
      {
        id: 4,
        menu: "Current Bets",
        icon: <BiWalletAlt />,
        link: "/current-bets",
      },
      {
        id: 5,
        menu: "Bets History",
        icon: <FaRegClock />,
        link: "/bets-history",
      },
      {
        id: 6,
        menu: "Profit & Loss",
        icon: <AiOutlineDollarCircle />,
        link: "/profit-and-loss",
      },
      {
        id: 2,
        menu: "Turnover",
        icon: <FaDollarSign/>,
        link: "/turnover",
        menubn: "টার্নওভার",
      },
      { id: 7, menu: "Active Log", icon: <CgKeyhole />, link: "/active-log" },
      { id: 8, menu: "My Profile", icon: <FaRegUser />, link: "/my-profile" },
    ],
  },
  {
    secondLevel: [
      {
        // id: 8,
        menu: "Deposit",
        icon: <RiLuggageDepositLine />,
        link: "/deposit",
      },
      {
        id: 8,
        menu: "Deposit History",
        icon: <BiTransfer />,
        link: "/deposit-history",
      },
      {
        // id: 9,
        menu: "Withdraw",
        icon: <BiMoneyWithdraw />,
        link: "/withdraw",
      },
      {
        id: 9,
        menu: "Withdraw History",
        icon: <TbTransferIn />,
        link: "/withdraw-history",
      },
    ],
  },

  {
    thirdLevel: [
      { id: 10, menu: "Setting", icon: <IoMdSettings />, link: "/setting" },
      { id: 17, menu: "Customer Support", icon: <MdSupportAgent />, link: "/customer-support" },
    ],
  },
];

export const bottomNavigation = [
  {
    id: "3",
    menu: "In Play",
    icon: sportIcon,
    link: "/sports",
  },
  {
    id: "6",
    menu: "Sports",
    icon: leadgeIcon,
    link: "/sports",
  },
  {
    id: "1",
    menu: "Home",
    icon: HomeIcon,
    link: "/",
  },
  {
    id: "2",
    menu: "Casino",
    icon: casinoIcon,
    link: "/casino",
  },
  {
    id: "5",
    menu: "My Account",
    icon: betsIcon,
  },
];
export const popularArray = [
  {
    gameCode: 101313,
    image: "../assets/images/evolution-new.webp",
    id: "",
  },
  // {
  //   gameCode: 5269,
  //   image: "../assets/images/poker/unlimatedSicbo.webp",
  //   id: "224100",
  // },
  // {
  //   gameCode: 5252,
  //   image: "../assets/images/poker/DICE-7.webp",
  //   id: "224000",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/Grand-Wheel.webp",
  //   id: "266",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/GAME-SHOW-4.webp",
  //   id: "180",
  // },
  // {
  //   gameCode: 5114,
  //   image: "../assets/images/poker/TABLE-13.webp",
  //   id: "228002",
  // },

  // {
  //   gameCode: 5074,
  //   image: "../assets/images/poker/KM-TABLE-032.webp",
  //   id: "228000",
  // },
  // {
  //   gameCode: 5068,
  //   image: "../assets/images/poker/TRADINATIONAL-5.webp",
  //   id: "227100",
  // },
  // {
  //   gameCode: 5048,
  //   image: "../assets/images/poker/ROULETTE-12.webp",
  //   id: "221002",
  // },
  // {
  //   gameCode: 5046,
  //   image: "../assets/images/poker/PT-LIVE-010.webp",
  //   id: "5051",
  // },
  // {
  //   gameCode: 5035,
  //   image: "../assets/images/poker/TABLE-15.webp",
  //   id: "606000",
  // },
  // {
  //   gameCode: 5066,
  //   image: "../assets/images/poker/namasteRoullete2.jpg",
  //   id: "411000",
  // },
  // {
  //   gameCode: 5059,
  //   image: "../assets/images/poker/vipBlackjack.jpg",
  //   id: "225",
  // },
  // {
  //   gameCode: 5051,
  //   image: "../assets/images/poker/lucky7.webp",
  //   id: "228001",
  // },
  // {
  //   gameCode: 5050,
  //   image: "../assets/images/poker/diamondRoullete.jpg",
  //   id: "221003",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/devilNumber2.jpg",
  //   id: "257",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/Atlantis.jpg",
  //   id: "249",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/MegaDragon.jpg",
  //   id: "277",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/lucky-wizard.webp",
  //   id: "274",
  // },
  // {
  //   gameCode: 5266,
  //   image: "../assets/images/poker/VIPFortuneBaccarat.jpg",
  //   id: "106",
  // },
  // {
  //   gameCode: 5001,
  //   image: "../assets/images/poker/BaccaratPro2.jpg",
  //   id: "41101",
  // },
  // {
  //   gameCode: 5271,
  //   image: "../assets/images/poker/pokerRoyal.webp",
  //   id: "537000",
  // },
  // {
  //   gameCode: 5270,
  //   image: "../assets/images/poker/cricketWar.webp",
  //   id: "45100",
  // },

  // {
  //   ProductId: 21000,
  //   image: "../assets/images/evolution.webp",
  // },
];

export const gamesLiveArray = [
  {
    img: "../assets/images/game-shows/GAME-SHOW-1.webp",
    name: "XXXtreme Lightning roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-205",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-2.webp",
    name: "Cash or Crash",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-175",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-3.webp",
    name: "Funky Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-183",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-4.webp",
    name: "Dead or Alive Saloon",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-176",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-5.webp",
    name: "Crazy Time",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-6.webp",
    name: "Lightning Dice",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-011",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-7.webp",
    name: "Lightning Roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-040",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-8.webp",
    name: "Lightning Fever",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-133",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-9.webp",
    name: "Extra Chilli",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-168",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-10.webp",
    name: "Imperial Quest",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-169",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-11.webp",
    name: "Monopoly Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-007",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-12.webp",
    name: "Monopoly Big Baller",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-018",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-13.webp",
    name: "Crazy Coin Flip",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-014",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-14.webp",
    name: "Mega Ball",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-010",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-15.webp",
    name: "Deal or Nodeal",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-025",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/EVOLUTION-LIVE-246.png",
    name: "Gonzo's Treasure Map",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-246",
    cateogry: "EVO",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-075.webp",
    name: "Auto Mega Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-075",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-16.webp",
    name: "Wounderland",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-011",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-17.webp",
    name: "Shweet Bonanza Candy Land",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-044",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-18.webp",
    name: "Spin a Win Wild live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-015",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-19.webp",
    name: "Spin and Win Wild B X2",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-088",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-20.webp",
    name: "The Greatest Cards Show",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-086",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-21.webp",
    name: "Mega Wheel",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-038",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-22.webp",
    name: "Mega Fire Blaze Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-028",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-23.webp",
    name: "Mega Roulette 500x",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-039",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-24.webp",
    name: "Mega Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-061",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-25.webp",
    name: "Quantum Auto Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-026",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-26.webp",
    name: "Power Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-055",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },

  {
    img: "../assets/images/game-shows/GAME-SHOW-27.webp",
    name: "Sicbo Delux",
    gameType: "LIVE",
    platForm: "PT",
    casinoType: "PT-LIVE-003",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-28.webp",
    name: "Mega Sicbo",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-025",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-29.webp",
    name: "Bet on Dragon Tiger",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-030",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-30.webp",
    name: "Hi-lo Club",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-014",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-077.webp",
    name: "Lucky 6 Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-077",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/GAME-SHOW-32.webp",
    name: "Italian Cashback Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-031",
    cateogry: "PT",
    type: "GAME-SHOWS",
  },
  {
    img: "../assets/images/game-shows/PP-LIVE-076.webp",
    name: "Lucky 6 Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-076",
    cateogry: "PP",
    type: "GAME-SHOWS",
  },

  // {
  //   img: "../assets/images/game-shows/GAME-SHOW-31.webp",
  //   name: "Quantum BlackJack Plus",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-029",
  //   cateogry: "PT",
  //   type: "GAME-SHOWS",
  // },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "1",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "2",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "3",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "4",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "5",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-001.webp",
    name: "HOTROAD-LIVE-001",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6001",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6002",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6003",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6004",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6005",
  },
  {
    img: "../assets/images/Baccarat/HOTROAD-LIVE-007.webp",
    name: "HOTROAD-LIVE-007",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-007",
    cateogry: "HOTROAD",
    type: "BACCARAT",
    table: "6006",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-1.webp",
    name: "Golden Wealth Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-179",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-2.webp",
    name: "Baccarat no commission",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-038",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-3.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-159",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-4.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-134",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-5.webp",
    name: "Lightning Baccarat First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-255",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-6.webp",
    name: "Speed Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-210",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-245.png",
    name: "Prosperity Tree Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-245",
    cateogry: "EVO",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/EVOLUTION-LIVE-244.png",
    name: "First Person Prosperity Tree Baccarat",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-244",
    cateogry: "EVO",
    type: "BACCARAT",
  },

  {
    img: "../assets/images/Baccarat/BACCARAT-7.webp",
    name: "Baccarat Classic",
    table: "1",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-8.webp",
    name: "Baccarat Classic",
    table: "2",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-9.webp",
    name: "Baccarat Classic",
    table: "3",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-10.webp",
    name: "Baccarat Classic",
    table: "4",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-11.webp",
    name: "Baccarat Classic",
    table: "5",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-12.webp",
    name: "Baccarat Classic",
    table: "6",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    cateogry: "SEXY",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/PT-LIVE-109.png",
    name: "Bet on Baccarat Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-109",
    cateogry: "PT",
    type: "BACCARAT",
  },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-13.webp",
  //   name: "Bet on Baccarat Live",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-005",
  //   cateogry: "PT",
  //   type: "BACCARAT",
  // },
  {
    img: "../assets/images/Baccarat/BACCARAT-14.webp",
    name: "Baccarat 1",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-001",
    cateogry: "PT",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-15.webp",
    name: "Baccarat 3",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-001",
    cateogry: "PT",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-16.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-010",
    cateogry: "PP",
    type: "BACCARAT",
  },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-17.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-18.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-19.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-20.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-21.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-22.webp",
  //   name: "Baccarat Classic",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-001",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  {
    img: "../assets/images/Baccarat/BACCARAT-24.webp",
    name: "Speed Baccarat 1",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-013",
    cateogry: "PP",
    type: "BACCARAT",
  },
  {
    img: "../assets/images/Baccarat/BACCARAT-25.webp",
    name: "Super 8 Baccarat",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-045",
    cateogry: "PP",
    type: "BACCARAT",
  },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-26.webp",
  //   name: "Speed Baccarat",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-002",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-27.webp",
  //   name: "Baccarat Insurance",
  //   platForm: "SEXY",
  //   gameType: "LIVE",
  //   casinoType: "MX-LIVE-002",
  //   cateogry: "SEXY",
  //   type: "BACCARAT",
  // },
  // {
  //   img: "../assets/images/Baccarat/BACCARAT-28.webp",
  //   name: "Baccarat Insurance",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-003",
  //   cateogry: "VENUS",
  //   type: "BACCARAT",
  // },
  // Baccarat End

  // Roulette Start
  {
    img: "../assets/images/Roulette/ROULETTE-1.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-001",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-2.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-080",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-3.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-078",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-4.webp",
    name: "Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-079",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-5.webp",
    name: "Instant Roulette",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-008",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-6.webp",
    name: "Roulette First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-013",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-7.webp",
    name: "Amercian Roulette Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-020",
    cateogry: "EVO",
    type: "ROULETTE",
  },
  // {
  //   img: "../assets/images/Roulette/ROULETTE-8.webp",
  //   name: "Roulette Live",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-001",
  //   cateogry: "EVO",
  //   type: "ROULETTE",
  // },
  {
    img: "../assets/images/Roulette/ROULETTE-9.webp",
    name: "Roulette",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-009",
    cateogry: "SEXY",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-10.webp",
    name: "Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-029",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-11.webp",
    name: "Auto Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-026",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-12.webp",
    name: "Speed Auto Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-060",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/PT-LIVE-102.png",
    name: "Speed Roulette 2",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PP-LIVE-102",
    cateogry: "PP",
    type: "ROULETTE",
  },
  // {
  //   img: "../assets/images/Roulette/ROULETTE-13.webp",
  //   name: "Roulette",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-009",
  //   cateogry: "VENUS",
  //   type: "ROULETTE",
  // },
  {
    img: "../assets/images/Roulette/ROULETTE-14.webp",
    name: "Speed Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-027",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-15.webp",
    name: "Azure Roulette",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-028",
    cateogry: "PP",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-16.webp",
    name: "American Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-021",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-17.webp",
    name: "Deutsches Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-022",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-18.webp",
    name: "Roulette Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-025",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-19.webp",
    name: "Football French Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-023",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-20.webp",
    name: "Football Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-024",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/ROULETTE-21.webp",
    name: "Spread Bets Roulette",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-027",
    cateogry: "PT",
    type: "ROULETTE",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5001",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5002",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5003",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5004",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5005",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-004.webp",
    name: "HOTROAD-LIVE-004",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "5006",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1001",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1002",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1003",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1004",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1005",
  },
  {
    img: "../assets/images/Roulette/HOTROAD-LIVE-002.webp",
    name: "HOTROAD-LIVE-002",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-002",
    cateogry: "HOTROAD",
    type: "ROULETTE",
    table: "1006",
  },
  {
    img: "../assets/images/Dice/DICE-1.webp",
    name: "Bac Bo Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-172",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-2.webp",
    name: "Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-005",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/EVOLUTION-LIVE-259.png",
    name: "First Person Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-259",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/EVOLUTION-LIVE-260.png",
    name: "Instant Super Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-260",
    cateogry: "EVO",
    type: "DICE",
  },

  {
    img: "../assets/images/Dice/DICE-3.webp",
    name: "Craps Live",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-003",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-4.webp",
    name: "Experor Sic Bo",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-211",
    cateogry: "EVO",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/DICE-5.webp",
    name: "Extra Sic bo",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-016",
    cateogry: "SEXY",
    type: "DICE",
  },

  {
    img: "../assets/images/Dice/PP-LIVE-079.webp",
    name: "Sic bo",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-079",
    cateogry: "PP",
    type: "DICE",
  },
  {
    img: "../assets/images/Dice/PT-LIVE-099.png",
    name: "Speed SicBo",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-099",
    cateogry: "PT",
    type: "DICE",
  },
  // {
  //   img: "../assets/images/Dice/DICE-6.webp",
  //   name: "Red Blue Duel",
  //   platForm: "SEXYBCRT",
  //   gameType: "LIVE",
  //   casinoType: "MX-LIVE-010",
  //   cateogry: "SEXY",
  //   type: "DICE",
  // },
  // {
  //   img: "../assets/images/Dice/DICE-7.webp",
  //   name: "Sic Bo",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-007",
  //   cateogry: "VENUS",
  //   type: "DICE",
  // },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4001",
  },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4002",
  },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4003",
  },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4004",
  },
  {
    img: "../assets/images/Dice/HOTROAD-LIVE-006.webp",
    name: "HOTROAD-LIVE-006",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-006",
    cateogry: "HOTROAD",
    type: "DICE",
    table: "4005",
  },
  // Tradiotional Start
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-1.webp",
    name: "Super Andhar Bahar",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-204",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-2.webp",
    name: "Fan Tan",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-178",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-4.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-004",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-3.webp",
    name: "Dragon Tiger",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-077",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-5.webp",
    name: "Teen Patti Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-026",
    cateogry: "EVO",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-6.webp",
    name: "Dragon Tiger",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-7.webp",
    name: "Dragon Tiger",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-10.webp",
    name: "Dragon Tiger",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-002",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-13.webp",
    name: "Dragon Tiger",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
    cateogry: "PP",
    type: "TRADITIONAL",
  },

  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-15.webp",
  //   name: "Dragon Tiger",
  //   platForm: "VN",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-006",
  //   cateogry: "VENUS",
  //   type: "TRADITIONAL",
  // },
  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-16.webp",
  //   name: "Dragon Tiger",
  //   platForm: "VN",
  //   gameType: "LIVE",
  //   casinoType: "VN-LIVE-006",
  //   cateogry: "VENUS",
  //   type: "TRADITIONAL",
  // },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-9.webp",
    name: "Extra Teen Patti",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-012",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-11.webp",
    name: "Andhar Bahar Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-004",
    cateogry: "PT",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-14.webp",
    name: "Andhar Bahar",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-043",
    cateogry: "PP",
    type: "TRADITIONAL",
  },

  {
    img: "../assets/images/TraditionalGames/TRADINATIONAL-8.webp",
    name: "Teen Patti",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-011",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/Dice/MX-LIVE-017_SEXY.png",
    name: "xocdia",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-017",
    cateogry: "SEXY",
    type: "TRADITIONAL",
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2001",
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2002",
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2003",
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2004",
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2005",
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-003.webp",
    name: "HOTROAD-LIVE-003",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "2006",
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-008.webp",
    name: "HOTROAD-LIVE-008",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-008",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "7001",
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-008.webp",
    name: "HOTROAD-LIVE-008",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-008",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "7002",
  },
  {
    img: "../assets/images/TraditionalGames/HOTROAD-LIVE-008.webp",
    name: "HOTROAD-LIVE-008",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-008",
    cateogry: "HOTROAD",
    type: "TRADITIONAL",
    table: "7003",
  },
  // {
  //   img: "../assets/images/TraditionalGames/TRADINATIONAL-12.webp",
  //   name: "Teen Patti",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-026",
  //   cateogry: "PT",
  //   type: "TRADITIONAL",
  // },
  // Tradiotional End

  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-181.webp",
  //   name: "Lightning Black Jack",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-181",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-208.webp",
    name: "Lightning Black Jack First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-208",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-227.png",
    name: "Lightning Black Jack First Person",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-227",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-009.webp",
    name: "Triple Card Poker",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-009",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/EVOLUTION-LIVE-012.webp",
    name: "Infinite Black Jack",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-012",
    cateogry: "EVOLUTION",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-006.webp",
    name: "Alexandrite Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-007",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-040.webp",
    name: "One Blackjack",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-040",
    cateogry: "PP",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PP-LIVE-041.webp",
    name: "ONE Blackjack 2 - Ruby",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-041",
    cateogry: "PP",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-008.webp",
    name: "Majority Rules Speed Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-008",
    cateogry: "PT",
    type: "GAME-CARD",
  },

  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-002.webp",
  //   name: "Black Jack A",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-002",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },
  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-015.webp",
  //   name: "Casino Hold'em",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-015",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },

  // {
  //   img: "../assets/images/CardGames/EVOLUTION-LIVE-024.webp",
  //   name: "Caribbean Stud Poker",
  //   platForm: "EVOLUTION",
  //   gameType: "LIVE",
  //   casinoType: "EVOLUTION-LIVE-024",
  //   cateogry: "EVOLUTION",
  //   type: "GAME-CARD",
  // },

  // {
  //   img: "../assets/images/CardGames/PT-LIVE-007.webp",
  //   name: "All bets Blackjack",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-007",
  //   cateogry: "PT",
  //   type: "GAME-CARD",
  // },

  {
    img: "../assets/images/CardGames/PT-LIVE-010.webp",
    name: "Unlimited Blackjack",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-010",
    cateogry: "PT",
    type: "GAME-CARD",
  },

  {
    img: "../assets/images/CardGames/PT-LIVE-016.webp",
    name: "3 Card Brag",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-016",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-017.webp",
    name: "Casino Hold AEM",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-017",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  {
    img: "../assets/images/CardGames/PT-LIVE-019.webp",
    name: "Bet on Poker Live",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-019",
    cateogry: "PT",
    type: "GAME-CARD",
  },
  // {
  //   img: "../assets/images/CardGames/PT-LIVE-009.webp",
  //   name: "Sette E Mezzo",
  //   platForm: "PT",
  //   gameType: "LIVE",
  //   casinoType: "PT-LIVE-009",
  //   cateogry: "PT",
  //   type: "GAME-CARD",
  // },
  // Table Start  (casino Done) -------

  {
    img: "../assets/images/Table/TABLE-2.webp",
    name: "Cash Rocket",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-041",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-073.webp",
    name: "Video Poker",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-073",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-069.webp",
    name: "Elite Aviator Club",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-069",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-070.webp",
    name: "Interstellar Run",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-070",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-071.webp",
    name: "Olympus Glory",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-071",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-066.webp",
    name: "Coin Pusher",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-066",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-067.webp",
    name: "Cockfighting Arena",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-067",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-063.webp",
    name: "Tongits",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-063",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/TABLE-3.webp",
    name: "Ludo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-060",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-053.webp",
    name: "Marble Knockout",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-053",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-16.webp",
    name: "Colour Game",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-050",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-048.webp",
    name: "Horse Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-048",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-055.webp",
    name: "KM Virtual Greyhound Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-055",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-058.webp",
    name: "KM Virtual Treadmill Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-058",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-062.webp",
    name: "KM Virtual Treadmill Racing",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-062",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-049.webp",
    name: "5 Card Poker",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-049",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-040.webp",
    name: "Plinko",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-040",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-12.webp",
    name: "Bonus Dice",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-043",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-15.webp",
    name: "keno",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-018",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-045.webp",
    name: "Monkey King Roulette",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-045",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-5.webp",
    name: "sicbo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-015",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-4.webp",
    name: "7 Up 7 Down",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-028",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/TABLE-6.webp",
    name: "Coin Toss",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-036",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-7.webp",
    name: "Cards Hi Lo",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-037",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/NETENT-TABLE-001.png",
    name: "Classic Blackjack",
    platForm: "NETENT",
    gameType: "TABLE",
    casinoType: "NETENT-TABLE-001",
    cateogry: "NETENT",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/TABLE-9.webp",
    name: "Card Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-022",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-021.webp",
    name: "Number Matka",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-021",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-032.webp",
    name: "Andar Bahar",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-032",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/TABLE-13.webp",
    name: "32 Cards",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-039",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/KM-TABLE-038.webp",
    name: "Blackjack",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-038",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-042.webp",
    name: "Minesweeper",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-042",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-035.webp",
    name: "Bola Golek",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-035",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/KM-TABLE-030.webp",
    name: "jhandimunda",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-030",
    cateogry: "KINGMAKER",
    type: "TABLE",
  },

  // Table End KINGMAKER (casino Done) -------

  // Table Start JILI (casino Done) -------

  {
    img: "../assets/images/JILI/JILI-TABLE-057.webp",
    name: "Fish Prawn Crab",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-057",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-058.webp",
    name: "Crash Cricket",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-058",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-021.webp",
    name: "Ludo Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-021",
    cateogry: "JILI",
    type: "TABLE",
  },

  {
    img: "../assets/images/JILI/JILI-TABLE-014.webp",
    name: "Baccarat",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-014",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-017.webp",
    name: "Sic Bo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-017",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-016.webp",
    name: "TeenPatti 20-20",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-016",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-047.png",
    name: "Crash Bonus",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-047",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-048.png",
    name: "Pool Rummy",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-048",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-042.png",
    name: "Mini Flush",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-042",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-018.webp",
    name: "Super Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-018",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-015.webp",
    name: "Fortune Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-015",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-011.webp",
    name: "7up7down",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-011",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-013.webp",
    name: "Callbreak Quick",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-013",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-012.webp",
    name: "Dragon & Tiger",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-012",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-010.webp",
    name: "TeenPatti Joker",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-010",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-008.webp",
    name: "iRich Bingo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-008",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-009.webp",
    name: "Callbreak",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-009",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-003.webp",
    name: "Andar Bahar",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-003",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-004.webp",
    name: "Rummy",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-004",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-005.webp",
    name: "NumberKing",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-005",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-027.webp",
    name: "Golden Land",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-027",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-006.webp",
    name: "Poker King",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-006",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-007.webp",
    name: "Big small",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-007",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-028.webp",
    name: "PAPPU",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-028",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-035.webp",
    name: "Limbo",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-035",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-036.webp",
    name: "wheel",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-036",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-039.webp",
    name: "Color Prediction",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-039",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-032.webp",
    name: "HILO",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-032",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/JILI/JILI-TABLE-030.webp",
    name: "Go Rush",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-030",
    cateogry: "JILI",
    type: "TABLE",
  },

  {
    img: "../assets/images/JILI/JILI-TABLE-002.webp",
    name: "AK47",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-002",
    cateogry: "JILI",
    type: "TABLE",
  },

  {
    img: "../assets/images/Table/JILI-TABLE-033.png",
    name: "Mines",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-033",
    cateogry: "JILI",
    type: "TABLE",
  },
  {
    img: "../assets/images/Table/JILI-TABLE-031.png",
    name: "Tower",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-031",
    cateogry: "JILI",
    type: "TABLE",
  },

  // Table End JILI (casino Done) -------

  // Other

  {
    img: "../assets/images/Others/MX-LIVE-015_SEXY.webp",
    name: "Thai Fish Prawn Crab",
    casinoType: "MX-LIVE-015",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    cateogry: "SEXY",
    type: "OTHERS",
  },
  {
    img: "../assets/images/Others/HOTROAD-LIVE-005.webp",
    name: "HOTROAD-LIVE-005",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-005",
    cateogry: "HOTROAD",
    type: "OTHERS",
    table: "3001",
  },
  // {
  //   img: "../assets/images/Others/VN-LIVE-008.webp",
  //   name: "Thai Fish Prawn Crab",
  //   casinoType: "VN-LIVE-008",
  //   platForm: "VENUS",
  //   gameType: "LIVE",
  //   cateogry: "VENUS",
  //   type: "OTHERS",
  // },
  // Other
  // Slot Start PP (casino Done) -----
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-075.webp",
    name: "Mahjong Win",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-075",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-017.png",
    name: "Starburst™ XXXtreme",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-017",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-014.png",
    name: "Divine Fortune™ Megaways™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-014",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-076.webp",
    name: "Super Hoo Hey How",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-076",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-010.png",
    name: "Finn's Golden Tavern™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-010",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-018.png",
    name: "Twin Spin™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-018",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-006.png",
    name: "Parthenon: Quest for Immortality™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-006",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-177.webp",
    name: "101 Candies",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-177",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-178.webp",
    name: "Big Bang Boom",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-178",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-179.webp",
    name: "Crab Trap™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-179",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-180.webp",
    name: "Jack and the Beanstalk Remastered",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-180",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/NETENT-SLOT-181.webp",
    name: "Thrill to Grill™",
    platForm: "NETENT",
    gameType: "SLOT",
    casinoType: "NETENT-SLOT-181",
    cateogry: "NETENT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-082.webp",
    name: "Football Rules",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PT-SLOT-082",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-003.webp",
    name: "777",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-003",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-004.webp",
    name: "Get Money",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-004",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-012.webp",
    name: "Over Dragon's Gate",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-012",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-002.webp",
    name: "Phoenix",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-002",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-011.webp",
    name: "Diamond Mogul",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-011",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-005.webp",
    name: "Rich Now",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-005",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-008.webp",
    name: "Dracula",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-008",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-020.webp",
    name: "Bust Treasury",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-020",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PP-SLOT-002.webp",
    name: "Gates of Olympus",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-002",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-082.webp",
    name: "Sweet Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-082",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-189.webp",
    name: "Starlight Princess",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-189",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-183.webp",
    name: "5 Lions Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-183",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-080.webp",
    name: "5 Lions Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-080",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-049.webp",
    name: "Great Rhino Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-049",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-069.webp",
    name: "Fire Strike",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-069",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-067.webp",
    name: "Sweet Bonanza Xmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-067",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-015.webp",
    name: "Bonanza Gold",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-015",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-176.webp",
    name: "Buffalo King Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-176",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-181.webp",
    name: "The Magic Cauldron - Enchanted Brew",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-181",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-046.webp",
    name: "Fruit Party",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-046",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-187.webp",
    name: "Pyramid Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-187",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-204.webp",
    name: "Candy Village",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-204",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-126.webp",
    name: "3 Kingdoms - Battle of Red Cliffs",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-126",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-104.webp",
    name: "5 Lions",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-104",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-072.webp",
    name: "John Hunter and the Tomb of the Scarab Queen",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-072",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-107.webp",
    name: "Joker's Jewels",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-107",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-025.webp",
    name: "Gems Bonanza",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-025",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-210.webp",
    name: "Santa's Wonderland",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-210",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-233.webp",
    name: "Wild West Gold Megaways",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-233",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-234.webp",
    name: "Spirit of Adventure",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-234",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-235.webp",
    name: "Fire Strike 2",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-235",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-236.webp",
    name: "Barn Festival",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-236",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-237.webp",
    name: "Chicken Chase",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-237",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-238.webp",
    name: "Goblin Heist Powernudge",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-238",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-239.webp",
    name: "Eye of Cleopatra",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-239",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-240.webp",
    name: "The Great Stick-Up",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-240",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-296.webp",
    name: "Aztec Blaze",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-296",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-297.webp",
    name: "Starlight Christmas",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-297",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-298.webp",
    name: "Towering Fortunes",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-298",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-299.webp",
    name: "Firebird Spirit",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-299",
    cateogry: "PP",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PP-SLOT-300.webp",
    name: "Pirate Golden Age",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-300",
    cateogry: "PP",
    type: "SLOT",
  },
  // Slot End PP (casino Done) -----

  // Slot Start KINGMAKER (casino Done) -----
  {
    img: "../assets/images/slot/KM-SLOT-001.webp",
    name: "Sugar Blast",
    platForm: "KINGMAKER",
    gameType: "SLOT",
    casinoType: "KM-SLOT-001",
    cateogry: "KINGMAKER",
    type: "SLOT",
  },
  // Slot End KINGMAKER (casino Done) -----

  // Slot Start DRAGOONSOFT (casino Done) -----

  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-001.webp",
    name: "Coin Cat",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-001",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-015.webp",
    name: "Egypt Oracle",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-015",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-018.webp",
    name: "Rich Lion",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-018",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-017.webp",
    name: "Candy Dynasty",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-017",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-007.webp",
    name: "Doggy Wealth",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-007",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-016.webp",
    name: "Caishen Coming",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-016",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-006.webp",
    name: "Chef Lady",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-006",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-009.webp",
    name: "Maya King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-009",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-010.webp",
    name: "Pirate King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-010",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-013.webp",
    name: "Great Lion",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-013",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-014.webp",
    name: "Ultra Treasure",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-014",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/DRAGOONSOFT-SLOT-019.webp",
    name: "Monkey King",
    platForm: "DRAGOONSOFT",
    gameType: "SLOT",
    casinoType: "DRAGOONSOFT-SLOT-019",
    cateogry: "DRAGOONSOFT",
    type: "SLOT",
  },
  // Slot End DRAGOONSOFT (casino Done) -----

  // Slot Start SPADE (casino Done) -----
  // {
  //   img: "../assets/images/slot/SG-SLOT-106.webp",
  //   name: "Fiery Sevens Exclusive",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-106",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-074.webp",
  //   name: "Mega7",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-074",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-116.webp",
  //   name: "Legacy Of Kong Maxways",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-116",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-069.webp",
  //   name: "888",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-069",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-112.webp",
  //   name: "Fruits Mania",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-112",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-076.webp",
  //   name: "DoubleFlame",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-076",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-110.webp",
  //   name: "Space Conquest",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-110",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-081.webp",
  //   name: "Triple Panda",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-081",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-077.webp",
  //   name: "Book of Myth",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-077",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-095.webp",
  //   name: "Poker Ways",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-095",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },

  // {
  //   img: "../assets/images/slot/SG-SLOT-086.webp",
  //   name: "Mayan Gems",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-086",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-075.webp",
  //   name: "JokersTreasure",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-075",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-030.webp",
  //   name: "5FortuneSA",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-030",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-103.webp",
  //   name: "Sexy Vegas",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-103",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-107.webp",
  //   name: "Lucky Koi Exclusive",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-107",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-082.webp",
  //   name: "Gold Panther",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-082",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-032.webp",
  //   name: "Golden Lotus SE",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-032",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/SG-SLOT-105.webp",
  //   name: "Royale House",
  //   platForm: "SPADE",
  //   gameType: "SLOT",
  //   casinoType: "SG-SLOT-105",
  //   cateogry: "SPADE",
  //   type: "SLOT",
  // },
  // Slot End SPADE (casino Done) -----

  // Slot Start PT (casino Done) -----

  {
    img: "../assets/images/slot/PT-SLOT-070.webp",
    name: "Heart of the Frontier",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-070",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-071.webp",
    name: "Thai Paradise",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-071",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-072.webp",
    name: "Legacy of the Wild",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-072",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-073.webp",
    name: "Archer",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-073",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-001.webp",
    name: "Triple Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-001",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-003.webp",
    name: "Buffalo Blitz",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-003",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-006.webp",
    name: "Great Blue",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-006",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-004.webp",
    name: "Captain Treasure",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-004",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-005.webp",
    name: "Long Long Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-005",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-009.webp",
    name: "Golden Tour",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-009",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-010.webp",
    name: "Funky Monkey",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-010",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-011.webp",
    name: "Highway Kings",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-011",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-014.webp",
    name: "Zhao Cai Tong Zi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-014",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-012.webp",
    name: "Zhao Cai Jin Bao",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-012",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-013.webp",
    name: "Jin Qian Wa",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-013",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-015.webp",
    name: "Ugga Bugga",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-015",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-016.webp",
    name: "Safari Heat",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-016",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-002.webp",
    name: "Arowanas Luck",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-002",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-019.webp",
    name: "Gem Queen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-019",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-023.webp",
    name: "Chaoji 888",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-023",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-008.webp",
    name: "Blue Wizard",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-008",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-018.webp",
    name: "Bonus Bears",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-018",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-017.webp",
    name: "Buffalo Blitz II",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-017",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-007.webp",
    name: "Sky Queen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-007",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-020.webp",
    name: "Feng Kuang Ma Jiang",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-020",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-021.webp",
    name: "Epic Ape",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-021",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-022.webp",
    name: "Ni Shu Shen Me",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-022",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-024.webp",
    name: "Vacation Station Deluxe",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-024",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-025.webp",
    name: "Eternal Lady",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-025",
    cateogry: "PT",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/PT-SLOT-026.webp",
    name: "Golden Macaque",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-026",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-027.webp",
    name: "Fei Long Zai Tian",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-027",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-028.webp",
    name: "Yun Cong Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-028",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-029.webp",
    name: "Jinfu Long",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-029",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-030.webp",
    name: "Hot Gems",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-030",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-031.webp",
    name: "Lie Yan Zuan Shi",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-031",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-032.webp",
    name: "Dolphin Reef",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-032",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-033.webp",
    name: "Jinns Moon",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-033",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-034.webp",
    name: "Tiger, Turtle, Dragon, Phoenix",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-034",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-035.webp",
    name: "Heart of the Jungle",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-035",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-036.webp",
    name: "Heavenly Ruler",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-036",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-037.webp",
    name: "Ox Riches",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-037",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-038.webp",
    name: "Ice Cave",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-038",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-039.webp",
    name: "Queen of Wands",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-039",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-040.webp",
    name: "Si Xiang",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-040",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-041.webp",
    name: "Panther Moon",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-041",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-043.webp",
    name: "Asian Fantasy",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-043",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-044.webp",
    name: "Fortune Lions",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-044",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-045.webp",
    name: "Hot KTV",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-045",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-046.webp",
    name: "Fortune Day",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-046",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-047.webp",
    name: "Ri Ri Jin Cai",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-047",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-048.webp",
    name: "Sun WuKong",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-048",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-049.webp",
    name: "Ji Xiang 8",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-049",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-050.webp",
    name: "Jade Emperor Yu Huang Da Di",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-050",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-051.webp",
    name: "Halloween Fortune 2",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-051",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-060.webp",
    name: "Chinese Kitchen",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-060",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-061.webp",
    name: "Geisha Story",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-061",
    cateogry: "PT",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/PT-SLOT-062.webp",
    name: "Fortunes of the Fox",
    platForm: "PT",
    gameType: "SLOT",
    casinoType: "PT-SLOT-062",
    cateogry: "PT",
    type: "SLOT",
  },
  // Slot End PT (casino Done) -----

  // Slot Start JDB (casino Done) -----
  {
    img: "../assets/images/slot/JDB-SLOT-108.webp",
    name: "Moneybags Man 2",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-108",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-106.webp",
    name: "FortuneNeko",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-106",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-107.webp",
    name: "Trump Card",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-107",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-105.webp",
    name: "BookOfMystery",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-105",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-001.webp",
    name: "Burglar",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-001",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-003.webp",
    name: "Chef Doeuvre",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-003",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-004.webp",
    name: "Lucky Miner",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-004",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-005.webp",
    name: "Candy Land",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-005",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-006.webp",
    name: "Crazy Scientist",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-006",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-007.webp",
    name: "Super Dumpling",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-007",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-008.webp",
    name: "Cash Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-008",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-009.webp",
    name: "Wild Beauty",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-009",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-010.webp",
    name: "Flirting Scholar Tang",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-010",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-011.webp",
    name: "Winning Mask",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-011",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-012.webp",
    name: "Wu kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-012",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-013.webp",
    name: "Llama Adventure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-013",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-014.webp",
    name: "Formosa Bear",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-014",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-015.webp",
    name: "Lucky Qilin",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-015",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-016.webp",
    name: "New Year",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-016",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-017.webp",
    name: "Four Treasures",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-017",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-018.webp",
    name: "Open Sesame",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-018",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-019.webp",
    name: "Banana Saga",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-019",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-020.webp",
    name: "Olympian Temple",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-020",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-021.webp",
    name: "Dancing Pa Pa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-021",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-022.webp",
    name: "Dragon King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-022",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-023.webp",
    name: "Magic Show",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-023",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-024.webp",
    name: "Cock Fight",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-024",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-025.webp",
    name: "Dragon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-025",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-026.webp",
    name: "Rooster In Love",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-026",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-027.webp",
    name: "Monkey King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-027",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-028.webp",
    name: "Moonlight Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-028",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-029.webp",
    name: "Mahjong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-029",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-030.webp",
    name: "Guan Gong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-030",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-031.webp",
    name: "Billionaire",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-031",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-032.webp",
    name: "Dragon Warrior",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-032",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-033.webp",
    name: "Kingsman",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-033",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-034.webp",
    name: "Curvy Magician",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-034",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-035.webp",
    name: "RollingIn Money",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-035",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-036.webp",
    name: "Chef Panda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-036",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-045.webp",
    name: "Lucky Dragons",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-045",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-046.webp",
    name: "Lucky Lion",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-046",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-047.webp",
    name: "Coffeeholics",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-047",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-048.webp",
    name: "Napoleon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-048",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-049.webp",
    name: "Lucky Phoenix",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-049",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-050.webp",
    name: "WinningMask II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-050",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-051.webp",
    name: "Open Sesame II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-051",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-052.webp",
    name: "Flirting Scholar Tang II",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-052",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-053.webp",
    name: "Fortune Horse",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-053",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-054.webp",
    name: "Xi Yang Yang",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-054",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-055.webp",
    name: "Panda Panda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-055",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-056.webp",
    name: "Zelda",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-056",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-057.webp",
    name: "Mr Bao",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-057",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-058.webp",
    name: "One Punch Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-058",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-059.webp",
    name: "Guardians of The Galaxy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-059",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-060.webp",
    name: "Street Fighter",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-060",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-061.webp",
    name: "Star Wars",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-061",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-062.webp",
    name: "War of Beauty",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-062",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-063.webp",
    name: "Daji",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-063",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-064.webp",
    name: "Poker King",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-064",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-065.webp",
    name: "Lucky Racing",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-065",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-066.webp",
    name: "Birds Party",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-066",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-067.webp",
    name: "Go Lai Fu",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-067",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-068.webp",
    name: "Gems Gems",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-068",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-069.webp",
    name: "Dragons World",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-069",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-070.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-070",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-071.webp",
    name: "Egypt Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-071",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-072.webp",
    name: "Fortune Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-072",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-073.webp",
    name: "Pirate Treasure",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-073",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-074.webp",
    name: "Mjoinir",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-074",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-075.webp",
    name: "Treasure Bowl",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-075",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-076.webp",
    name: "Funky King Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-076",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-077.webp",
    name: "Super Niubi Deluxe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-077",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-078.webp",
    name: "Super Niubi",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-078",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-079.webp",
    name: "Lucky Fuwa",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-079",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-080.webp",
    name: "Inca Empire",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-080",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-081.webp",
    name: "Ninja Rush",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-081",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-082.webp",
    name: "Sun Archer",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-082",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-083.webp",
    name: "Mystery of Nine Tales",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-083",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-084.webp",
    name: "Golden Disco",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-084",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-085.webp",
    name: "Miner Babe",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-085",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-086.webp",
    name: "Moneybags Man",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-086",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-087.webp",
    name: "Double Wilds",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-087",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-088.webp",
    name: "Pop Pop Fruity",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-088",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-089.webp",
    name: "Spindrift",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-089",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-090.webp",
    name: "Spindrift 2",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-090",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-091.webp",
    name: "Jungle Jungle",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-091",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-092.webp",
    name: "Dragons Gate",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-092",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-093.webp",
    name: "Lucky Diamond",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-093",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-094.webp",
    name: "Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-094",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-095.webp",
    name: "NinjaX",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-095",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-096.webp",
    name: "Rex Brothers",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-096",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-097.webp",
    name: "Wonder Elephant",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-097",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-098.webp",
    name: "MarvelousIV",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-098",
    cateogry: "JDB",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JDB-SLOT-099.webp",
    name: "Maya Gold Crazy",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-099",
    cateogry: "JDB",
    type: "SLOT",
  },
  // Slot End JDB (casino Done) -----

  // Slot Start FC (casino Done) -----

  // {
  //   img: "../assets/images/slot/FC-SLOT-025.webp",
  //   name: "SUGAR BANG BANG",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-025",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-021.webp",
  //   name: "Lucky Fortunes",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-021",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-020.webp",
  //   name: "Robin Hood",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-020",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-019.webp",
  //   name: "GLORY OF ROME",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-019",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-001.webp",
  //   name: "GOLDEN PANTHER",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-001",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-002.webp",
  //   name: "THREE LITTLE PIGS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-002",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-003.webp",
  //   name: "HOT POT PARTY",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-003",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-004.webp",
  //   name: "NIGHT MARKET",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-004",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-005.webp",
  //   name: "PONG PONG HU",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-005",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-006.webp",
  //   name: "PANDA DRAGON BOAT",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-006",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-007.webp",
  //   name: "CHINESE NEW YEAR",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-007",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-008.webp",
  //   name: "FORTUNE KOI",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-008",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-009.webp",
  //   name: "HAPPY DUO BAO",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-009",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-010.webp",
  //   name: "	DA LE MEN",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-010",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-011.webp",
  //   name: "ANIMAL RACING",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-011",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-012.webp",
  //   name: "LUXURY GOLDEN PANTHER",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-012",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-013.webp",
  //   name: "MAGIC BEANS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-013",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-014.webp",
  //   name: "COWBOYS",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-014",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-015.webp",
  //   name: "WAR OF THE UNIVERSE",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-015",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // {
  //   img: "../assets/images/slot/FC-SLOT-016.webp",
  //   name: "TREASURE CRUISE",
  //   platForm: "FC",
  //   gameType: "SLOT",
  //   casinoType: "FC-SLOT-016",
  //   cateogry: "FC",
  //   type: "SLOT",
  // },
  // Slot End FC (casino Done) -----

  // Slot Start JILI (casino Done) -----
  {
    img: "../assets/images/slot/JILI-SLOT-063.webp",
    name: "World Cup",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-063",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-056.webp",
    name: "Happy Taxi",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-056",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-057.webp",
    name: "Gold Rush",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-057",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-054.webp",
    name: "Crazy Pusher",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-054",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-053.webp",
    name: "Book of Gold",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-053",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-071.webp",
    name: "Neko Fortune",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-071",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-051.webp",
    name: "Mega Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-051",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-052.webp",
    name: "Thor X",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-052",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-002.webp",
    name: "Hot Chilli",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-002",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-003.webp",
    name: "Chin Shi Huang",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-003",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-004.webp",
    name: "War Of Dragons",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-004",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-005.webp",
    name: "Fortune Tree",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-005",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-006.webp",
    name: "Lucky Ball",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-006",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-007.webp",
    name: "Hyper Burst",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-007",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-008.webp",
    name: "Shanghai Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-008",
    cateogry: "JILI",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/JILI-SLOT-009.webp",
    name: "Fa Fa Fa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-009",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-010.webp",
    name: "God Of Martial",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-010",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-012.webp",
    name: "Hawaii Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-012",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-013.webp",
    name: "SevenSevenSeven",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-013",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-014.webp",
    name: "Crazy777",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-014",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-015.webp",
    name: "Bubble Beauty",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-015",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-016.webp",
    name: "Bao boon chin",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-016",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-017.webp",
    name: "Crazy FaFaFa",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-017",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-018.webp",
    name: "XiYangYang",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-018",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-019.webp",
    name: "FortunePig",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-019",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-020.webp",
    name: "Candy Baby",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-020",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-021.webp",
    name: "Diamond Party",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-021",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-022.webp",
    name: "Fengshen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-022",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-023.webp",
    name: "Golden Bank",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-023",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-024.webp",
    name: "Lucky Goldbricks",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-024",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-025.webp",
    name: "Dragon Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-025",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-026.webp",
    name: "Charge Buffalo",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-026",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-027.webp",
    name: "Super Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-027",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-028.webp",
    name: "Jungle King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-028",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-029.webp",
    name: "Money Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-029",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-030.webp",
    name: "Golden Queen",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-030",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-031.webp",
    name: "Boxing King",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-031",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-032.webp",
    name: "Matka India",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-032",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-037.webp",
    name: "Lucky Coming",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-037",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-038.webp",
    name: "Pharaoh Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-038",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-039.webp",
    name: "Secret Treasure",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-039",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-040.webp",
    name: "RomaX",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-040",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-041.webp",
    name: "Super Rich",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-041",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-042.webp",
    name: "Golden Empire",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-042",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-043.webp",
    name: "Fortune Gems",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-043",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-044.webp",
    name: "Party Night",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-044",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-045.webp",
    name: "Crazy Hunter",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-045",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-046.webp",
    name: "Magic Lamp",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-046",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-047.webp",
    name: "TWINWINS",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-047",
    cateogry: "JILI",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/JILI-SLOT-048.webp",
    name: "Agent Ace",
    platForm: "JILI",
    gameType: "SLOT",
    casinoType: "JILI-SLOT-048",
    cateogry: "JILI",
    type: "SLOT",
  },
  // Slot End JILI (casino Done) -----

  //slot spade start
  {
    img: "../assets/images/slot/SG-SLOT-106.png",
    name: "Fiery Sevens Exclusive",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-106",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-074.png",
    name: "Mega7",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-074",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-116.png",
    name: "Legacy Of Kong Maxways",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-116",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-069.webp",
    name: "888",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-069",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-112.webp",
    name: "Fruits Mania",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-112",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-076.png",
    name: "DoubleFlame",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-076",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-110.webp",
    name: "Space Conquest",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-110",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-081.png",
    name: "TriplePanda",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-081",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-077.png",
    name: "BookofMyth",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-077",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-095.png",
    name: "Poker Ways",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-095",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-086.png",
    name: "Mayan Gems",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-086",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-075.png",
    name: "JokersTreasure",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-075",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-030.png",
    name: "5FortuneSA",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-030",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-103.webp",
    name: "Sexy Vegas",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-103",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-107.png",
    name: "Lucky Koi Exclusive",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-107",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-082.png",
    name: "GoldPanther",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-082",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-032.webp",
    name: "GoldenLotusSE",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-032",
    cateogry: "SPADE",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/SG-SLOT-105.png",
    name: "Royale House",
    platForm: "SPADE",
    gameType: "SLOT",
    casinoType: "SG-SLOT-105",
    cateogry: "SPADE",
    type: "SLOT",
  },

  //slot spade end
  {
    img: "../assets/images/slot/FC-SLOT-025.png",
    name: "SUGAR BANG BANG",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-025",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-023.png",
    name: "GRAND BLUE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-023",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-024.png",
    name: "TREASURE RAIDERS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-024",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-021.png",
    name: "Lucky Fortunes",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-021",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-020.png",
    name: "Robin Hood",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-020",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-019.png",
    name: "GLORY OF ROME",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-019",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-001.png",
    name: "GOLDEN PANTHER",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-001",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-002.png",
    name: "THREE LITTLE PIGS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-002",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-003.png",
    name: "HOT POT PARTY",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-003",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-004.png",
    name: "NIGHT MARKET",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-004",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-005.png",
    name: "PONG PONG HU",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-005",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-006.png",
    name: "PANDA DRAGON BOAT",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-006",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-007.png",
    name: "CHINESE NEW YEAR",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-007",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-008.png",
    name: "FORTUNE KOI",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-008",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-009.png",
    name: "HAPPY DUO BAO",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-009",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-010.png",
    name: "DA LE MEN",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-010",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-011.png",
    name: "ANIMAL RACING",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-011",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-012.png",
    name: "LUXURY GOLDEN PANTHER",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-012",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-013.png",
    name: "MAGIC BEANS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-013",
    cateogry: "FC",
    type: "SLOT",
  },

  {
    img: "../assets/images/slot/FC-SLOT-014.png",
    name: "COWBOYS",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-014",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-015.png",
    name: "WAR OF THE UNIVERSE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-015",
    cateogry: "FC",
    type: "SLOT",
  },
  {
    img: "../assets/images/slot/FC-SLOT-016.png",
    name: "TREASURE CRUISE",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-016",
    cateogry: "FC",
    type: "SLOT",
  },

  //slot FC start

  //slot FC end

  // Fishing Start JILI (casino Done) -----
  {
    img: "../assets/images/Fishing/JDB-FISH-008.webp",
    name: "Royal Fishing",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-001",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-010.webp",
    name: "Fishing YiLuFa",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-002",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-011.webp",
    name: "Dragon Master",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-004",
    cateogry: "JDB",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JDB-FISH-013.webp",
    name: "Fishing Legend",
    platForm: "FC",
    gameType: "FH",
    casinoType: "FC-FISH-005",
    cateogry: "JDB",
    type: "FISHING",
  },

  // Fishing Start SPADE (casino Done) -----
  {
    img: "../assets/images/Fishing/SG-FISH-001.webp",
    name: "Fishing God",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-001",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-002.webp",
    name: "Fishing War",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-002",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-003.webp",
    name: "Alien Hunter",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-003",
    cateogry: "SPADE",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/SG-FISH-004.webp",
    name: "Zombie Party",
    platForm: "SPADE",
    gameType: "FH",
    casinoType: "SG-FISH-004",
    cateogry: "SPADE",
    type: "FISHING",
  },
  // Fishing End SPADE (casino Done) -----
  {
    img: "../assets/images/Fishing/JILI-FISH-008.webp",
    name: "Boom Legend",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-008",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-001.webp",
    name: "Royal Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-001",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-004.webp",
    name: "Dinosaur Tycoon",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-004",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-002.webp",
    name: "Bombing Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-002",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-007.webp",
    name: "Mega Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-007",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-006.webp",
    name: "Dragon Fortune",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-006",
    cateogry: "JILI",
    type: "FISHING",
  },
  {
    img: "../assets/images/Fishing/JILI-FISH-005.webp",
    name: "Happy Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-005",
    cateogry: "JILI",
    type: "FISHING",
  },

  {
    img: "../assets/images/Fishing/JILI-FISH-003.webp",
    name: "Jackpot Fishing",
    platForm: "JILI",
    gameType: "FH",
    casinoType: "JILI-FISH-003",
    cateogry: "JILI",
    type: "FISHING",
  },

  // Fishing End JILI (casino Done) -----
  // EGAME SPRIBE
  {
    img: "../assets/images/Egame/22001.png",
    name: "Aviator",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-001",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22002.png",
    name: "Dice",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-003",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22008.png",
    name: "Mini Roulette",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-008",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22005.png",
    name: "Mines",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-005",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22006.png",
    name: "Spribe Hilo",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-006",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22004.png",
    name: "Plinko",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-004",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22003.png",
    name: "Goal",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-002",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22009.png",
    name: "Hotline",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-009",
    cateogry: "SPRIBE",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/22007.png",
    name: "Keno",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-007",
    cateogry: "SPRIBE",
    type: "EGAME",
  },

  // Egame Start JDB (casino Done) -----
  {
    img: "../assets/images/Egame/JDB-EGAME-002.webp",
    name: "Lucky Color Game",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-009",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-006.webp",
    name: "King Of Football",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-010",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-001.webp",
    name: "Crazy King Kong",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-011",
    cateogry: "JDB",
    type: "EGAME",
  },

  {
    img: "../assets/images/Egame/JDB-EGAME-007.webp",
    name: "Super Super Fruit",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-012",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-011.webp",
    name: "Jogo Do Bicho",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-013",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-010.webp",
    name: "Beer Tycoon",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-014",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-005.webp",
    name: "CAISHEN PARTY",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-015",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-003.webp",
    name: "Birds and Animals",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-016",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-009.webp",
    name: "Classic Mario",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-017",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-004.webp",
    name: "Happy New Year",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-018",
    cateogry: "JDB",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/JDB-EGAME-008.webp",
    name: "Huaguoshan Legends",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JJDB-SLOT-019",
    cateogry: "JDB",
    type: "EGAME",
  },

  // Egame End JDB (casino Done) -----

  //PP EGAME START

  {
    img: "../assets/images/Egame/PP-EGAME-004.webp",
    name: "Fantastic League",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-001",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-002.webp",
    name: "Penalty Shootout",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-002",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-005.webp",
    name: "Greyhound Racing",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-005",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-003.webp",
    name: "Horse Racing",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-003",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-006.webp",
    name: "Force 1",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-006",
    cateogry: "PP",
    type: "EGAME",
  },
  {
    img: "../assets/images/Egame/PP-EGAME-007.webp",
    name: "Darts",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-007",
    cateogry: "PP",
    type: "EGAME",
  },

  //PP EGAME END

  // AWC Start -----

  // AWC BG Start -----
  {
    img: "../assets/images/Awc/BG-LIVE-001.webp",
    name: "Live Casino BG",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-001",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-002.webp",
    name: "Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-002",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-003.webp",
    name: "Roulette Awc",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-003",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-004.webp",
    name: "Sicbo Awc",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-004",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-005.webp",
    name: "Dragon tiger",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-005",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-006.webp",
    name: "Speed Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-006",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-007.webp",
    name: "Mi Card Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-007",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-008.webp",
    name: "Full Color Baccarat",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-008",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-009.webp",
    name: "BullBull",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-009",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-010.webp",
    name: "Win Three Cards",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-010",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-011.webp",
    name: "RedBlack",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-011",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-012.webp",
    name: "SpeedSicBo",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-012",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-013.webp",
    name: "SeDie",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-013",
    cateogry: "BG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BG-LIVE-014.webp",
    name: "CasinoWar",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-014",
    cateogry: "BG",
    type: "AWC",
  },
  // AWC BG END -----
  // AWC BTG START -----
  {
    img: "../assets/images/Awc/BTG-SLOT-001.webp",
    name: "Apollo Pays",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-001",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-002.webp",
    name: "Castle of Terror",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-002",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-003.webp",
    name: "Rasputin Megaways",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-003",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-004.webp",
    name: "Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-004",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-005.webp",
    name: "Extra Chilli",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-005",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-006.webp",
    name: "White Rabbit",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-006",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-007.webp",
    name: "Millionaire",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-007",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-008.webp",
    name: "Golden Catch",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-008",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-009.webp",
    name: "Danger High Voltage",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-009",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-010.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-010",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-011.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-011",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-012.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-012",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-013.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-013",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-014.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-014",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-015.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-015",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-016.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-016",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-017.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-017",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-018.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-018",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-019.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-019",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-020.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-020",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-021.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-021",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-022.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-022",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-023.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-023",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-024.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-024",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-025.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-025",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-026.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-026",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-027.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-027",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-028.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-028",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-029.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-029",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-030.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-030",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-031.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-031",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-032.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-032",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-033.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-033",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-034.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-034",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-035.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-035",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-036.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-036",
    cateogry: "BTG",
    type: "AWC",
  },

  {
    img: "../assets/images/Awc/BTG-SLOT-038.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-038",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-039.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-039",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-040.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-040",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-041.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-041",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-042.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-042",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-043.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-043",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-044.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-044",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-045.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-045",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-046.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-046",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-047.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-047",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-048.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-048",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-049.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-049",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-050.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-050",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-051.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-051",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-052.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-052",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-053.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-053",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-054.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-054",
    cateogry: "BTG",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/BTG-SLOT-055.webp",
    name: "Christmas Bonanza",
    platForm: "BTG",
    gameType: "SLOT",
    casinoType: "BTG-SLOT-055",
    cateogry: "BTG",
    type: "AWC",
  },

  // AWC BTG END -----
  // AWC E1SPORT START -----
  {
    img: "../assets/images/Awc/E1-ESPORTS-001.webp",
    name: "E1Sports",
    platForm: "E1SPORT",
    gameType: "ESPORTS",
    casinoType: "E1-ESPORTS-001",
    cateogry: "E1SPORT",
    type: "AWC",
  },
  // AWC E1SPORT END -----

  // AWC NLC START -----
  {
    img: "../assets/images/Awc/NLC-SLOT-001.webp",
    name: "Das x Boot",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-001",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-002.webp",
    name: "Mental",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-002",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-003.webp",
    name: "Punk Toilet",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-003",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-004.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-004",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-005.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-005",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-006.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-006",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-007.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-007",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-008.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-008",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-009.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-009",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-010.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-010",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-011.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-011",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-012.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-012",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-013.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-013",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-014.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-014",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-015.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-015",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-016.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-016",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-017.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-017",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-018.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-018",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-019.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-019",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-020.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-020",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-021.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-021",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-022.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-022",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-023.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-023",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-024.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-024",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-025.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-025",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-026.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-026",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-027.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-027",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-028.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-028",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-029.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-029",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-030.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-030",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-031.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-031",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-032.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-032",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-033.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-033",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-034.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-034",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-035.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-035",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-036.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-036",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-037.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-037",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-038.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-038",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-039.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-039",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-040.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-040",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-041.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-041",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-042.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-042",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-043.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-043",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-044.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-044",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-045.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-045",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-046.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-046",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-047.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-047",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-048.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-048",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-049.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-049",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-050.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-050",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-051.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-051",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-052.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-052",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-053.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-053",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-054.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-054",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-055.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-055",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-056.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-056",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-057.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-057",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-058.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-058",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-059.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-059",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-060.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-060",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-061.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-061",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-062.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-062",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-063.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-063",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-064.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-064",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-065.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-065",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-066.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-066",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-067.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-067",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-068.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-068",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-069.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-069",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-070.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-070",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-071.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-071",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-072.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-072",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-073.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-073",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-074.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-074",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-075.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-075",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-076.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-076",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-077.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-077",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-078.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-078",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-079.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-079",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-080.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-080",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-081.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-081",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-082.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-082",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-083.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-083",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-084.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-084",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-085.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-085",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-086.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-086",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-087.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-087",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-088.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-088",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-089.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-089",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-090.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-090",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-091.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-091",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-092.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-092",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-093.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-093",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-094.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-094",
    cateogry: "NLC",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/NLC-SLOT-095.webp",
    name: "Serial",
    platForm: "NLC",
    gameType: "SLOT",
    casinoType: "NLC-SLOT-095",
    cateogry: "NLC",
    type: "AWC",
  },
  // AWC NLC END -----
  // AWC RT START -----
  {
    img: "../assets/images/Awc/RT-SLOT-001.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-001",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-002.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-002",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-003.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-003",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-004.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-004",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-005.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-005",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-006.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-006",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-007.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-007",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-008.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-008",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-009.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-009",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-010.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-010",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-011.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-011",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-012.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-012",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-013.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-013",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-014.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-014",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-015.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-015",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-016.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-016",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-017.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-017",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-018.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-018",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-019.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-019",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-020.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-020",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-021.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-021",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-022.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-022",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-023.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-023",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-024.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-024",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-025.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-025",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-026.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-026",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-027.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-027",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-028.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-028",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-029.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-029",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-030.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-030",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-031.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-031",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-032.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-032",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-033.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-033",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-034.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-034",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-035.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-035",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-036.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-036",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-037.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-037",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-038.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-038",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-039.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-039",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-040.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-040",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-041.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-041",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-042.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-042",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-0433.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-043",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-044.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-044",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-045.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-045",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-046.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-046",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-047.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-047",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-048.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-048",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-049.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-049",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-050.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-050",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-051.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-051",
    cateogry: "RT",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/RT-SLOT-052.webp",
    name: "Ancient Script",
    platForm: "RT",
    gameType: "SLOT",
    casinoType: "RT-SLOT-052",
    cateogry: "RT",
    type: "AWC",
  },
  // AWC RT END -----
  // AWC SV388 START -----

  {
    img: "../assets/images/Awc/SV-LIVE-001.webp",
    name: "SV388",
    platForm: "SV388",
    gameType: "LIVE",
    casinoType: "SV-LIVE-001",
    cateogry: "SV388",
    type: "AWC",
  },
  // AWC SV388 END -----
  // AWC YESBINGO START -----
  {
    img: "../assets/images/Awc/YesBingo-BINGO-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-002.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-002",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-003.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-003",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-004.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-004",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-005.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-005",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-006.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-006",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-007.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-007",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-008.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-008",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-009.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-009",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-010.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-010",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-BINGO-011.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "BINGO",
    casinoType: "YesBingo-BINGO-011",
    cateogry: "YesBingo",
    type: "AWC",
  },

  {
    img: "../assets/images/Awc/YesBingo-FH-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "FH",
    casinoType: "YesBingo-FH-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-002.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-002",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-003.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-003",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-004.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-004",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-005.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-005",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-006.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-006",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-007.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-007",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-008.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-008",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-009.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-009",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-010.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-010",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-011.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-011",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-012.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-012",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-013.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-013",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-014.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-014",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-015.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-015",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-016.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-016",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-017.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-017",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-018.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-018",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-019.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-019",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-020.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-020",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-021.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-021",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-022.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-022",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-023.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-023",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-024.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-024",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-025.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-025",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-026.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-026",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-027.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-027",
    cateogry: "YesBingo",
    type: "AWC",
  },

  {
    img: "../assets/images/Awc/YesBingo-SLOT-029.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-029",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-030.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-030",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-SLOT-031.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "SLOT",
    casinoType: "YesBingo-SLOT-031",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-EGAME-001.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "EGAME",
    casinoType: "YesBingo-EGAME-001",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-EGAME-002.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "EGAME",
    casinoType: "YesBingo-EGAME-002",
    cateogry: "YesBingo",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YesBingo-EGAME-003.webp",
    name: "YesBingo",
    platForm: "YESBINGO",
    gameType: "EGAME",
    casinoType: "YesBingo-EGAME-003",
    cateogry: "YesBingo",
    type: "AWC",
  },
  // AWC YESBINGO END -----
  // AWC YL START -----
  {
    img: "../assets/images/Awc/YL-EGAME-001.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-001",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-002.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-002",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-003.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-003",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-004.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-004",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-005.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-005",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-006.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-006",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-007.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-007",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-008.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-008",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-009.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-009",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-010.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-010",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-011.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-011",
    cateogry: "YL",
    type: "AWC",
  },
  {
    img: "../assets/images/Awc/YL-EGAME-012.webp",
    name: "YL",
    platForm: "YL",
    gameType: "EGAME",
    casinoType: "YL-EGAME-012",
    cateogry: "YL",
    type: "AWC",
  },
  // AWC YL END -----
  // AWC End -----
];


export const gamesTabelArray = [
  {
    img: "../assets/images/Table/TABLE-2.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-3.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/KM-TABLE-053.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-5.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-6.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-7.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },

  {
    img: "../assets/images/Table/TABLE-8.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-9.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-10.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-11.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-12.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },

  {
    img: "../assets/images/Table/TABLE-13.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-14.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-15.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/TABLE-16.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/KM-TABLE-058.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },

  {
    img: "../assets/images/Table/KM-TABLE-055.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/KM-TABLE-048.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
  {
    img: "../assets/images/Table/KM-TABLE-045.webp",
    platForm: "1006",
    gameType: "1",
    casinoType: "LIVE CASINO",
    gameid: "XxxtremeLigh0001",
    cateogry: "KINGMAKER",
  },
];

export const gamesSelectionArray = [
  {
    id: 1,
    title: "Game Shows",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/game-shows/GAME-SHOW-1.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "XxxtremeLigh0001",
      },
      {
        id: 2,
        img: "../assets/images/game-shows/GAME-SHOW-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CashOrCrash00001",
      },
      {
        id: 3,
        img: "../assets/images/game-shows/GAME-SHOW-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "FunkyTime0000001",
      },
      {
        id: 4,
        img: "../assets/images/game-shows/GAME-SHOW-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "doasaloon0000001",
      },
      {
        id: 5,
        img: "../assets/images/game-shows/GAME-SHOW-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },
      {
        id: 6,
        img: "../assets/images/game-shows/GAME-SHOW-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },

      {
        id: 7,
        img: "../assets/images/game-shows/GAME-SHOW-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 8,
        img: "../assets/images/game-shows/GAME-SHOW-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 9,
        img: "../assets/images/game-shows/GAME-SHOW-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 10,
        img: "../assets/images/game-shows/GAME-SHOW-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "ImperialQuest001",
      },
      {
        id: 11,
        img: "../assets/images/game-shows/GAME-SHOW-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 12,
        img: "../assets/images/game-shows/GAME-SHOW-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 13,
        img: "../assets/images/game-shows/GAME-SHOW-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyCoinFlip001",
      },
      {
        id: 14,
        img: "../assets/images/game-shows/GAME-SHOW-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 15,
        img: "../assets/images/game-shows/GAME-SHOW-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 16,
        img: "../assets/images/game-shows/GAME-SHOW-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 17,
        img: "../assets/images/game-shows/GAME-SHOW-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 18,
        img: "../assets/images/game-shows/GAME-SHOW-18.webp",
        id: 17,
        img: "../assets/images/game-shows/GAME-SHOW-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 19,
        img: "../assets/images/game-shows/GAME-SHOW-19.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "MOWDream00000001",
      },

      {
        id: 20,
        img: "../assets/images/game-shows/GAME-SHOW-20.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "n5emwq5c5dwepwam",
      },

      {
        id: 21,
        img: "../assets/images/game-shows/GAME-SHOW-21.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 22,
        img: "../assets/images/game-shows/GAME-SHOW-22.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 23,
        img: "../assets/images/game-shows/GAME-SHOW-23.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DoubleBallRou001",
      },
      {
        id: 24,
        img: "../assets/images/game-shows/GAME-SHOW-24.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 25,
        img: "../assets/images/game-shows/GAME-SHOW-25.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 26,
        img: "../assets/images/game-shows/GAME-SHOW-26.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },

      {
        id: 27,
        img: "../assets/images/game-shows/GAME-SHOW-27.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 28,
        img: "../assets/images/game-shows/GAME-SHOW-28.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 29,
        img: "../assets/images/game-shows/GAME-SHOW-29.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DragonTiger00001",
      },
      {
        id: 30,
        img: "../assets/images/game-shows/GAME-SHOW-30.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DragonTiger00001",
      },
      {
        id: 31,
        img: "../assets/images/game-shows/GAME-SHOW-31.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "LightningSbj0001",
      },
      {
        id: 32,
        img: "../assets/images/game-shows/GAME-SHOW-32.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "FreeBet000000001",
      },
    ],
  },
  {
    id: 2,
    title: "Baccarat",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/Baccarat/BACCARAT-1.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "gwbaccarat000001",
      },
      {
        id: 2,
        img: "../assets/images/Baccarat/BACCARAT-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "gwbaccarat000001",
      },
      {
        id: 3,
        img: "../assets/images/Baccarat/BACCARAT-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 4,
        img: "../assets/images/Baccarat/BACCARAT-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 5,
        img: "../assets/images/Baccarat/BACCARAT-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "LightningBac0001",
      },
      {
        id: 6,
        img: "../assets/images/Baccarat/BACCARAT-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 7,
        img: "../assets/images/Baccarat/BACCARAT-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 8,
        img: "../assets/images/Baccarat/BACCARAT-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 9,
        img: "../assets/images/Baccarat/BACCARAT-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 10,
        img: "../assets/images/Baccarat/BACCARAT-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 11,
        img: "../assets/images/Baccarat/BACCARAT-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 12,
        img: "../assets/images/Baccarat/BACCARAT-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 13,
        img: "../assets/images/Baccarat/BACCARAT-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 14,
        img: "../assets/images/Baccarat/BACCARAT-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "LightningBac0001",
      },
      {
        id: 15,
        img: "../assets/images/Baccarat/BACCARAT-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 16,
        img: "../assets/images/Baccarat/BACCARAT-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 17,
        img: "../assets/images/Baccarat/BACCARAT-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 18,
        img: "../assets/images/Baccarat/BACCARAT-18.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "gwbaccarat000001",
      },
      {
        id: 19,
        img: "../assets/images/Baccarat/BACCARAT-19.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 20,
        img: "../assets/images/Baccarat/BACCARAT-20.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 21,
        img: "../assets/images/Baccarat/BACCARAT-21.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 22,
        img: "../assets/images/Baccarat/BACCARAT-22.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "peekbaccarat0001",
      },
      {
        id: 23,
        img: "../assets/images/Baccarat/BACCARAT-23.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 24,
        img: "../assets/images/Baccarat/BACCARAT-24.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 25,
        img: "../assets/images/Baccarat/BACCARAT-25.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 26,
        img: "../assets/images/Baccarat/BACCARAT-26.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "leqhceumaq6qfoug",
      },
      {
        id: 27,
        img: "../assets/images/Baccarat/BACCARAT-27.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "gwbaccarat000001",
      },
      {
        id: 28,
        img: "../assets/images/Baccarat/BACCARAT-28.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "gwbaccarat000001",
      },
    ],
  },
  {
    id: 3,
    title: "Roulette",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/Roulette/ROULETTE-1.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 2,
        img: "../assets/images/Roulette/ROULETTE-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DoubleBallRou001",
      },
      {
        id: 3,
        img: "../assets/images/Roulette/ROULETTE-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "XxxtremeLigh0001",
      },
      {
        id: 4,
        img: "../assets/images/Roulette/ROULETTE-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DoubleBallRou001",
      },
      {
        id: 5,
        img: "../assets/images/Roulette/ROULETTE-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "DoubleBallRou001",
      },
      {
        id: 6,
        img: "../assets/images/Roulette/ROULETTE-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 7,
        img: "../assets/images/Roulette/ROULETTE-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 8,
        img: "../assets/images/Roulette/ROULETTE-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 9,
        img: "../assets/images/Roulette/ROULETTE-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 10,
        img: "../assets/images/Roulette/ROULETTE-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 11,
        img: "../assets/images/Roulette/ROULETTE-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 12,
        img: "../assets/images/Roulette/ROULETTE-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 13,
        img: "../assets/images/Roulette/ROULETTE-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 14,
        img: "../assets/images/Roulette/ROULETTE-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "48z5pjps3ntvqc1b",
      },
      {
        id: 15,
        img: "../assets/images/Roulette/ROULETTE-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 16,
        img: "../assets/images/Roulette/ROULETTE-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 17,
        img: "../assets/images/Roulette/ROULETTE-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 18,
        img: "../assets/images/Roulette/ROULETTE-18.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 19,
        img: "../assets/images/Roulette/ROULETTE-19.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 20,
        img: "../assets/images/Roulette/ROULETTE-20.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 21,
        img: "../assets/images/Roulette/ROULETTE-21.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
    ],
  },
  {
    id: 4,
    title: "Dice",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/Dice/DICE-1.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "BacBo00000000001",
      },
      {
        id: 2,
        img: "../assets/images/Dice/DICE-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 3,
        img: "../assets/images/Dice/DICE-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Craps00000000001",
      },
      {
        id: 4,
        img: "../assets/images/Dice/DICE-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 5,
        img: "../assets/images/Dice/DICE-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 6,
        img: "../assets/images/Dice/DICE-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
      {
        id: 7,
        img: "../assets/images/Dice/DICE-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
      },
    ],
  },
  {
    id: 5,
    title: "Traditional Games",
    gamesCard: [
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-1.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVO",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVO",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVO",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVO",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVO",
        type: "TRADITIONAL",
      },

      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "SEXY",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "SEXY",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "SEXY",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "SEXY",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "TRADITIONAL",
      },

      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PP",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PP",
        type: "TRADITIONAL",
      },

      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "VENUS",
        type: "TRADITIONAL",
      },
      {
        img: "../assets/images/TraditionalGames/TRADINATIONAL-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "VENUS",
        type: "TRADITIONAL",
      },
    ],
  },
  {
    id: 5,
    title: "Card Games",
    gamesCard: [
      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-181.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-208.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },

      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-002.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-015.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-009.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },

      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-012.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/EVOLUTION-LIVE-024.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "EVOLUTION",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PT-LIVE-006.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PT-LIVE-007.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PP-LIVE-040.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PP",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PP-LIVE-041.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PP",
        type: "GAME-CARD",
      },

      {
        img: "../assets/images/CardGames/PT-LIVE-008.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PT-LIVE-010.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },

      {
        img: "../assets/images/CardGames/PT-LIVE-016.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PT-LIVE-017.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
      {
        img: "../assets/images/CardGames/PT-LIVE-019.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "SuperSicBo000001",
        cateogry: "PT",
        type: "GAME-CARD",
      },
    ],
  },
];

export const tableSelectionArray = [
  {
    id: 1,
    title: "",
    gamesCard: [
      // {
      //   id: 1,
      //   img: "../assets/images/Table/TABLE-1.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "XxxtremeLigh0001",
      // },
      {
        id: 2,
        img: "../assets/images/Table/TABLE-2.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CashOrCrash00001",
      },
      {
        id: 3,
        img: "../assets/images/Table/TABLE-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "FunkyTime0000001",
      },
      {
        id: 4,
        img: "../assets/images/Table/TABLE-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "doasaloon0000001",
      },
      {
        id: 5,
        img: "../assets/images/Table/TABLE-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },
      {
        id: 6,
        img: "../assets/images/Table/TABLE-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },

      {
        id: 7,
        img: "../assets/images/Table/TABLE-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 8,
        img: "../assets/images/Table/TABLE-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 9,
        img: "../assets/images/Table/TABLE-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      // {
      //   id: 10,
      //   img: "../assets/images/Table/TABLE-10.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "ImperialQuest001",
      // },
      // {
      //   id: 11,
      //   img: "../assets/images/Table/TABLE-11.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "Monopoly00000001",
      // },
      {
        id: 12,
        img: "../assets/images/Table/TABLE-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 13,
        img: "../assets/images/Table/TABLE-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyCoinFlip001",
      },
      {
        id: 14,
        img: "../assets/images/Table/TABLE-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 15,
        img: "../assets/images/Table/TABLE-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 16,
        img: "../assets/images/Table/TABLE-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      // {
      //   id: 17,
      //   img: "../assets/images/Table/TABLE-17.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "dealnodeal000001",
      // },
      // {
      //   id: 18,
      //   img: "../assets/images/Table/TABLE-18.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "RngMegaBall00001",
      // },
      // {
      //   id: 19,
      //   img: "../assets/images/Table/TABLE-19.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "MOWDream00000001",
      // },

      // {
      //   id: 20,
      //   img: "../assets/images/Table/TABLE-20.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "n5emwq5c5dwepwam",
      // },

      // {
      //   id: 21,
      //   img: "../assets/images/Table/TABLE-21.webp",
      //   platForm: "1006",
      //   gameType: "1",
      //   casinoType: "LIVE CASINO",
      //   gameid: "RngMegaBall00001",
      // },
    ],
  },
];

export const slotSelectionArray = [
  {
    id: 1,
    title: "",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/slot/KM-SLOT-001.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "XxxtremeLigh0001",
      },
      {
        id: 2,
        img: "../assets/images/slot/KM-SLOT-001.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CashOrCrash00001",
      },
      {
        id: 3,
        img: "../assets/images/Table/TABLE-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "FunkyTime0000001",
      },
      {
        id: 4,
        img: "../assets/images/Table/TABLE-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "doasaloon0000001",
      },
      {
        id: 5,
        img: "../assets/images/Table/TABLE-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },
      {
        id: 6,
        img: "../assets/images/Table/TABLE-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },

      {
        id: 7,
        img: "../assets/images/Table/TABLE-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 8,
        img: "../assets/images/Table/TABLE-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 9,
        img: "../assets/images/Table/TABLE-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 10,
        img: "../assets/images/Table/TABLE-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "ImperialQuest001",
      },
      {
        id: 11,
        img: "../assets/images/Table/TABLE-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 12,
        img: "../assets/images/Table/TABLE-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 13,
        img: "../assets/images/Table/TABLE-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyCoinFlip001",
      },
      {
        id: 14,
        img: "../assets/images/Table/TABLE-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 15,
        img: "../assets/images/Table/TABLE-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 16,
        img: "../assets/images/Table/TABLE-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 17,
        img: "../assets/images/Table/TABLE-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 18,
        img: "../assets/images/Table/TABLE-18.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 19,
        img: "../assets/images/Table/TABLE-19.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "MOWDream00000001",
      },

      {
        id: 20,
        img: "../assets/images/Table/TABLE-20.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "n5emwq5c5dwepwam",
      },

      {
        id: 21,
        img: "../assets/images/Table/TABLE-21.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
    ],
  },
];

export const fishingSelectionArray = [
  {
    id: 1,
    title: "",
    gamesCard: [
      {
        id: 1,
        img: "../assets/images/slot/KM-SLOT-001.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "XxxtremeLigh0001",
      },
      {
        id: 2,
        img: "../assets/images/slot/KM-SLOT-001.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CashOrCrash00001",
      },
      {
        id: 3,
        img: "../assets/images/Table/TABLE-3.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "FunkyTime0000001",
      },
      {
        id: 4,
        img: "../assets/images/Table/TABLE-4.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "doasaloon0000001",
      },
      {
        id: 5,
        img: "../assets/images/Table/TABLE-5.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },
      {
        id: 6,
        img: "../assets/images/Table/TABLE-6.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyTime0000001",
      },

      {
        id: 7,
        img: "../assets/images/Table/TABLE-7.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 8,
        img: "../assets/images/Table/TABLE-8.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 9,
        img: "../assets/images/Table/TABLE-9.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "oviijmmqdaatpvon",
      },
      {
        id: 10,
        img: "../assets/images/Table/TABLE-10.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "ImperialQuest001",
      },
      {
        id: 11,
        img: "../assets/images/Table/TABLE-11.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 12,
        img: "../assets/images/Table/TABLE-12.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "Monopoly00000001",
      },
      {
        id: 13,
        img: "../assets/images/Table/TABLE-13.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "CrazyCoinFlip001",
      },
      {
        id: 14,
        img: "../assets/images/Table/TABLE-14.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 15,
        img: "../assets/images/Table/TABLE-15.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 16,
        img: "../assets/images/Table/TABLE-16.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 17,
        img: "../assets/images/Table/TABLE-17.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "dealnodeal000001",
      },
      {
        id: 18,
        img: "../assets/images/Table/TABLE-18.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
      {
        id: 19,
        img: "../assets/images/Table/TABLE-19.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "MOWDream00000001",
      },

      {
        id: 20,
        img: "../assets/images/Table/TABLE-20.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "n5emwq5c5dwepwam",
      },

      {
        id: 21,
        img: "../assets/images/Table/TABLE-21.webp",
        platForm: "1006",
        gameType: "1",
        casinoType: "LIVE CASINO",
        gameid: "RngMegaBall00001",
      },
    ],
  },
];

export const showMenuUsingPath = [{ path: "/change-password" }, { path: "" }];

export const casinoimg = [
  { id: 1, img: c1 },
  { id: 2, img: c2 },
  { id: 3, img: c3 },
  { id: 4, img: c4 },
  { id: 5, img: c5 },
  { id: 6, img: c6 },
  { id: 7, img: c7 },
  { id: 8, img: c8 },
  { id: 9, img: c9 },
  { id: 10, img: c10 },
  { id: 11, img: c11 },
  { id: 12, img: c12 },
];
export const NewLiveCasino = [
  {
    Id: 301990,
    ProductId: 14457,
    ProductName: "Sweet Powernudge",
    ProductDescription: "Sweet Powernudge",
    State: 1,
    Rating: null,
    Percent: 14.0,
    PartnerId: 71,
    ProductGameProviderId: 54,
    GameProviderName: "PragmaticPlay",
    CategoryIds: [30, 29, 1, 28, 25],
    RTP: null,
    ExternalId: "vs20clspwrndg",
    Volatility: null,
    SubproviderId: 54,
    OpenMode: null,
    IsForMobile: true,
    IsForDesktop: true,
    HasDemo: true,
    Jackpot: null,
    MobileImageUrl: "pragmaticplay/mob/14457.png",
    WebImageUrl: "pragmaticplay/web/14457.png",
    CreationTime: "2023-09-12T09:54:34.682",
    LastUpdateTime: "2023-10-17T12:00:30.847",
  },
  {
    Id: 301990,
    ProductId: 14457,
    ProductName: "Sweet Powernudge",
    ProductDescription: "Sweet Powernudge",
    State: 1,
    Rating: null,
    Percent: 14.0,
    PartnerId: 71,
    ProductGameProviderId: 54,
    GameProviderName: "PragmaticPlay",
    CategoryIds: [30, 29, 1, 28, 25],
    RTP: null,
    ExternalId: "vs20clspwrndg",
    Volatility: null,
    SubproviderId: 54,
    OpenMode: null,
    IsForMobile: true,
    IsForDesktop: true,
    HasDemo: true,
    Jackpot: null,
    MobileImageUrl: "pragmaticplay/mob/14457.png",
    WebImageUrl: "pragmaticplay/web/14457.png",
    CreationTime: "2023-09-12T09:54:34.682",
    LastUpdateTime: "2023-10-17T12:00:30.847",
  },

  {
    Id: 301990,
    ProductId: 14457,
    ProductName: "Sweet Powernudge",
    ProductDescription: "Sweet Powernudge",
    State: 1,
    Rating: null,
    Percent: 14.0,
    PartnerId: 71,
    ProductGameProviderId: 54,
    GameProviderName: "PragmaticPlay",
    CategoryIds: [30, 29, 1, 28, 25],
    RTP: null,
    ExternalId: "vs20clspwrndg",
    Volatility: null,
    SubproviderId: 54,
    OpenMode: null,
    IsForMobile: true,
    IsForDesktop: true,
    HasDemo: true,
    Jackpot: null,
    MobileImageUrl: "pragmaticplay/mob/14457.png",
    WebImageUrl: "pragmaticplay/web/14457.png",
    CreationTime: "2023-09-12T09:54:34.682",
    LastUpdateTime: "2023-10-17T12:00:30.847",
  },
  {
    Id: 301990,
    ProductId: 14457,
    ProductName: "Sweet Powernudge",
    ProductDescription: "Sweet Powernudge",
    State: 1,
    Rating: null,
    Percent: 14.0,
    PartnerId: 71,
    ProductGameProviderId: 54,
    GameProviderName: "PragmaticPlay",
    CategoryIds: [30, 29, 1, 28, 25],
    RTP: null,
    ExternalId: "vs20clspwrndg",
    Volatility: null,
    SubproviderId: 54,
    OpenMode: null,
    IsForMobile: true,
    IsForDesktop: true,
    HasDemo: true,
    Jackpot: null,
    MobileImageUrl: "pragmaticplay/mob/14457.png",
    WebImageUrl: "pragmaticplay/web/14457.png",
    CreationTime: "2023-09-12T09:54:34.682",
    LastUpdateTime: "2023-10-17T12:00:30.847",
  },
  {
    Id: 301990,
    ProductId: 14457,
    ProductName: "Sweet Powernudge",
    ProductDescription: "Sweet Powernudge",
    State: 1,
    Rating: null,
    Percent: 14.0,
    PartnerId: 71,
    ProductGameProviderId: 54,
    GameProviderName: "PragmaticPlay",
    CategoryIds: [30, 29, 1, 28, 25],
    RTP: null,
    ExternalId: "vs20clspwrndg",
    Volatility: null,
    SubproviderId: 54,
    OpenMode: null,
    IsForMobile: true,
    IsForDesktop: true,
    HasDemo: true,
    Jackpot: null,
    MobileImageUrl: "pragmaticplay/mob/14457.png",
    WebImageUrl: "pragmaticplay/web/14457.png",
    CreationTime: "2023-09-12T09:54:34.682",
    LastUpdateTime: "2023-10-17T12:00:30.847",
  },
];




export const DepositWithrawDataArrayNew = [
  {
    game_id: "900000",
    game_name: "Royal Gaming Live Lobby",
    category: "LOBBY",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Lobby",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/rg_live_lobby.webp",
    game_code: "RG-LIVELOBBY",
  },

  // {
  //   game_id: "000000",
  //   game_name: "GAP LOBBY",
  //   category: "LOBBY",
  //   provider_name: "GAPLOBBY",
  //   sub_provider_name: "GAPLOBBY",
  //   status: "ACTIVE",
  //   url_thumb: "https://cdn.dreamdelhi.com/gap-logo.webp",
  //   game_code: "gap_lobby",
  // },
  {
    game_id: "203552",
    game_name: "BOMBAY LIVE",
    category: "Live Games",
    provider_name: "DC",
    sub_provider_name: " Bombay Live",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamcasino.live/bbl_bombaylivelobby.webp",
    game_code: "bbl_bombaylivelobby",
  },
  {
    game_id: "100000",
    game_name: "Ezugi Lobby",
    category: "LOBBY",
    provider_name: "EZUGI",
    sub_provider_name: "Ezugi",
    status: "ACTIVE",
    url_thumb: "https://cdn.hub88.io/ezugi/ezg_blackjackgold5.jpg",
    game_code: "ezg_lobby",
  },

  {
    image: evo,
    title: "EVOLUTION",
    type: "evo",
    key: "live",
    platForm: "EVOLUTION",
    gameType: "",
    casinoType: "EVOLUTION-LIVE-001",
    isLobbyTrue: true,
  },
  {
    image: "../assets/images/Aura/auraLobby.webp",
    title: "Lobby",
    gameCode: "98789",
    legacy: "aura",
  },
  {
    image: sexy,
    title: "SEXYBCRT",
    type: "sexy",
    key: "live",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "",
    isLobbyTrue: true,
  },

  {
    image: iconhotroad,
    title: "HOTROAD",
    type: "hotroad",
    key: "live",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "",
    isLobbyTrue: true,
  },
  {
    image: pt,
    title: "PLAYTECH",
    type: "pt",
    key: "live",
    platForm: "PT",
    isLobbyTrue: true,
  },

  {
    image: betgames,
    title: "BG",
    type: "bg",
    key: "live",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "",
  },

  {
    image: pp,
    title: "PP",
    type: "pp",
    key: "live",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "",
  },

  {
    image: esport,
    title: "E1SPORT",
    type: "e1sports",
    key: "live",
    gameTypeCheck: "platForm",
    gameType: "ESPORTS",
    platForm: "E1SPORT",
    casinoType: "",
  },

  {
    image: sv388,
    title: "SV388",
    type: "sv",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "SV388",
    gameType: "LIVE",
    casinoType: "",
  },

  {
    image: Jili,
    title: "JILI",
    type: "jili",
    key: "slot",
    gameType: "SLOT",
    platForm: "JILI",
  },
  {
    image: pg,
    title: "PG",
    type: "pg",
    key: "slot",
    gameType: "SLOT",
    platForm: "PG",
  },
  {
    image: jdb,
    title: "JDB",
    type: "jdb",
    key: "slot",
    gameType: "SLOT",
    platForm: "JDB",
  },

  {
    image: fastspin,
    title: "FASTSPIN",
    type: "FastSpin",
    key: "slot",
    gameType: "SLOT",
    platForm: "FASTSPIN",
  },

  {
    image: fc,
    title: "FC",
    type: "fc",
    key: "slot",
    gameType: "SLOT",
    platForm: "FC",
  },
  {
    image: sg,
    title: "SG",
    type: "spade",
    key: "slot",
    gameType: "SLOT",
    platForm: "SPADE",
  },
  {
    image: yesbingo,
    title: "Yes Bingo",
    type: "yesbingo",
    key: "slot",
    gameType: "SLOT",
    platForm: "YESBINGO",
  },
  {
    image: rt,
    title: "RT",
    type: "rt",
    key: "slot",
    gameType: "",
    platForm: "RT",
  },
  {
    image: pt,
    title: "PT",
    type: "pt",
    key: "slot",
    gameType: "SLOT",
    platForm: "PT",
  },

  {
    image: joker,
    title: "Joker",
    type: "Joker",
    key: "slot",
    gameType: "SLOT",
    platForm: "JOKER",
    casinoType: "",
  },

  {
    image: netent,
    title: "NETENT",
    type: "NETENT",
    key: "slot",
    gameType: "NETENT",
    platForm: "",
    casinoType: "",
  },

  {
    image: p8,
    title: "P8",
    type: "PLAYSTAR",
    key: "slot",
    gameType: "SLOT",
    platForm: "PLAY8",
    casinoType: "",
  },

  {
    image: btg,
    title: "BTG",
    type: "btg",
    key: "slot",
    gameType: "SLOT",
    platForm: "BTG",
  },

  {
    image: km,
    title: "KINGMAKER",
    type: "kingmaker",
    key: "slot",
    gameType: "SLOT",
    platForm: "KINGMAKER",
  },

  {
    image: dragonsoft,
    title: "DRAGOONSOFT",
    type: "dragoonsoft",
    key: "slot",
    gameType: "SLOT",
    platForm: "DRAGOONSOFT",
  },

  {
    image: nlcLogo,
    title: "NLC",
    type: "NLC",
    key: "SLOT",
    platForm: "NLC",
    gameType: "SLOT",
  },

  {
    image: spribe,
    title: "SPRIBE",
    type: "SPRIBE",
    key: "table",
    platForm: "SPRIBE",
    gameType: "",
    casinoType: "",
  },

  {
    image: iconSportbook,
    title: "SABAVIRTUAL",
    type: "SABAVIRTUAL",
    key: "lottery",
    // gameTypeCheck: "platForm",
    platForm: "SABA",
    gameType: "",
    casinoType: "",
  },

  {
    game_id: "999999",
    game_name: "Royal Gaming Mobile Lobby",
    category: "LOBBY",
    provider_name: "RG",
    sub_provider_name: "Royal Gaming Lobby",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/rg/yuvi.webp",
    game_code: "RG_MLOBBY",
  },
  {
    game_id: "280000",
    game_name: "Vivo Lobby",
    category: "LOBBY",
    provider_name: "DC",
    sub_provider_name: "Vivo Games",
    status: "ACTIVE",
    url_thumb: VivoImage,
    game_code: "vivo_lobby",
  },
  {
    game_id: "350006",
    game_name: "E-game Lobby",
    category: "LOBBY",
    provider_name: "EA",
    sub_provider_name: "EA",
    status: "ACTIVE",
    url_thumb: "https://cdn.dreamdelhi.com/ea/Egame_lobby.webp",
    game_code: "ea_e-game_lobby",
  },
];

export const EzugiNew = [
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_blackjackgold5.webp",
    name: "Blackjack Gold 5",
    gameCode: "ezg_blackjackgold5",
    category: "Live Blackjack",
    gameId: 100001,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_blackjackplatinum1.webp",
    name: "Blackjack Platinum 1",
    gameCode: "ezg_blackjackplatinum1",
    category: "Live Blackjack",
    gameId: 100003,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/gold_blackjack_1.webp",
    name: "Blackjack Gold 3",
    gameCode: "ezg_blackjackgold3",
    category: "Live Blackjack",
    gameId: 100005,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/gold_blackjack_2.webp",
    name: "Blackjack Gold 1",
    gameCode: "ezg_blackjackgold1",
    category: "Live Blackjack",
    gameId: 100006,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/gold_blackjack_3.webp",
    name: "Blackjack Gold 6",
    gameCode: "ezg_blackjackgold6",
    category: "Live Blackjack",
    gameId: 100008,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/vip-bj-surrender-thumb-v2.webp",
    name: "VIP Blackjack with Surrender",
    gameCode: "ezg_vipblackjackwithsurrender",
    category: "Live Blackjack",
    gameId: 100009,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/vip-bj-thumb-v2.webp",
    name: "VIP Blackjack",
    gameCode: "ezg_vipblackjack",
    category: "Live Blackjack",
    gameId: 100010,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/rumba_blackjack_2.webp",
    name: "Rumba Blackjack 2",
    gameCode: "ezg_rumbablackjack2",
    category: "Live Blackjack",
    gameId: 100013,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_unlimitedblackjack.webp",
    name: "Unlimited Blackjack",
    gameCode: "ezg_unlimitedblackjack",
    category: "Live Blackjack",
    gameId: 100022,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/unlimited_blackjack_turkish.webp",
    name: "Unlimited Turkish Blackjack",
    gameCode: "ezg_unlimitedturkishblackjack",
    category: "Live Blackjack",
    gameId: 100023,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/440x528+ubj++es.webp",
    name: "Mambo Unlimited Blackjack",
    gameCode: "ezg_mambounlimitedblackjack",
    cateogry: "Ezugi",
    gameId: 100024,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/italian roulette.webp",
    name: "Italian Roulette",
    gameCode: "ezg_italianroulette",
    cateogry: "Ezugi",
    gameId: 100026,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/speed_roulette_221000.webp",
    name: "Speed Roulette",
    gameCode: "ezg_speedroulette",
    cateogry: "Ezugi",
    gameId: 100027,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/diamond-roulette-thumb-v2.webp",
    name: "Diamond Roulette",
    gameCode: "ezg_diamondroulette",
    cateogry: "Ezugi",
    gameId: 100028,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/namaste-roulette-thumb-v2.webp",
    name: "Namaste Roulette",
    gameCode: "ezg_namasteroulette",
    cateogry: "Ezugi",
    gameId: 100029,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_cumbiaruleta1.webp",
    name: "Cumbia Ruleta 1",
    gameCode: "ezg_cumbiaruleta1",
    cateogry: "Ezugi",
    gameId: 100030,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/turkish_roulette.webp",
    name: "Türkçe Rulet",
    gameCode: "ezg_turkishroulette",
    cateogry: "Ezugi",
    gameId: 100031,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ruletka russia.webp",
    name: "Ruletka Russia",
    gameCode: "ezg_ruletkarussia",
    cateogry: "Ezugi",
    gameId: 100032,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/auto-roulette-thumbb.webp",
    name: "Automatic Roulette",
    gameCode: "ezg_autoroulette",
    cateogry: "Ezugi",
    gameId: 100034,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_speedautoroulette.webp",
    name: "Speed Auto Roulette",
    gameCode: "ezg_speedautoroulette",
    cateogry: "Ezugi",
    gameId: 100035,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_prestigeautoroulette.webp",
    name: "Prestige Auto Roulette",
    gameCode: "ezg_prestigeautoroulette",
    cateogry: "Ezugi",
    gameId: 100036,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_portomasocasinoroulette.webp",
    name: "Portomaso Casino Roulette",
    gameCode: "ezg_portomasocasinoroulette",
    cateogry: "Ezugi",
    gameId: 100037,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/oracle_roulette.webp",
    name: "Oracle Casino Roulette",
    gameCode: "ezg_oraclecasinoroulette",
    cateogry: "Ezugi",
    gameId: 100038,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_oraclecasinoroulette.webp",
    name: "Oracle Casino Roulette 360",
    gameCode: "ezg_oraclecasinoroulette360",
    cateogry: "Ezugi",
    gameId: 100039,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_marinacasinoroulette.webp",
    name: "Marina Casino Roulette",
    gameCode: "ezg_marinacasinoroulette",
    cateogry: "Ezugi",
    gameId: 100042,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_OTT_roulette_2-thumb.webp",
    name: "Marina Casino Roulette2",
    gameCode: "ezg_casinomarinaroulette2",
    cateogry: "Ezugi",
    gameId: 100043,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_ruleta-del-sol-thumb.webp",
    name: "Ruleta del Sol",
    gameCode: "ezg_ruletaclasicaperu",
    cateogry: "Ezugi",
    gameId: 100044,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_casinoholdem.webp",
    name: "Casino Hold'em",
    gameCode: "ezg_casinoholdem",
    cateogry: "Ezugi",
    gameId: 100045,
    Cat: "Live Poker",
  },
  {
    img: "https://cdn.dreamcasino.live/teen_patti_227100.webp",
    name: "Teen Patti",
    gameCode: "ezg_teenpatti",
    cateogry: "Ezugi",
    gameId: 100046,
    Cat: "Live Popular",
  },
  {
    img: "https://cdn.dreamcasino.live/beton_teenpatti.webp",
    name: "Bet on Teen Patti",
    gameCode: "ezg_betonteenpatti",
    cateogry: "Ezugi",
    gameId: 100047,
    Cat: "Live Popular",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_onedayteenpatticlassic.webp",
    name: "One Day Teen Patti Classic",
    gameCode: "ezg_onedayteenpatticlassic",
    cateogry: "Ezugi",
    gameId: 100048,
    Cat: "Live Popular",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_baccarat.webp",
    name: "Baccarat",
    gameCode: "ezg_baccarat",
    cateogry: "Ezugi",
    gameId: 100049,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamcasino.live/speed_bacaratt_cricket.webp",
    name: "Speed Baccarat- Cricket",
    gameCode: "ezg_speedcricketbaccarat",
    cateogry: "Ezugi",
    gameId: 100050,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_marinacasinobaccarat1.webp",
    name: "Marina Casino Baccarat 1",
    gameCode: "ezg_marinacasinobaccarat1",
    cateogry: "Ezugi",
    gameId: 100054,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamcasino.live/marina_casino_baccarat_2.webp",
    name: "Marina Casino Baccarat 2",
    gameCode: "ezg_marinacasinobaccarat2",
    cateogry: "Ezugi",
    gameId: 100055,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_OTT_Baccarat_3-thumb.webp",
    name: "Marina Casino Baccarat 3",
    gameCode: "ezg_casinomarinabaccarat3",
    cateogry: "Ezugi",
    gameId: 100056,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_OTT_Baccarat_4-thumb.webp",
    name: "Marina Casino Baccarat 4",
    gameCode: "ezg_casinomarinabaccarat4",
    cateogry: "Ezugi",
    gameId: 100057,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_goldenbaccaratknockout.webp",
    name: "Baccarat Knockout",
    gameCode: "ezg_goldenbaccaratknockout",
    cateogry: "Ezugi",
    gameId: 100058,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_baccaratsuper6.webp",
    name: "Baccarat Super Six",
    gameCode: "ezg_baccaratsuper6",
    cateogry: "Ezugi",
    gameId: 100059,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamcasino.live/no_commission_baccarat_170.webp",
    name: "No Commission Baccarat",
    gameCode: "ezg_nocommissionbaccarat",
    cateogry: "Ezugi",
    gameId: 100060,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ezg_dragontiger.webp",
    name: "Dragon Tiger",
    gameCode: "ezg_dragontiger",
    cateogry: "Ezugi",
    gameId: 100061,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamcasino.live/cricket_war.webp",
    name: "Cricket War",
    gameCode: "ezg_cricketwar",
    cateogry: "Ezugi",
    Cat: "Live Baccarat",
    gameId: 100062,
  },
  {
    img: "https://cdn.dreamcasino.live/andar_bahar_228000.webp",
    name: "Andar Bahar",
    gameCode: "ezg_andarbahar",
    cateogry: "Ezugi",
    gameId: 100063,
    Cat: "Live Popular",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/ott-ab-thumbnail.webp",
    name: "OTT Andar Bahar",
    gameCode: "ezg_ottab",
    cateogry: "Ezugi",
    gameId: 100064,
    Cat: "Live Popular",
  },
  {
    img: "https://cdn.dreamcasino.live/lucky7_228001.webp",
    name: "Lucky 7",
    gameCode: "ezg_lucky7",
    cateogry: "Ezugi",
    gameId: 100066,
    Cat: "Live Popular",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/sicbo-thumbnail.webp",
    name: "Sic Bo",
    gameCode: "ezg_sicbo",
    cateogry: "Ezugi",
    gameId: 100068,
    Cat: "Live Popular",
  },
  {
    img: "https://hx-app-assets.s3.eu-west-2.amazonaws.com/gap_casino/ezg_fortune_baccarat.jpg",
    name: "Fortune Baccarat",
    gameCode: "ezg_fortune_baccarat",
    cateogry: "Ezugi",
    gameId: 100069,
    Cat: "baccarat",
  },
  {
    img: "https://hx-app-assets.s3.eu-west-2.amazonaws.com/gap_casino/ezg_speed_fortunebaccarat.jpg",
    name: "Speed Fortune Baccarat",
    gameCode: "ezg_speed_fortune_baccarat",
    cateogry: "Ezugi",
    gameId: 100070,
    Cat: "baccarat",
  },
  {
    img: "https://hx-app-assets.s3.eu-west-2.amazonaws.com/gap_casino/ezg_blackjack_salonprive.jpg",
    name: "Salon Prive Blackjack",
    gameCode: "ezg_salon_prive_blackjack",
    cateogry: "Ezugi",
    gameId: 100073,
    Cat: "blackjack",
  },
  {
    img: "https://hx-app-assets.s3.eu-west-2.amazonaws.com/gap_casino/ezg_ultimate_sicbo.jpg",
    name: "Ultimate Sic Bo",
    gameCode: "ezg_ultimate_sic_bo",
    cateogry: "Ezugi",
    gameId: 100074,
    Cat: "sicbo",
  },
  {
    img: "https://hx-app-assets.s3.eu-west-2.amazonaws.com/gap_casino/ezg_ultimate_andarbahar.jpg",
    name: "Ultimate Andar Bahar",
    gameCode: "ezg_ultimate_andar_bahar",
    cateogry: "Ezugi",
    gameId: 100075,
    Cat: "andar_bahar",
  },
  {
    img: "https://hx-app-assets.s3.eu-west-2.amazonaws.com/gap_casino/ezg_ez_dealer_roulette.jpg",
    name: "EZ Roulette",
    gameCode: "ezg_ez_roulette",
    cateogry: "Ezugi",
    gameId: 100076,
    Cat: "roulette",
  },
  {
    img: "https://hx-app-assets.s3.eu-west-2.amazonaws.com/gap_casino/ezg_thai_roulette.jpg",
    name: "EZ Dealer Roulette Thai",
    gameCode: "ezg_ez_dealer_roulette_thai",
    cateogry: "Ezugi",
    gameId: 100077,
    Cat: "roulette",
  },
  {
    img: "https://hx-app-assets.s3.eu-west-2.amazonaws.com/gap_casino/ezg_ez_dealer_roulette_japanese.jpg",
    name: "EZ Dealer Roulette Japanese",
    gameCode: "ezg_ez_dealer_roulette_japanese",
    cateogry: "Ezugi",
    gameId: 100078,
    Cat: "roulette",
  },
  {
    img: "https://hx-app-assets.s3.eu-west-2.amazonaws.com/gap_casino/ez_dealer_roulette_mandarin.jpg",
    name: "EZ Dealer Roulette Mandarin",
    gameCode: "ezg_ez_dealer_roulette_mandarin",
    cateogry: "Ezugi",
    gameId: 100079,
    Cat: "roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/ultimate_roulette.webp",
    name: "Ultimate Roulette",
    gameCode: "ezg_ultimate_roulette",
    cateogry: "Ezugi",
    gameId: 100082,
    Cat: "roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/blackjack_da_sorte.webp",
    name: "Blackjack da Sorte",
    gameCode: "ezg_blackjack_da_sorte",
    cateogry: "Ezugi",
    gameId: 100083,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/roleta_da_sorte.webp",
    name: "Roleta da Sorte",
    gameCode: "ezg_roleta_da_sorte",
    cateogry: "Ezugi",
    gameId: 100089,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/ruleta_a.webp",
    name: "Ruleta A",
    gameCode: "ezg_ruleta_a",
    cateogry: "Ezugi",
    gameId: 100090,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/vip_roulette.webp",
    name: "VIP Roulette",
    gameCode: "ezg_vip_roulette",
    cateogry: "Ezugi",
    gameId: 100091,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/ez_dealer_roleta_brasileira.webp",
    name: "EZ Dealer Roleta Brazileira",
    gameCode: "ezg_roleta_brazileira",
    cateogry: "Ezugi",
    gameId: 100092,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/baccarat_e.webp",
    name: "Baccarat E",
    gameCode: "ezg_baccarat_e",
    cateogry: "Ezugi",
    gameId: 100093,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/no_commission_baccarat_a.webp",
    name: "No Commission Baccarat A",
    gameCode: "ezg_nc_baccarat_a",
    cateogry: "Ezugi",
    gameId: 100094,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/no_commission_baccarat_b.webp",
    name: "No Commission Baccarat B",
    gameCode: "ezg_nc_baccarat_b",
    cateogry: "Ezugi",
    gameId: 100095,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/no_commission_baccarat_c.webp",
    name: "No Commission Baccarat C",
    gameCode: "ezg_nc_baccarat_c",
    cateogry: "Ezugi",
    gameId: 100096,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/no_commission_baccarat_d.webp",
    name: "No Commission Baccarat D",
    gameCode: "ezg_nc_baccarat_d",
    cateogry: "Ezugi",
    gameId: 100097,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/dt_da_sorte.webp",
    name: "Dragon Tiger da Sorte",
    gameCode: "ezg_dragong_tiger_da_sorte",
    cateogry: "Ezugi",
    gameId: 100098,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/cricket_auto_roulette.webp",
    name: "Cricket Auto Roulette",
    gameCode: "ezg_cricket_auto_roulette",
    cateogry: "Ezugi",
    gameId: 100099,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/football_auto_roulette.webp",
    name: "Football Auto Roulette",
    gameCode: "ezg_football_auto_roulette",
    cateogry: "Ezugi",
    gameId: 100100,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/32_cards.webp",
    name: "32 Cards",
    gameCode: "ezg_32_cards",
    cateogry: "Ezugi",
    gameId: 100101,
    Cat: "32 Cards",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/rumba_blackjack_3.webp",
    name: "Rumba Blackjack 3",
    gameCode: "ezg_rumbablackjack3",
    cateogry: "Ezugi",
    gameId: 100102,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/rumba_blackjack_4.webp",
    name: "Rumba Blackjack 4",
    gameCode: "ezg_rumbablackjack4",
    cateogry: "Ezugi",
    gameId: 100105,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamcasino.live/ezugi/rumba_blackjack_1.webp",
    name: "Rumba Blackjack 1",
    gameCode: "ezg_rumbablackjack1",
    cateogry: "Ezugi",
    gameId: 100106,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/ezg_skylineroulette.png",
    name: "Skyline Roulette",
    gameCode: "ezg_skylineroulette",
    cateogry: "Ezugi",
    gameId: 100107,
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/ezg_baccaratoverunder.webp",
    name: "Baccarat Over Under",
    gameCode: "ezg_baccaratoverunder",
    cateogry: "Ezugi",
    gameId: 100108,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/ezg_nocommbaccarat.webp",
    name: "No Commission Baccarat Over Under",
    gameCode: "ezg_nocommbaccarat",
    cateogry: "Ezugi",
    gameId: 100109,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/ezg_ezbaccarat.png",
    name: "EZ Baccarat",
    gameCode: "ezg_ezbaccarat",
    cateogry: "Ezugi",
    gameId: 100110,
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/matka.webp",
    name: "Matka",
    gameCode: "ezg_matka",
    cateogry: "Ezugi",
    gameId: 100111,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/xoc_dia.webp",
    name: "Xoc Dia",
    gameCode: "ezg_xoc_dia",
    cateogry: "Ezugi",
    gameId: 100112,
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamdelhi.com/ezugi/hindi_roulette.webp",
    name: "EZ Dealer Roulette Hindi",
    gameCode: "ezg_hindi_roulette",
    cateogry: "Ezugi",
    gameId: 100113,
    Cat: "Live Roulette",
  },
  // Add more games following the same format
];

export const RoyalGammingNew = [
  {
    img: "https://cdn.dreamcasino.live/rg_7up_7down.webp",
    name: "7 Up Down",
    gameCode: "RG-7UD01",
    category: "Royal Gaming",
    gameId: "900001",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_roulette.webp",
    name: "Auto Roulette",
    gameCode: "RG-ART01",
    category: "Royal Gaming",
    gameId: "900002",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_teen_patti.webp",
    name: "Teen Patti",
    gameCode: "RG-TP01",
    category: "Royal Gaming",
    gameId: "900003",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_dragon_tiger.webp",
    name: "Dragon Tiger",
    gameCode: "RG-DT01",
    category: "Royal Gaming",
    gameId: "900004",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_cricket_war.webp",
    name: "Cricket War",
    gameCode: "RG-CW01",
    category: "Royal Gaming",
    gameId: "900005",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamdelhi.com/rg/baccarat.webp",
    name: "Baccarat",
    gameCode: "RG-BAC01",
    category: "Royal Gaming",
    gameId: "900006",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_a3.webp",
    name: "Amar Akbar Anthony",
    gameCode: "RG-AAA01",
    category: "Royal Gaming",
    gameId: "900007",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_3cards_judgement.webp",
    name: "3 Cards Judgement",
    gameCode: "RG-3CJ01",
    category: "Royal Gaming",
    gameId: "900008",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_casino_war.webp",
    name: "Casino War",
    gameCode: "RG-CAW01",
    category: "Royal Gaming",
    gameId: "900009",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_worli_matka.webp",
    name: "Worli Matka",
    gameCode: "RG-WM01",
    category: "Royal Gaming",
    gameId: "900010",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_bollywood_casino.webp",
    name: "Bollywood Casino",
    gameCode: "RG-BOC01",
    category: "Royal Gaming",
    gameId: "900011",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_lottery.webp",
    name: "Lottery",
    gameCode: "RG-LOT01",
    category: "Royal Gaming",
    gameId: "900012",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_book_cricket.webp",
    name: "Book Cricket",
    gameCode: "RG-BCRIC01",
    category: "Royal Gaming",
    gameId: "900013",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_high_low.webp",
    name: "HighLow",
    gameCode: "RG-HL01",
    category: "Royal Gaming",
    gameId: "900014",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_muflis_teenpatti.webp",
    name: "Muflis Teen Patti",
    gameCode: "RG-MTP01",
    category: "Royal Gaming",
    gameId: "900015",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamdelhi.com/rg/teen_patti_one_day.webp",
    name: "One Day Teenpatti",
    gameCode: "RG-1TP101",
    category: "Royal Gaming",
    gameId: "900019",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_lucky7.webp",
    name: "Lucky7",
    gameCode: "RG-LK7101",
    category: "Royal Gaming",
    gameId: "900021",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_2card_tp.webp",
    name: "2 Cards Teenpatti",
    gameCode: "RG-2TP101",
    category: "Royal Gaming",
    gameId: "900025",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamdelhi.com/rg/dragon_tiger_one_day.webp",
    name: "Dragon Tiger One day",
    gameCode: "RG-1DT101",
    category: "Royal Gaming",
    gameId: "900027",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamcasino.live/rg_dtl.webp",
    name: "Dragon Tiger Lion",
    gameCode: "RG-DTL101",
    category: "Royal Gaming",
    gameId: "900031",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamdelhi.com/rg/trio.webp",
    name: "Trio",
    gameCode: "RG-TRI101",
    category: "Royal Gaming",
    gameId: "900037",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamdelhi.com/rg/2_card_teen_patti_one%20day.webp",
    name: "2 Card Teen Patti One Day",
    gameCode: "RG-12TP101",
    category: "Royal Gaming",
    gameId: "900039",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamdelhi.com/rg/center_card.webp",
    name: "Center Card",
    gameCode: "RG-CC101",
    category: "Royal Gaming",
    gameId: "900046",
    Cat: "TABLE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/hc.webp",
    name: "High Card",
    gameCode: "RG-HC101",
    category: "Royal Gaming",
    gameId: "900047",
    Cat: "TABLE",
  },
  {
    img: "https://cdn.dreamdelhi.com/rg/roulette.webp",
    name: "Roulette",
    gameCode: "RG-ROT101",
    category: "Royal Gaming",
    gameId: "900075",
    Cat: "TABLE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/ttp.webp",
    name: "Test Teenpatti",
    gameCode: "RG-TTP",
    category: "Royal Gaming",
    gameId: "900079",
    Cat: "LIVE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/itp2.webp",
    name: "Instant Teenpatti 2",
    gameCode: "RG-ITP2",
    category: "Royal Gaming",
    gameId: "900080",
    Cat: "LIVE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/itp1.webp",
    name: "Instant Teenpatti 1",
    gameCode: "RG-ITP",
    category: "Royal Gaming",
    gameId: "900081",
    Cat: "LIVE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/roulette_classic.webp",
    name: "Roulette Classic",
    gameCode: "RG-ROT-V2",
    category: "Royal Gaming",
    gameId: "900082",
    Cat: "TABLE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/diamond_roulette.webp",
    name: "Diamond Roulette",
    gameCode: "RG-ROT-V5",
    category: "Royal Gaming",
    gameId: "900085",
    Cat: "TABLE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/baccarat_classic.webp",
    name: "Baccarat Classic",
    gameCode: "RG-BAC-V2",
    category: "Royal Gaming",
    gameId: "900086",
    Cat: "TABLE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/xclusive_baccarat.webp",
    name: "Xclusive Baccarat",
    gameCode: "RG-BAC-V3",
    category: "Royal Gaming",
    gameId: "900087",
    Cat: "TABLE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/vip_tp.webp",
    name: "VIP TeenPatti",
    gameCode: "RG-TP-V3",
    category: "Royal Gaming",
    gameId: "900088",
    Cat: "TABLE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/daimond_tp.webp",
    name: "Diamond Teen Patti",
    gameCode: "RG-TP-V2",
    category: "Royal Gaming",
    gameId: "900089",
    Cat: "TABLE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/vip_ar.webp",
    name: "VIP Auto Roulette",
    gameCode: "RG-RTV2101",
    category: "Royal Gaming",
    gameId: "900090",
    Cat: "LIVE",
  },
  {
    img: "https://rg-assests.royalgaming.online/lobby-icon/kbc.webp",
    name: "Kaun Banega Crorepati",
    gameCode: "RG-KBC101",
    category: "Royal Gaming",
    gameId: "900092",
    Cat: "LIVE",
  },
  // {
  //   img: "https://cdn.dreamdelhi.com/rg/sicbo_exclusive.webp",
  //   name: "Sicbo Exclusive",
  //   gameCode: "RG-XSB101",
  //   category: "Royal Gaming",
  //   gameId: "903002",
  // },
  // {
  //   img: "https://cdn.dreamdelhi.com/rg/Andar_Bahar.webp",
  //   name: "Andar Bahar",
  //   gameCode: "RG-XAB101",
  //   category: "Royal Gaming",
  //   gameId: "903006",
  // },
];

export const BetGames = [
  {
    img: "https://cdn.dreamdelhi.com/betgames/lucky7.webp",
    name: "Lucky 7",
    gameCode: "bg_lucky7",
    category: "BetGames",
    gameId: "460001",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/lucky5.webp",
    name: "Lucky 5",
    gameCode: "bg_lucky5",
    category: "BetGames",
    gameId: "460002",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/bet_on_poker.webp",
    name: "Bet On Poker",
    gameCode: "bg_bet_on_poker",
    category: "BetGames",
    gameId: "460003",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/bet_on_baccarat.webp",
    name: "Bet On Baccarat",
    gameCode: "bg_bet_on_baccarat",
    category: "BetGames",
    gameId: "460004",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/wheel_of_fortune.webp",
    name: "Wheel Of Fortune",
    gameCode: "bg_wheel_of_fortune",
    category: "BetGames",
    gameId: "460005",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/war_of_bets.webp",
    name: "War Of Bets",
    gameCode: "bg_war_of_bets",
    category: "BetGames",
    gameId: "460006",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/lucky6.webp",
    name: "Lucky 6",
    gameCode: "bg_lucky6",
    category: "BetGames",
    gameId: "460007",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/dice_duel.webp",
    name: "Dice Duel",
    gameCode: "bg_dice_duel",
    category: "BetGames",
    gameId: "460008",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/speedy7.webp",
    name: "Speedy 7",
    gameCode: "bg_speedy7",
    category: "BetGames",
    gameId: "460009",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/6poker.webp",
    name: "6+ Poker",
    gameCode: "bg_6_poker",
    category: "BetGames",
    gameId: "460010",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/classic_wheel.webp",
    name: "Classic Wheel",
    gameCode: "bg_classic_wheel",
    category: "BetGames",
    gameId: "460011",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/football_grid.webp",
    name: "Football Grid",
    gameCode: "bg_football_grid",
    category: "BetGames",
    gameId: "460012",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/skyward.webp",
    name: "Skyward",
    gameCode: "bg_skyward",
    category: "BetGames",
    gameId: "460013",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/instant_lucky7.webp",
    name: "Instant Lucky 7",
    gameCode: "bg_instant_lucky7",
    category: "BetGames",
    gameId: "460014",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/classic_roulette.webp",
    name: "Classic Roulette",
    gameCode: "bg_classic_roulette",
    category: "BetGames",
    gameId: "460017",
  },
  {
    img: "https://cdn.dreamdelhi.com/betgames/flash_roulette.webp",
    name: "Flash Roulette",
    gameCode: "bg_flash_roulette",
    category: "BetGames",
    gameId: "460019",
  },
];

export const VivoNew = [
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/american_auto_roulette.webp",
    name: "American Auto Roulette",
    gameCode: "vvg_americanautoroulette",
    category: "VivoGaming",
    gameId: "226113",
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/american_roulette.webp",
    name: "American Roulette",
    gameCode: "vvg_americanroulette",
    category: "VivoGaming",
    gameId: "226114",
    Cat: "Live Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/lasvegas_black_jack.webp",
    name: "Las Vegas Blackjack",
    gameCode: "vvg_blackjack4",
    category: "VivoGaming",
    gameId: "226116",
    Cat: "Live Blackjack",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/macau_baccarat.webp",
    name: "Macau Baccarat",
    gameCode: "vvg_bulgariabaccarat1",
    category: "VivoGaming",
    gameId: "226117",
    Cat: "Live Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/imperial_baccarat.webp",
    name: "Imperial Baccarat",
    gameCode: "vvg_bulgariabaccarat2",
    category: "VivoGaming",
    gameId: "226118",
    Cat: "Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/oceania_vip_blackjack.webp",
    name: "Oceania VIP Blackjack",
    gameCode: "vvg_bulgariablackjack",
    category: "VivoGaming",
    gameId: "226120",
    Cat: "Blackjack",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/galactic_vip_roulette.webp",
    name: "Galactic VIP Roulette",
    gameCode: "vvg_bulgariaroulette",
    category: "VivoGaming",
    gameId: "226121",
    Cat: "Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/imperial_dragon_tiger.webp",
    name: "Imperial Dragon Tiger",
    gameCode: "vvg_dragontiger",
    category: "VivoGaming",
    gameId: "226127",
    Cat: "Dragon Tiger",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/infinite_casino_holdem.webp",
    name: "Infinite Casino Holdem",
    gameCode: "vvg_englishcasinoholdem",
    category: "VivoGaming",
    gameId: "226128",
    Cat: "Other Live Games",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/rapid_auto_roulette.webp",
    name: "Rapid-Auto Roulette",
    gameCode: "vvg_europeanautoroulette",
    category: "VivoGaming",
    gameId: "226129",
    Cat: "Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/french_roulette.webp",
    name: "French Roulette",
    gameCode: "vvg_frenchroulette",
    category: "VivoGaming",
    gameId: "226130",
    Cat: "Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/majestic_roulette.webp",
    name: "Majestic Roulette",
    gameCode: "vvg_galaxyroulette",
    category: "VivoGaming",
    gameId: "226137",
    Cat: "Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/oceania_twister_roulette.webp",
    name: "Oracle Twister Roulette",
    gameCode: "vvg_oracle360roulette",
    category: "VivoGaming",
    gameId: "226142",
    Cat: "Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/blaze_roulette.webp",
    name: "Blaze Roulette",
    gameCode: "vvg_oracleblazeroulette",
    category: "VivoGaming",
    gameId: "226143",
    Cat: "Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/portmaso_roulette.webp",
    name: "Portomaso Roulette",
    gameCode: "vvg_portomasoroulette",
    category: "VivoGaming",
    gameId: "226145",
    Cat: "Roulette",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/oriental_baccarat.webp",
    name: "Oriental Baccarat",
    gameCode: "vvg_tablegalaxy3",
    category: "VivoGaming",
    gameId: "226157",
    Cat: "Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/singapore_baccarat.webp",
    name: "Singapore Baccarat",
    gameCode: "vvg_tablegtm1",
    category: "VivoGaming",
    gameId: "226158",
    Cat: "Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/copa_cabana_baccarat.webp",
    name: "Copa Cabana Baccarat",
    gameCode: "vvg_tablegtm2",
    category: "VivoGaming",
    gameId: "226159",
    Cat: "Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/miami_beach_baccarat.webp",
    name: "Miami Beach Baccarat",
    gameCode: "vvg_tablegtm3",
    category: "VivoGaming",
    gameId: "226160",
    Cat: "Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/caribbean_baccarat.webp",
    name: "Caribbean Baccarat",
    gameCode: "vvg_tablegtm4",
    category: "VivoGaming",
    gameId: "226161",
    Cat: "Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/baccarat_dance.webp",
    name: "Baccarat Dance",
    gameCode: "vvg_tablegtm5",
    category: "VivoGaming",
    gameId: "226162",
    Cat: "Baccarat",
  },
  {
    img: "https://cdn.dreamdelhi.com/vivogaming/teenpatti.webp",
    name: "Teen Patti",
    gameCode: "vvg_teenpatti",
    category: "VivoGaming",
    gameId: "226166",
    Cat: "Other Live Games",
  },
];