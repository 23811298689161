import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import sport3 from "../assets/images/New/1.webp";
import sport4 from "../assets/images/New/2.webp";
import sport5 from "../assets/images/New/3.webp";
import sport6 from "../assets/images/New/4.webp";

const CasinoCard = () => {
  const { user,  setActive } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div className="casino-racing mb-md-4">
      <Row className="gx-md-2 gx-0">
        <Col xs={4} className="mb-2 p-1">
          <div
            onClick={() => {
              if (isEmpty(user)) {
                navigate("/login");
              }else {
                navigate('/sports')
                setActive('Cricket')
              }
            }}
            className="text-center racing-sec"
          >
            {/* <h2>Cricket</h2> */}
            <figure>
              <img src={sport3} alt="" />
            </figure>
          </div>
        </Col>
        <Col xs={4} className="mb-2 p-1">
          <div
          
            onClick={() => {
              if (isEmpty(user)) {
                navigate("/login");
              } else {
                navigate("/sports");
                setActive('Tennis')
              }
            }}
            className="text-center racing-sec"
          >
            {/* <h2>Soccer</h2> */}
            <figure>
              <img src={sport4}  alt="" />
            </figure>
          </div>
        </Col>
        <Col xs={4} className="mb-2 p-1">
          <div
            onClick={() => {
              if (isEmpty(user)) {
                navigate("/login");
              } else {
                navigate("/sports");
                setActive('Soccer')
              }
            }}
            className="text-center racing-sec"
          >
            {/* <h2>Tennis</h2> */}
            <figure>
              <img src={sport5} alt="" />
            </figure>
          </div>
        </Col>

        {/* <Col xs={3} className="mb-2 p-1">
          <div
            onClick={() => {
              if (isEmpty(user)) {
                navigate("/login");
              }
            }}
            className="text-center racing-sec"
          >
          
            <figure>
              <img src={sport6} alt="" />
            </figure>
          </div>
        </Col> */}
      </Row>
    </div>
  );
};

export default CasinoCard;
