import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DepositWithrawData,
  DepositWithrawDataArray,
  PokerGames2,
} from "../Utils/constants";
import AuthContext from "../context/AuthContext";
import { isEmpty, toLower } from "lodash";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
const GameSlider = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const {
    lang,
    user,
    launchEGTCasino,
    launchCasino,
    logoutUser,
    withoutLogin,
    setShowDownloadBar,
    downloadBar,
    setEvolutionModal,
    dolaunchCasinoNew,dolaunchCasinoRyoal
  } = useContext(AuthContext);
  const [key, setKey] = useState("casino");
  const [slideClass, setSlideClass] = useState("slide-left");

  useEffect(() => {
    if (key == "poker") {
      setData(PokerGames2);
    } else {
      setData(
        DepositWithrawDataArray?.find((res) => {
          {
            return res?.key == "casino";
          }
        })
      );
    }
  }, []);

  const setFunc = (type) => {
    setSlideClass("");
    if (type == "poker") {
      setData(PokerGames2);
    } else {
      setData(
        DepositWithrawDataArray?.find((res) => {
          {
            return res?.key == type;
          }
        })
      );
    }

    setKey(type);
    setTimeout(() => {
      setSlideClass("slide-left");
    }, 10);
  };

  return (
    <div className="home-tabing-slider">
      <div className={"payment-sec "}>
        {DepositWithrawData?.map((item) => {
          return (
            <div
              onClick={() => {
                if (!isEmpty(item?.link)) {
                  navigate(item?.link);
                } else {
                  setFunc(item?.type);
                }
              }}
              className={`payment-box ${key == item?.type ? "active" : ""}`}
            >
              <figure>
                <img src={item?.icons} alt="" />
              </figure>
              <span>{lang == "bn" ? item?.titlebn : item?.title}</span>
            </div>
          );
        })}
      </div>
      {slideClass == "slide-left" && (
        <div className="main-slider-div">
          <div className="main-slider-inner-div ">
            <div>
              <h5>
                {key == "poker"
                  ? "Indain Poker"
                  : lang == "bn"
                  ? data?.titlebn
                  : data?.title}
              </h5>
            </div>

            <ul className={slideClass}>
            {(key == "poker" ? data : data?.array)?.map((res) => {
                return (
                  <li>
                    <div
                      className={`d-flex align-items-center flex-column ${
                        ["poker"].includes(key) && "game-slider-home"
                      }`}
                      onClick={() => {
                        if (!isEmpty(user)) {
                          if (
                            res?.platForm == "EVOLUTION" ||
                            res?.platForm == "JOKER" ||
                            res?.platForm == "BG" ||
                            res?.platForm == "SV388" ||
                            res?.platForm == "PT"
                          ) {
                            setEvolutionModal({
                              status: true,
                              data: res,
                            });
                          } else if (key == "poker") {
                            dolaunchCasinoNew({
                              gameCode: res?.gameCode,
                              legacy: res?.legacy,
                              type: "first",
                            });
                          } else {
                            if (key == "casino") {
                              if (res?.legacy == "aura") {
                                dolaunchCasinoNew({
                                  gameCode: res?.gameCode,
                                  legacy: res?.legacy,
                                  type: "first",
                                });
                              } else if (!isEmpty(res?.url_thumb)) {
                                dolaunchCasinoRyoal(res);
                              }
                            } else {
                              launchCasino({
                                platForm: res?.platForm,
                                gameType: res?.gameType,
                                casinoType: res?.casinoType,
                                isLobbyTrue: res?.isLobbyTrue,
                              });
                            }
                          }
                        } else {
                          navigate("/login");
                        }
                      }}
                      // className={`d-flex align-items-center flex-column ${
                      //   ["poker", "royal"].includes(key) && "game-slider-home"
                      // }`}
                    >
                      {(res?.image || res?.img || res?.url_thumb) && (
                        <LazyLoadImage
                          effect="opacity"
                          alt={res?.img}
                          src={res?.image || res?.img || res?.url_thumb}
                        />
                      )}
                      {key !== "poker" && <p>{res?.title || res?.game_name}</p>}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameSlider;
