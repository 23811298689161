import React, { createContext, useState, useEffect, useContext } from "react";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "./AuthContext";
import { isEmpty } from "lodash";
import moment from "moment";
import { io } from "socket.io-client";
const BetSlipContext = createContext();

export default BetSlipContext;

export const BetSlipProvider = ({ children }) => {
  const [betLoader, setBetLoader] = useState(false);
  const { user, amounutRefresh } = useContext(AuthContext);
  const [limit, setLimit] = useState({});
  const [showBetDialog, setShowBetDialog] = useState(false);
  const [showBet, setShowBet] = useState(false);
  const [resetBetPosition, setResetBetPosition] = useState(false);
  const [socketObj, setSocketObj] = useState({});
  const [message, setMessage] = useState({
    status: false,
    message: "",
    errorType: "",
  });
  const [betPlace, setBetPlace] = useState({
    isPlaced: false,
    message: "",
    betType: "",
  });
  const [betSlipObject, setBetSlipObject] = useState({
    odds: "",
    bidPrice: "",
    teamName: "",
    betType: "",
  });

  useEffect(() => {
    if (message?.status) {
      setTimeout(() => {
        setMessage({
          status: false,
        });
      }, 4000);
    }
  }, [message]);

  const handelError = (object, type) => {
    setShowBetDialog(false);
    setBetSlipObject({});
    setMessage({
      ...message,
      status: true,
      message: object?.message,
      errorType: "Error",
    });
    setBetPlace({
      isPlaced: true,
      betType: type,
      type: "error",
    });
  };

  const resetBetSlipObject = () => {
    setBetSlipObject({
      odds: "",
      bidPrice: "",
      teamName: "",
      betType: "",
    });
  };
  const resetBetPlace = () => {
    setBetPlace({
      isPlaced: false,
      message: "",
      betType: "",
    });
  };

  const beforeBetPlace = async (obj) => {
    setBetLoader(true);
    document.getElementById("root").style.pointerEvents = "none";
    const { data: response_users } = await apiPost(apiPath.beforeBetPlace, obj);
    return response_users;
  };

  const resetLayout = () => {
    setBetLoader(false);
    document.getElementById("root").style.pointerEvents = "auto";
  };

  const socketBetPlace = async (socketObj, url, result, betPlaceType) => {
    if (!isEmpty(socketObj)) {
      socketObj.disconnect();
    }
    let newSocket = "";
    if (!isEmpty(user)) {
      newSocket = io(url, {
        transports: ["websocket"],
        forceNew: true,
      });
    } else {
      newSocket = io(url, {
        transports: ["websocket"],
        forceNew: true,
      });
    }
    setSocketObj(newSocket);
    newSocket.emit(betPlaceType, result);
    newSocket.on("listenBookmakerPlace", (e) => console.log(e));
    // return true;
  };

  const handelPlaceBetFair = async () => {
    if (!isEmpty(user) && betSlipObject?.bidPrice > 1) {
      const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
      let result = {
        eventId: betSlipObject?.object?.eventId,
        marketId: betSlipObject?.object?.marketId,
        betPlaceTime: date,
        betPlaceType: "betfair",
        bet: [
          {
            selectionId:
              betSlipObject?.object?.SelectionId || betSlipObject?.object?.ri,
            teamName:
              betSlipObject?.teamName || betSlipObject?.object?.runnerName,
            bhav: betSlipObject?.odds,
            amount: betSlipObject?.bidPrice,
            betType: betSlipObject?.betType,
          },
        ],
      };
      // let isValid = await beforeBetPlace(result);
      // if (isValid?.success) {
      setBetLoader(true);
      document.getElementById("root").style.pointerEvents = "none";
      setTimeout(async () => {
        const { status, data: response_users } = await apiPost(
          apiPath.betfairPlaceBet,
          result
        );
        if (status === 200) {
          if (response_users?.results?.matchedFlag) {
            setShowBetDialog(false);
            setResetBetPosition(true);
            resetLayout();
            setBetPlace({
              isPlaced: true,
              message: response_users?.message,
              betType: betSlipObject?.newType == "multi" ? "multi" : "betFair",
              type: "matched",
            });
            setMessage({
              ...message,
              ...betSlipObject,
              odds:
                response_users?.results?.finalAppRate || betSlipObject?.odds,
              newOdds: response_users?.results?.finalAppRate,
              status: true,
              errorType: "Matched",
            });
            amounutRefresh();
          } else {
            resetLayout();
            handelError(
              response_users,
              betSlipObject?.newType == "multi" ? "multi" : "betFair"
            );
          }
        } else {
          resetLayout();
          handelError(
            response_users,
            betSlipObject?.newType == "multi" ? "multi" : "betFair"
          );
        }
      }, 2000);
      // } else {
      //   resetLayout();
      //   handelError(isValid, "betFair");
      // }
    }
  };

  const handelPlaceTie = async () => {
    if (!isEmpty(user) && betSlipObject?.bidPrice > 1) {
      const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
      let result = {
        eventId: betSlipObject?.object?.eventId,
        marketId: betSlipObject?.object?.marketId,
        betPlaceTime: date,
        betPlaceType: "tie",
        bet: [
          {
            selectionId: betSlipObject?.object?.SelectionId,
            teamName: betSlipObject?.teamName,
            bhav: betSlipObject?.odds,
            amount: betSlipObject?.bidPrice,
            betType: betSlipObject?.betType,
          },
        ],
      };
      // let isValid = await beforeBetPlace(result);
      // if (isValid?.success) {
      setBetLoader(true);
      document.getElementById("root").style.pointerEvents = "none";
      setTimeout(async () => {
        const { status, data: response_users } = await apiPost(
          apiPath.tieBetPlace,
          result
        );
        if (status === 200) {
          if (response_users?.results?.matchedFlag) {
            setShowBetDialog(false);
            setResetBetPosition(true);
            resetLayout();
            setBetPlace({
              isPlaced: true,
              message: response_users?.message,
              betType: "tie",
              type: "matched",
            });
            setMessage({
              ...message,
              ...betSlipObject,
              status: true,
              errorType: "Matched",
            });
            amounutRefresh();
          } else {
            resetLayout();
            handelError(response_users, "tie");
          }
        } else {
          resetLayout();
          handelError(response_users, "tie");
        }
      }, 2000);
      // } else {
      //   resetLayout();
      //   handelError(isValid, "betFair");
      // }
    }
  };
  const handelPlaceFancy = async () => {
    if (!isEmpty(user) && betSlipObject?.bidPrice > 1) {
      // const date = moment.utc(new Date()).format("YYYY-MM-DD, h:mm:ss");
      // const updatedDate = moment(betSlipObject?.object?.ts).format(
      //   "YYYY-MM-DD, h:mm:ss"
      // );
      // let difference = moment(date).diff(updatedDate, "seconds");
      // if (difference > 5) {
      //   resetLayout();
      //   handelError({ message: "UnMatched" }, "fancy");
      // } else {
      setBetLoader(true);
      document.getElementById("root").style.pointerEvents = "none";
      const result = {
        eventId: betSlipObject?.object?.eventId,
        marketId: betSlipObject?.object?.marketId,
        centralizedId: betSlipObject?.object?.centralizedId,
        selectionId: betSlipObject?.object?.selectionId,
        betPlaceTime: moment(new Date()).format("YYYY-MM-DD, h:mm:ss"),
        fancyName: betSlipObject?.object?.teamName,
        bhav: betSlipObject?.object?.pt,
        amount: betSlipObject?.bidPrice,
        betType: betSlipObject?.betType,
        betRun: betSlipObject?.object?.rt,
        runnerName: betSlipObject?.object?.teamName,
        betPlaceType: "fancy",
      };
      // let socketResult = socketBetPlace(
      //   socketObj,
      //   betSlipObject?.socketUrl,
      //   result,
      //   "fancyBetPlace"
      // );
      // if (socketResult) {
      //   setShowBetDialog(false);
      //   setResetBetPosition(true);
      //   resetLayout();
      //   setBetPlace({
      //     isPlaced: true,
      //     // message: response_users?.message,
      //     betType: "fancy",
      //     type: "matched",
      //   });
      //   setMessage({
      //     ...message,
      //     ...betSlipObject,
      //     status: true,
      //     errorType: "Matched",
      //   });
      //   amounutRefresh();
      // } else {
      //   resetLayout();
      //   // handelError(response_users, "fancy");
      // }
      const { status, data: response_users } = await apiPost(
        apiPath.fancyBetPlace,
        result
      );
      if (status === 200) {
        if (response_users?.success) {
          setShowBetDialog(false);
          setResetBetPosition(true);
          resetLayout();
          setBetPlace({
            isPlaced: true,
            message: response_users?.message,
            betType: "fancy",
            type: "matched",
          });
          setMessage({
            ...message,
            ...betSlipObject,
            status: true,
            errorType: "Matched",
          });
          amounutRefresh();
        } else {
          resetLayout();
          handelError(response_users, "fancy");
        }
      } else {
        resetLayout();
        handelError(response_users, "fancy");
      }
    }
    // }
  };

  const handelPlaceSportBook = async () => {
    if (!isEmpty(user) && betSlipObject?.bidPrice > 1) {
      const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
      const result = {
        eventId: betSlipObject?.eventId,
        marketId: betSlipObject?.marketId,
        fancySelectionId: betSlipObject?.object?.id,
        selectionId: betSlipObject?.item?.id,
        betPlaceTime: date,
        fancyName: betSlipObject?.item?.marketName,
        bhav: betSlipObject?.odds,
        amount: betSlipObject?.bidPrice,
        betType: "back",
        runnerName: betSlipObject?.object?.selectionName,
        apiSiteSelectionId: betSlipObject?.object?.apiSiteSelectionId,
        betPlaceType: "premiumFancy",
      };
      // let isValid = await beforeBetPlace(result);
      // if (isValid?.success) {
      setBetLoader(true);
      document.getElementById("root").style.pointerEvents = "none";
      setTimeout(async () => {
        let api = apiPath.preminumFancyBets;
        const { status, data: response_users } = await apiPost(api, result);
        if (status === 200) {
          if (response_users?.success) {
            setShowBetDialog(false);
            setResetBetPosition(true);
            resetLayout();
            setBetPlace({
              isPlaced: true,
              message: response_users?.message,
              betType: "sportBook",
              type: "matched",
            });
            setMessage({
              ...message,
              ...betSlipObject,
              status: true,
              betType: "back",
              errorType: "Matched",
              teamName: betSlipObject?.item?.marketName,
            });
            amounutRefresh();
          } else {
            resetLayout();
            handelError(response_users, "sportBook");
          }
        } else {
          resetLayout();
          handelError(response_users, "sportBook");
        }
      }, 2000);
      // } else {
      //   resetLayout();
      //   handelError(isValid, "sportBook");
      // }
    }
  };

  const handelPlaceLineMarket = async () => {
    if (!isEmpty(user) && betSlipObject?.bidPrice > 1) {
      const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
      const result = {
        eventId: betSlipObject?.eventId,
        marketId: betSlipObject?.marketId,
        betPlaceTime: date,
        fancyName: betSlipObject?.object?.name,
        bhav: betSlipObject?.odds,
        amount: betSlipObject?.bidPrice,
        betType: "back",
        runnerName: betSlipObject?.teamName,
        betPlaceType: "line",
      };
      setBetLoader(true);
      document.getElementById("root").style.pointerEvents = "none";
      let api = apiPath.linemarketBets;
      const { status, data: response_users } = await apiPost(api, result);
      if (status === 200) {
        if (response_users?.success) {
          setShowBetDialog(false);
          setResetBetPosition(true);
          resetLayout();
          setBetPlace({
            isPlaced: true,
            message: response_users?.message,
            betType: "line",
            type: "matched",
          });
          setMessage({
            ...message,
            ...betSlipObject,
            status: true,
            betType: "back",
            errorType: "Matched",
            teamName: betSlipObject?.object?.name,
          });
          amounutRefresh();
        } else {
          resetLayout();
          handelError(response_users, "line");
        }
      } else {
        resetLayout();
        handelError(response_users, "line");
      }
      // } else {
      //   resetLayout();
      //   handelError(isValid, "sportBook");
      // }
    }
  };
  const handelPlaceBookmaker = async () => {
    if (!isEmpty(user) && betSlipObject?.bidPrice > 1) {
      const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
      const updatedDate = moment(betSlipObject?.ts).format(
        "YYYY-MM-DD, h:mm:ss"
      );
      // let difference = moment(date).diff(updatedDate, "seconds");
      // if (difference > 5) {
      //   resetLayout();
      //   handelError({ message: "UnMatched" }, "bookmaker");
      // } else {
      setBetLoader(true);
      document.getElementById("root").style.pointerEvents = "none";
      let result = {
        eventId: betSlipObject?.object?.eventId,
        marketId: betSlipObject?.object?.marketId,
        selectionId:
          betSlipObject?.object?.SelectionId ||
          betSlipObject?.object?.selectionID,
        betPlaceTime: date,
        teamName: betSlipObject?.teamName,
        bhav: betSlipObject?.odds,
        amount: betSlipObject?.bidPrice,
        betType: betSlipObject?.betType,
        betPlaceType: "bookmaker",
        ts: updatedDate,
      };
      // let socketResult = socketBetPlace(
      //   socketObj,
      //   betSlipObject?.socketUrl,
      //   result,
      //   "bookmakerBetPlace"
      // );
      // if (socketResult) {
      //   setShowBetDialog(false);
      //   setResetBetPosition(true);
      //   resetLayout();
      //   setBetPlace({
      //     isPlaced: true,
      //     // message: response_users?.message,
      //     betType: "bookmaker",
      //     type: "matched",
      //   });
      //   setMessage({
      //     ...message,
      //     ...betSlipObject,
      //     status: true,
      //     errorType: "Matched",
      //   });
      //   amounutRefresh();
      // } else {
      //   resetLayout();
      //   // handelError(response_users, "bookmaker");
      // }
      setBetLoader(true);
      document.getElementById("root").style.pointerEvents = "none";
      const { status, data: response_users } = await apiPost(
        apiPath.bookmakerBet,
        result
      );
      if (status === 200) {
        if (response_users?.success) {
          setShowBetDialog(false);
          setResetBetPosition(true);
          resetLayout();
          setBetPlace({
            isPlaced: true,
            message: response_users?.message,
            betType: "bookmaker",
            type: "matched",
          });
          setMessage({
            ...message,
            ...betSlipObject,
            status: true,
            errorType: "Matched",
          });
          amounutRefresh();
        } else {
          resetLayout();
          handelError(response_users, "bookmaker");
        }
      } else {
        resetLayout();
        handelError(response_users, "bookmaker");
      }
    }
    // }
  };

  const handelPlaceBookmakerWifi = async () => {
    if (!isEmpty(user) && betSlipObject?.bidPrice > 1) {
      const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
      const updatedDate = moment(betSlipObject?.ts).format(
        "YYYY-MM-DD, h:mm:ss"
      );
      setBetLoader(true);
      document.getElementById("root").style.pointerEvents = "none";
      let result = {
        eventId: betSlipObject?.object?.eventId,
        marketId: betSlipObject?.object?.marketId,
        selectionId:
          betSlipObject?.object?.SelectionId ||
          betSlipObject?.object?.selectionID,
        betPlaceTime: date,
        teamName: betSlipObject?.object?.runnerName,
        bhav: betSlipObject?.odds,
        amount: betSlipObject?.bidPrice,
        betType: betSlipObject?.betType,
        betPlaceType: "wifiBookmaker",
        ts: updatedDate,
      };
    
      setBetLoader(true);
      document.getElementById("root").style.pointerEvents = "none";
      const { status, data: response_users } = await apiPost(
        apiPath.bookmakerBet,
        result
      );
      if (status === 200) {
        if (response_users?.success) {
          setShowBetDialog(false);
          setResetBetPosition(true);
          resetLayout();
          setBetPlace({
            isPlaced: true,
            message: response_users?.message,
            betType: "bookmaker",
            type: "matched",
          });
          setMessage({
            ...message,
            ...betSlipObject,
            status: true,
            errorType: "Matched",
          });
          amounutRefresh();
        } else {
          resetLayout();
          handelError(response_users, "bookmaker");
        }
      } else {
        resetLayout();
        handelError(response_users, "bookmaker");
      }
    }
  };
  const handelPlaceToss = async () => {
    if (!isEmpty(user) && betSlipObject?.bidPrice > 1) {
      const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
      console.log(betSlipObject, "betSlipObject");
      const result = {
        eventId: betSlipObject?.object?.eventId,
        marketId: betSlipObject?.object?.marketId,
        selectionId: betSlipObject?.object?.SelectionId,
        betPlaceTime: date,
        bhav: betSlipObject?.odds,
        amount: betSlipObject?.bidPrice,
        teamName: betSlipObject?.teamName,
      };
      setBetLoader(true);
      document.getElementById("root").style.pointerEvents = "none";
      setTimeout(async () => {
        const { status, data: response_users } = await apiPost(
          apiPath.tossBetPlace,
          result
        );
        if (status === 200) {
          if (response_users?.success) {
            setShowBetDialog(false);
            setResetBetPosition(true);
            resetLayout();
            setBetPlace({
              isPlaced: true,
              message: response_users?.message,
              betType: "toss",
              type: "matched",
            });
            setMessage({
              ...message,
              ...betSlipObject,
              status: true,
              errorType: "Matched",
            });
            amounutRefresh();
          } else {
            resetLayout();
            handelError(response_users, "toss");
          }
        } else {
          resetLayout();
          handelError(response_users, "toss");
        }
      }, 2000);
    }
  };
  const dolaunchCasinoNew = async (request) => {
    if (request?.gameCode) {
      const { status, data: response_users1 } = await apiPost(
        request?.type == "first"
          ? apiPath.doLoginAndLaunchIQCasino
          : apiPath.doLoginAndLaunchEasyToCasinoNormal,
        {
          gameid: request?.gameCode,
          legacy: request?.legacy || "",
        }
      );
      if (status === 200) {
        if (response_users1.status) {
          window.location.href = response_users1.data;
        } else {
          setMessage({
            message:
              response_users1?.message == "success"
                ? "Casino is in maintenance mode if you have any query please contact upline"
                : response_users1?.message ||
                  "Casino is in maintenance mode if you have any query please contact upline",
            status: true,
            errorType: "P2P",
          });
        }
      }
    }
  };


  
  
  const launchCasino = async (request) => {
    if (request?.platForm || request?.gameType || request?.casinoType) {
      const { status, data: response_users1 } = await apiPost(
        apiPath.casinoAmountAdd,
        { amount: 0, platForm: request?.platForm }
      );
      if (status === 200) {
        if (response_users1.success) {
          const { status, data: response_users } = await apiPost(
            request?.platForm && request?.gameType && request?.casinoType
              ? apiPath.doLoginAndLaunchGame
              : apiPath.casinoWalletLogin,
            request?.platForm && request?.gameType && request?.casinoType
              ? request?.table
                ? {
                    userId: response_users1?.results?.aeCasinoUserId,
                    platForm: request?.platForm == "1" ? "" : request?.platForm,
                    gameType: request?.gameType,
                    gameCode: request?.casinoType,
                    gameTableId: request?.table,
                    isLaunchGameTable: true,
                  }
                : {
                    userId: response_users1?.results?.aeCasinoUserId,
                    platForm: request?.platForm == "1" ? "" : request?.platForm,
                    gameType: request?.gameType,
                    gameCode: request?.casinoType,
                  }
              : {
                  userId: response_users1?.results?.aeCasinoUserId,
                  gameType: request?.gameType,
                  platForm: request?.platForm == "1" ? "" : request?.platForm,
                }
          );
          if (status === 200) {
            if (response_users.status) {
              if (response_users.data.status === "0000") {
                window.location.href = response_users.data.url;
              } else {
                setMessage({
                  message: response_users?.data?.desc,
                  status: true,
                  errorType: "P2P",
                });
              }
            }
          } else {
            setMessage({
              message: response_users?.message,
              status: true,
              errorType: "P2P",
            });
          }
        } else {
          setMessage({
            message: response_users1?.message,
            status: true,
            errorType: "P2P",
          });
        }
      } else {
        setMessage({
          message: response_users1?.message,
          status: true,
          errorType: "P2P",
        });
      }
    }
    document.getElementById("loading").style.display = "none";
  };

  const launchEGTCasino = async (request) => {
    if (request?.platForm || request?.gameType || request?.casinoType) {
      const { status, data: response_users1 } = await apiPost(
        apiPath.easytogoCasinoAmountAdd,
        {
          prod: request?.platForm,
          type: request?.gameType,
        }
      );
      if (status === 200) {
        if (response_users1.success) {
          const { status, data: response_users } = await apiPost(
            apiPath.doLoginAndLaunchEasyToCasino,
            {
              prod: request?.platForm,
              type: request?.gameType,
              gameCode: request?.casinoType,
              gameid: request?.gameid || false,
            }
          );
          if (status === 200 && response_users.status) {
            if (response_users.data.err === 1) {
              window.location.href = response_users.data.url;
            } else {
              setMessage({
                message: response_users?.data?.desc,
                status: true,
                errorType: "P2P",
              });
            }
          } else {
            setMessage({
              message: response_users?.message,
              status: true,
              errorType: "P2P",
            });
          }
        } else {
          setMessage({
            message: response_users1?.message,
            status: true,
            errorType: "P2P",
          });
        }
      }
    }
    document.getElementById("loading").style.display = "none";
  };
  let contextData = {
    betLoader,
    showBetDialog,
    setShowBetDialog,
    setBetSlipObject,
    betSlipObject,
    showBet,
    setShowBet,
    resetBetSlipObject,
    resetBetPosition,
    setResetBetPosition,
    handelPlaceBetFair,
    betPlace,
    resetBetPlace,
    handelPlaceFancy,
    handelPlaceSportBook,
    handelPlaceBookmaker,
    setLimit,
    limit,
    setMessage,
    message,
    handelPlaceTie,
    handelPlaceToss,
    dolaunchCasinoNew,
    resetLayout,
    handelError,
    setBetPlace,
    setBetLoader,
    handelPlaceLineMarket,
    launchEGTCasino,
    launchCasino,handelPlaceBookmakerWifi
  };
  return (
    <BetSlipContext.Provider value={contextData}>
      {children}
    </BetSlipContext.Provider>
  );
};
