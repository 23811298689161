import React, { useState, useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { FaArrowsAltV } from "react-icons/fa";
import { isEmpty, startCase } from "lodash";
import { priceFormat } from "../Utils/constants";
import BackLay from "./BackLay";
import { useNavigate } from "react-router-dom";
import useScroll from "./Scroll";
import { AiOutlineStar } from "react-icons/ai";
import { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import BackLayBookmaker from "./BackLayBookmaker";
const BookmakerWifi = ({
  backOdds,
  layOdds,
  bookmaker,
  user,
  profileData,
  detail,
  setBetSlipObject,
  setShowBetDialog,
  betSlipObject,
  setLimit,
  displayType,
  showBetDialog,
  scrollHeight,
  socketUrl,
  bookmakerSuspended,
  ms,
}) => {
  const { setMessage } = useContext(BetSlipContext);
  const naviagte = useNavigate();
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [localLimit, setLocalLimit] = useState({
    maxAmount: 10000000,
    minAmount: 1000,
    oddsLimit: 100,
  });

  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setScrollId(index);
        setShowBetDialog(true);
        setLimit(localLimit);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: team,
          betType: type,
          socketUrl: socketUrl,
          playType: "wifiBookmaker",
          object: {
            ...item,
            ...odds,
            teamName: team,
            eventId: detail?.eventId,
            marketId: detail?.wifiBookmakerMarketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };
  return (
    <div className="bookmaker p-2">
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div
              className="d-flex align-items-center"
              style={{ paddingLeft: "10px" }}
            >
              <AiOutlineStar size={20} style={{ margin: "0px 4px" }} />
              {startCase(displayType)}
            </div>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <div className="match-odd-table bg-white p-1 py-1 pe-0">
              <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
                <span className="text-center w-100">Back</span>

                <span className="text-center w-100">Lay</span>
              </div>
              <ul className="mb-2 rounded position-relative p-0">
                {bookmaker?.map((item, index) => {
                  let odds_back = backOdds
                    ?.filter(
                      (todd) =>
                        todd?.runnerName?.toLowerCase() ==
                        (item?.RunnerName?.toLowerCase() ||
                          item?.runnerName?.toLowerCase())
                    )
                    ?.sort((a, b) => {
                      return a - b;
                    });
                  const odds_lay = layOdds
                    ?.filter(
                      (todd) =>
                        todd?.runnerName?.toLowerCase() ==
                        (item?.RunnerName?.toLowerCase() ||
                          item?.runnerName?.toLowerCase())
                    )
                    ?.sort((a, b) => {
                      return a - b;
                    });

                  const odds_lay2 =
                    layOdds?.find(
                      (todd) =>
                        todd?.runnerName?.toLowerCase() ==
                        (item?.RunnerName?.toLowerCase() ||
                          item?.runnerName?.toLowerCase())
                    ) || {};
                  let odds_back2 =
                    backOdds?.find(
                      (todd) =>
                        todd?.runnerName?.toLowerCase() ==
                        (item?.RunnerName?.toLowerCase() ||
                          item?.runnerName?.toLowerCase())
                    ) || {};
                  let layRt = odds_lay2?.rt > 0 ? odds_lay2?.rt : 0;
                  let backRt = odds_back2?.rt > 0 ? odds_back2?.rt : 0;
                  let disable = false;
                  if (odds_lay?.length == 0 && odds_back?.length == 0) {
                    disable = true;
                  } else if (layRt <= 0 && backRt <= 0) {
                    disable = true;
                  } else if (
                    (layRt > localLimit?.oddsLimit || layRt == 0) &&
                    (backRt > localLimit?.oddsLimit || backRt == 0)
                  ) {
                    disable = true;
                  } else {
                    disable = false;
                  }

                  return (
                    <li
                      ref={index == scrollId ? myRef : null}
                      style={{ scrollMarginTop: scrollHeight }}
                    >
                      <h2>
                        {item?.RunnerName || item?.runnerName}{" "}
                        {(Math?.abs(item?.position) >= 0 ||
                          Math.abs(item?.newPosition) > 0) && (
                          <span
                            className={`d-block py-1 ${
                              item?.position > 0
                                ? "text-green team-red-score"
                                : "team-red-score"
                            }`}
                          >
                            {Math?.abs(item?.position) >= 0
                              ? Math?.abs(item?.position).toFixed(2)
                              : ""}
                            {Math.abs(item?.newPosition) > 0 && (
                              <span
                                className={
                                  item?.newPosition > 0 ? "text-green" : ""
                                }
                              >
                                {" "}
                                {">"} {Math.abs(item?.newPosition).toFixed(2)}
                              </span>
                            )}
                          </span>
                        )}
                      </h2>

                      <BackLayBookmaker
                        component={"wifiBookmaker"}
                        index={index}
                        item={item}
                        handelChange={handelChange}
                        conditionNew={disable && ms == 1}
                        odds_lay={odds_lay}
                        ms={ms}
                        odds_back={odds_back}
                        betPlaceAccept={true}
                        limit={localLimit}
                      />

                      {disable &&
                        ms == 1 &&
                        (backOdds?.length !== 0 || layOdds?.length !== 0) && (
                          <div className="overlay-match">Suspended</div>
                        )}
                    </li>
                  );
                })}
                {ms != 1 ||
                  (backOdds?.length == 0 && layOdds?.length == 0 && (
                    <div className="overlay-match">Suspended</div>
                  ))}
              </ul>
              <div className="market-depth-sec d-flex justify-content-end">
                <div className="d-flex align-items-center min-max">
                  <FaArrowsAltV />
                  <span>min/max</span>
                  <strong>
                    {localLimit?.minAmount || 1}/
                    {priceFormat(localLimit?.maxAmount) || 100}
                  </strong>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default BookmakerWifi;
