import React, { useContext, useEffect, useState } from "react";
import LeftPanel from "../LeftPanel";
import Header from "../Header";
import RightPanel from "../RightPanel";
import Footer from "../Footer";
import { Outlet, useLocation } from "react-router-dom";
import BottomNavigation from "../BottomNavigation";
import {
  bottomNavigation,
  showMenuUsingPath,
  sidebarData,
} from "../../constraints/constants";
import BetSlipContext from "../../context/BetSlipContext";
import PlaysBet from "../PlaysBet";
import AuthContext from "../../context/AuthContext";
import { isEmpty } from "lodash";
import BookPosition from "../BookPosition";
import Message from "../Message";
import Calendar from "../Calendar";
import Loader from "../Loader";
import CurrentNews from "../CurrentNews";
import Announcement from "../Announcement";
import MessageLottery from "../MessageLottery";
import EvolutionModal from "../EvolutionModal";
const LayoutComponent = (props) => {
  const location = useLocation();
  const { showBetDialog, message } = useContext(BetSlipContext);
  const {
    user,
    showPosition,
    showDate,
    setSearchTab,
    searchTab,
    messagelist,
    announcement,
    setAnnouncement,
    messageLotter,
    evolutionModal,
    setEvolutionModal,
  } = useContext(AuthContext);
  const { children, visibilityType, newType } = props;
  const [activePage, setActivePage] = useState(false);
  const [leaguesPage, setLeaguesPage] = useState(false);
  const [visibleBottomNavigation, setVisibleBottomNavigation] = useState(false);
  const [noShowMenu, setNoShowMenu] = useState();

  useEffect(() => {
    setActivePage(sidebarData.some((el) => el.link === location?.pathname));
  }, []);

  useEffect(() => {
    setVisibleBottomNavigation(
      bottomNavigation.some((el) => el.link === location?.pathname)
    );
  }, []);

  useEffect(() => {
    if (location.pathname === "/leagues") {
      setLeaguesPage(true);
    }
  }, []);

  useEffect(() => {
    setNoShowMenu(
      showMenuUsingPath.some((el) => el.path === location?.pathname)
    );
  }, []);

  // console.log(document.getElementsByClassName('bottom-navigation')[0].clientHeight)
  return (
    <div className="main-outer position-relative">
      {/* <LeftPanel /> */}
      <div
        className={
          visibilityType ? "inner-container" : "inner-container login-panel"
        }
        style={{ background: newType ? "#eceaea" : "" }}
      >
        {/* className={visibleBottomNavigation ? "showbottomnavigation" : !isEmpty(user) ? 'with-login' : ""} */}
        <main id="main">
          <Outlet />
          {visibilityType && <Header />}
          {/* {(location?.pathname == "/sports") && (
              <CurrentNews
                message={messagelist}
                setAnnouncement={setAnnouncement}
              />
            )} */}
          {children}
          {visibilityType &&
            !activePage &&
            !leaguesPage &&
            location?.pathname !== "/customer-support" && <Footer />}
          {!sidebarData && location?.pathname !== "/customer-support" && (
            <Footer />
          )}
          {evolutionModal?.status && (
            <EvolutionModal
              show={evolutionModal?.status}
              handelClose={() => setEvolutionModal({ data: {}, status: false })}
            />
          )}
          {/* {visibleBottomNavigation && homeLocation ? "" : <BottomNavigation />} */}
          {/* {visibleBottomNavigation  &&
          location.pathname == "/" &&
          sidebarData ? (
            ""
          ) : ( */}
          {location.pathname == "/login" ||
          location?.pathname?.split("/")[1] == "full-market"
            ? ""
            : location.pathname === "/change-password" ||
              location?.pathname === "/customer-support"
            ? ""
            : !isEmpty(user) && <BottomNavigation />}
          {showDate?.status && !isEmpty(showDate?.type) && <Calendar />}
          {showPosition?.status && !isEmpty(showPosition?.object) && (
            <BookPosition />
          )}
          {messageLotter?.status && <MessageLottery />}
          {announcement && <Announcement data={messagelist} />}
          {message?.status && <Message />}
          {/* // )} */}
          <Loader />
        </main>
        {showBetDialog && <PlaysBet />}
      </div>
      {/* <RightPanel /> */}
    </div>
  );
};

export default LayoutComponent;
