import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { RxCrossCircled } from "react-icons/rx";
import { Button } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
const EvolutionModal = ({ show, handelClose }) => {
  const { launchCasino, evolutionModal } = useContext(AuthContext);
  return (
    <Modal centered show={show} onHide={handelClose} className="block-modal">
      <Modal.Body className="py-3 position-relative">
        <RxCrossCircled
        onClick={handelClose}
          color="black"
          style={{ position: "absolute", right: "2%", top: "2%" }}
          size={20}
        />
        <div
          className="d-flex align-items-center pt-2 flex-column"
          style={{ fontWeight: "600" }}
        >
          <p className="p-0 m-0 mb-1" style={{ fontSize: "17px" }}>
            Welcome To Our
          </p>
          <h2 style={{ fontSize: "25px", fontWeight: "700" }} className="mb-0">
            CASINO
          </h2>
          <h4 style={{ fontSize: "25px", fontWeight: "700" }}>LOBBY</h4>

          <p
            className="p-0 mb-1 w-100 d-flex align-items-center justify-content-center"
            style={{ fontSize: "15px", borderTop: "2px solid black" }}
          >
            PLEASE NOTE
          </p>
          <p
            className="p-0 mb-1 w-100 d-flex align-items-center justify-content-center"
            style={{ fontSize: "15px", borderBottom: "2px solid black" }}
          >
            1 POINT = 100
          </p>

          <p className="p-0 mb-1 w-100 d-flex align-items-center justify-content-center">
            Maximum Bet
          </p>
          <p className="p-0 mb-1 w-100 d-flex align-items-center justify-content-center">
            2,00,000
          </p>
          <Button
            className="mt-2"
            style={{ background: "black", fontWeight: "600" }}
            onClick={() => launchCasino(evolutionModal?.data)}
          >
            OK, I AGREE
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EvolutionModal;
