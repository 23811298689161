import React, { useContext, useEffect, useState } from "react";
import LayoutComponent from "../../components/shared/Layout";
import HeadContainer from "./HeadContainer";
import CasinoFilter from "./CasinoFilter";
import GameCard from "../../components/GameCard";
import {
  gamesLiveArray,

  PokerGames,
  AviatorGames,

  BetGames,

  NavDepositWithrawData2,
  DepositWithrawDataArrayNew,
  EzugiNew,
  RoyalGammingNew,
  VivoNew,
} from "../../constraints/constants";
import GameCardNew from "../../components/GameCardNew";
import BetSlipContext from "../../context/BetSlipContext";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { isEmpty, startCase } from "lodash";
const Casino = () => {
  const [header, setHeader] = useState("poker");

  var settings1 = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    arrows: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const {
    dolaunchCasinoRyoal,
    setEvolutionModal,
    launchEGTCasino,
    launchCasino,
    user,
  } = useContext(AuthContext);
  const { dolaunchCasinoNew } = useContext(BetSlipContext);
  const navigate = useNavigate();
  const handel = (res) => {
    if (!isEmpty(user)) {
      if (
        res?.platForm == "EVOLUTION" ||
        res?.platForm == "JOKER" ||
        res?.platForm == "BG" ||
        res?.platForm == "SV388" ||
        res?.platForm == "PT"
      ) {
        setEvolutionModal({
          status: true,
          data: res,
        });
      } else {
        if (!isEmpty(res?.gameid)) {
          launchEGTCasino({
            platForm: res?.platForm,
            gameType: res?.gameType,
            casinoType: res?.casinoType,
            gameid: res?.gameid,
          });
        } else {
          launchCasino({
            platForm: res?.platForm,
            gameType: res?.gameType,
            casinoType: res?.casinoType,
            isLobbyTrue: res?.isLobbyTrue,
          });
        }
      }
    } else {
      navigate("/login");
    }
  };

  const handelChange = (res, game, type) => {
    if (!isEmpty(user)) {
      dolaunchCasinoNew(
        game == "poker"
          ? {
              gameCode: res?.gameCode,
              legacy: res?.legacy,
              type: type,
            }
          : {
              gameCode: res?.gameCode,
              legacy: res?.legacy,
              type: type,
            }
      );
    } else {
      navigate("/login");
    }
  };
  return (
    <div>
      <LayoutComponent visibilityType={true}>
        <div className="payment-sec casino-category" {...settings1}>
          {NavDepositWithrawData2?.map((item) => {
            return (
              <div
                onClick={() => {
                  setHeader(item?.firstTab);
                }}
                className={`payment-box ${
                  header == item?.firstTab ? "active" : ""
                }`}
              >
                <figure>
                  <img src={item?.icons} alt="" />
                </figure>
                <span>{item?.title}</span>
              </div>
            );
          })}
        </div>
        <div className={"main-casino-wrapper"}>
          <div className="games-wrapper casino-all-games">
            {/* {header == "live" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {LiveGames?.map((res, index) => {
                    return <GameCardNew key={index} res={res} />;
                  })}
                </div>
              </div>
            )}
            {header == "ezugi" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {popularArray?.map((res, index) => {
                    return <GameCardNew key={index} res={res} />;
                  })}
                </div>
              </div>
            )} */}
            {header == "poker" && (
              <div className="games-card-inner bg-white">
                <img src={"../assets/images/poker/poker.webp"} />
                <div className="row">
                  {PokerGames?.map((res, index) => {
                    return (
                      <GameCardNew
                        key={index}
                        res={res}
                        type={"first"}
                        game={"poker"}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {header == "TABLE" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.type == "TABLE";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "SLOT" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.type == "SLOT";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "AWC" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.type == "AWC";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "LIVE" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return ["ROULETTE", "GAME-SHOWS"].includes(res?.type);
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "FISHING" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.type == "FISHING";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "EGAME" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.type == "EGAME";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "aviator" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {AviatorGames?.map((res, index) => {
                    return <GameCardNew key={index} res={res} type={"first"} />;
                  })}
                </div>
              </div>
            )}
            {header == "Casino" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {DepositWithrawDataArrayNew?.map((res, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (res?.legacy == "aura") {
                            handelChange(res, "poker", "first");
                          } else if (!isEmpty(res?.url_thumb)) {
                            dolaunchCasinoRyoal(res);
                          } else {
                            handel(res);
                          }
                        }}
                        style={{ background: "black" }}
                        className={`game-card position-relative ${
                          res?.title ? "margin" : ""
                        }`}
                      >
                        <img
                          style={
                            res?.size == "size"
                              ? { height: "120px" }
                              : res?.size == "contain"
                              ? { objectFit: "contain" }
                              : {}
                          }
                          src={res?.image || res?.url_thumb}
                          alt=""
                          className="newCardImage"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {header == "Ezugi" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {EzugiNew?.map((res, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (isEmpty(user)) {
                            navigate("/login");
                          } else {
                            dolaunchCasinoRyoal({
                              game_code: res?.gameCode,
                              game_id: res?.gameId,
                              game_name: res?.name,
                              provider_name: "EZUGI",
                              category: res?.Cat,
                              deviceType: "mobile",
                              isLobby: res?.category == "LOBBY" ? true : false,
                            });
                          }
                        }}
                        style={{ background: "black" }}
                        className={`game-card position-relative ${
                          res?.title ? "margin" : ""
                        }`}
                      >
                        <img
                          style={
                            res?.size == "size"
                              ? { height: "120px" }
                              : res?.size == "contain"
                              ? { objectFit: "contain" }
                              : {}
                          }
                          src={res?.img}
                          alt=""
                          className="newCardImage"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}{" "}
            {header == "royal" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {RoyalGammingNew?.map((res, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (isEmpty(user)) {
                            navigate("/login");
                          } else {
                            dolaunchCasinoRyoal({
                              game_code: res?.gameCode,
                              game_id: res?.gameId,
                              game_name: res?.name,
                              provider_name: "RG",
                              category: res?.Cat,
                              deviceType: "mobile",
                              isLobby: res?.category == "LOBBY" ? true : false,
                            });
                          }
                        }}
                        style={{ background: "black" }}
                        className={`game-card position-relative ${
                          res?.title ? "margin" : ""
                        }`}
                      >
                        <img
                          style={
                            res?.size == "size"
                              ? { height: "120px" }
                              : res?.size == "contain"
                              ? { objectFit: "contain" }
                              : {}
                          }
                          src={res?.img}
                          alt=""
                          className="newCardImage"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {header == "Sexy" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.platForm == "SEXYBCRT";
                    })
                    ?.map((res, index) => {
                      return (
                        <div
                          onClick={() => {
                            handel(res);
                          }}
                          style={{ background: "black" }}
                          className={`game-card position-relative ${
                            res?.title ? "margin" : ""
                          }`}
                        >
                          <img
                            style={
                              res?.size == "size"
                                ? { height: "120px" }
                                : res?.size == "contain"
                                ? { objectFit: "contain" }
                                : {}
                            }
                            src={res?.img}
                            alt=""
                            className="newCardImage"
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {header == "Evolution" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {gamesLiveArray
                    ?.filter((res) => {
                      return res?.platForm == "EVOLUTION";
                    })
                    ?.map((res, index) => {
                      return <GameCardNew key={index} res={res} type={"sec"} />;
                    })}
                </div>
              </div>
            )}
            {header == "Vivo" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {VivoNew?.map((res, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (isEmpty(user)) {
                            navigate("/login");
                          } else {
                            dolaunchCasinoRyoal({
                              game_code: res?.gameCode,
                              game_id: res?.gameId,
                              game_name: res?.name,
                              provider_name: "DC",
                              category: res?.Cat,
                              deviceType: "mobile",
                              isLobby: res?.category == "LOBBY" ? true : false,
                            });
                          }
                        }}
                        style={{ background: "black" }}
                        className={`game-card position-relative ${
                          res?.title ? "margin" : ""
                        }`}
                      >
                        <img
                          style={
                            res?.size == "size"
                              ? { height: "120px" }
                              : res?.size == "contain"
                              ? { objectFit: "contain" }
                              : {}
                          }
                          src={res?.img}
                          alt=""
                          className="newCardImage"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}{" "}
            {header == "BetGames" && (
              <div className="games-card-inner bg-white">
                <div className="row">
                  {BetGames?.map((res, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (isEmpty(user)) {
                            navigate("/login");
                          } else {
                            dolaunchCasinoRyoal({
                              game_code: res?.gameCode,
                              game_id: res?.gameId,
                              game_name: res?.name,
                              provider_name: startCase(res?.category),
                              category: "LIVE",
                              deviceType: "mobile",
                              isLobby: res?.category == "LOBBY" ? true : false,
                            });
                          }
                        }}
                        style={{ background: "black" }}
                        className={`game-card position-relative ${
                          res?.title ? "margin" : ""
                        }`}
                      >
                        <img
                          style={
                            res?.size == "size"
                              ? { height: "120px" }
                              : res?.size == "contain"
                              ? { objectFit: "contain" }
                              : {}
                          }
                          src={res?.img}
                          alt=""
                          className="newCardImage"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default Casino;
