import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { apiGet, apiPost } from "../Utils/apiFetch";
import { pick, isEmpty } from "lodash";
import apiPath from "../Utils/apiPath";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { io } from "socket.io-client";
import moment from "moment";
import { toast } from "react-toastify";
const AuthContext = createContext();
export default AuthContext;
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [searchTab, setSearchTab] = useState(false);
  const [userCoins, setUserCoins] = useState({});
  let [user, setUser] = useState({});
  const [announcement, setAnnouncement] = useState(false);
  const [showWidget, setShowWidget] = useState(true);
  const [activeWidget, setActiveWidget] = useState("");
  const [scrollHeight, setScrollHeight] = useState(75);
  const [messageLotter, setMessageLottery] = useState({ status: false });
  const [active, setActive] = useState("InPlay");
  
  const [showDate, setShowDate] = useState({
    status: false,
    type: "",
  });
  const [evolutionModal, setEvolutionModal] = useState({
    status: false,
    data: {},
  });
  const [isChangeDate, setIsChangeDate] = useState(false);
  let today = new Date();
  let days = 86400000;
  let fiveDaysAgo = new Date(today - 5 * days);
  const [plDate, setPlDate] = useState([
    {
      startDate: fiveDaysAgo,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (messageLotter?.status) {
      let timer = setTimeout(() => {
        setMessageLottery({ status: false });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [messageLotter]);

  const [loaderUser, setLoaderUser] = useState(true);
  const [message, setMessage] = useState({});
  const [refreshAmountLoader, setRefreshAmountLoader] = useState(false);
  const [plDateType, setPlDateType] = useState("");
  const [loginError, setLoginError] = useState("");
  const [keyTime, setKeyTime] = useState("in_play");
  const [gameTab, setGameTab] = useState("all");
  const [profileData, setProfileData] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [showPosition, setShowPosition] = useState({
    status: false,
    object: {},
  });
  const [checkRefresh, setCheckRefresh] = useState(true);
  const location = useLocation();
  const handleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  let logoutUser = () => {
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    navigate("/login");
    setShowSidebar(false);
  };
  const [totalCount, setTotalCount] = useState(0);
  const [banner, setBanner] = useState([]);
  // getBanner

  let getBannerData = async () => {
    const data = await apiPost(apiPath.getBanner, { type: "home_top" });
    if (data?.status == 200) {
      if (data?.data?.success) {
        setBanner(data?.data?.results?.banners);
      }
    }
  };
  useEffect(() => {
    getBannerData();
  }, []);
  // const refreshAmount = () => {
  //   const newSocket = io(
  //     `${process.env.REACT_APP_API_BASE_URL}?token=${localStorage.getItem(
  //       "token"
  //     )}&userType=front`,
  //     {
  //       transports: ["websocket"],
  //     }
  //   );
  //   const coinListener = (message) => {
  //     setUserCoins({
  //       exp: message?.results?.exposure,
  //       balance: message?.results?.totalCoins,
  //     });
  //   };
  //   const forceLogout = (message) => {
  //     const uniqueId = localStorage.getItem("uniqueId");
  //     if (uniqueId !== message.results.uniqueId) {
  //       logoutUser();
  //     }
  //   };
  //   newSocket.emit("getCoins", { user_id: user ? user.user._id : "" });
  //   newSocket.on("listenGetCoin", coinListener);
  //   newSocket.on("listenForceLogout", forceLogout);
  //   return () => newSocket.close();
  // };

  // const refreshAmount = async () => {
  //   setRefreshAmountLoader(true);
  //   const data = await apiGet(apiPath.refreshAmount);
  //   if (data?.status == 200) {
  //     if (data?.data?.success) {
  //       setUserCoins({
  //         exp: data?.data?.results?.exposure,
  //         balance: data?.data?.results?.totalCoins,
  //       });
  //       setRefreshAmountLoader(false);
  //     } else {
  //       setRefreshAmountLoader(false);
  //     }
  //   } else {
  //     setRefreshAmountLoader(false);
  //   }
  // };

  const messageList = async () => {
    const { status, data: response_users } = await apiGet(apiPath.messageList);
    if (status === 200) {
      if (response_users.success) {
        setMessage(response_users.results);
      }
    }
  };

  const [sokcetId, setSocketId] = useState({});
  useEffect(() => {
    if (showSidebar) {
      setShowSidebar(false);
    }
    if (location?.pathname?.split("/")[1] !== "full-market") {
      secureLocalStorage.removeItem("betFair");
      secureLocalStorage.removeItem("bookmaker");
      secureLocalStorage.removeItem("fancy");
      secureLocalStorage.removeItem("tie");
      secureLocalStorage.removeItem("premiumFancy");
      secureLocalStorage.removeItem("betFairMulti");
      secureLocalStorage.removeItem("line");
      setShowWidget(true);
      setActiveWidget("");
    }
    if (location?.pathname?.split("/")[1] !== "profit-and-loss") {
      setShowDate({
        status: false,
        type: "",
      });
      setPlDate([
        {
          startDate: fiveDaysAgo,
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    if (
      location?.pathname?.split("/")[1] !== "full-market" &&
      !isEmpty(sokcetId)
    ) {
      sokcetId.disconnect();
      setSocketId({});
    }
  }, [location]);

  let getProfileData = async () => {
    const data = await apiGet(apiPath.userProfile);
    if (data?.status == 200) {
      if (data?.data?.success) {
        setProfileData(data?.data?.results);
        setUserCoins({
          exp: data?.data?.results?.exposure,
          balance: data?.data?.results?.totalCoins,
        });
        setTotalCount(data?.data?.results?.totalUnreadCount);
      }
    }
  };

  const registerUser = async ({ body, inputRef }) => {
    try {
      const { status, data: response_users1 } = await apiPost(
        apiPath.registerUser,
        {
          username: body?.username,
          password: body?.password,
          phone_number: body?.mobile?.substring(
            inputRef?.current?.state.selectedCountry?.countryCode?.length,
            body?.mobile?.toString()?.length
          ),
          refreralCode: body?.refreralCode || "",
          country_code: inputRef?.current?.state.selectedCountry?.countryCode,
          uniqueId: body?.uniqueId,
        }
      );
      if (status === 200) {
        if (response_users1.success) {
          localStorage.setItem("token", response_users1?.results?.token);
          localStorage.setItem(
            "refresh_token",
            response_users1?.results?.refresh_token
          );
          setUser(jwt_decode(response_users1?.results.token));
          navigate("/");
        } else {
          toast.error(response_users1?.message);
        }
      } else {
        toast.error(response_users1?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  let loginUser = async (body) => {
    body.website = "wifiexch.com";
    const { status, data } = await apiPost(
      apiPath.loginUser,
      pick(body, ["username", "password", "uniqueId"])
    );
    if (status === 200) {
      if (data.success) {
        setLoginError("");
        localStorage.setItem("token", data.results.token);
        localStorage.setItem("refresh_token", data.results.refresh_token);
        setUser(jwt_decode(data.results.token));
        navigate("/");
      } else {
        setLoginError(data.message);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      let data = jwt_decode(localStorage.getItem("token"));
      if (!isEmpty(data)) {
        setLoaderUser(true);
        setUser(data);
        setLoaderUser(false);
      }
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(user) && checkRefresh) {
      amounutRefresh();
    }
  }, [user, checkRefresh]);

  // useEffect(() => {
  //   if (!isEmpty(user)) {
  //     if (!user?.user?.isLoggedIn) {
  //       navigate("/change-password");
  //     }
  //   } else {
  //     navigate("/");
  //   }
  // }, [user]);

  useEffect(() => {
    if (!isEmpty(user)) {
      messageList();
      getProfileData();
    }
  }, [user]);

  useEffect(() => {
    if (!isEmpty(user)) {
      let interval = setInterval(() => {
        getProfileData();
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [user]);

  const [socketObj, setSocketObj] = useState(null);
  const amounutRefresh = () => {
    setRefreshAmountLoader(true);
    setCheckRefresh(false);
    if (!isEmpty(socketObj)) {
      socketObj.disconnect();
    }
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL}?token=${localStorage.getItem(
        "token"
      )}&userType=front`,
      {
        transports: ["websocket"],
      }
    );
    const coinListener = (message) => {
      setUserCoins({
        exp: message?.results?.exposure,
        balance: message?.results?.totalCoins,
      });
      setRefreshAmountLoader(false);
    };
    const forceLogout = (message) => {
      const uniqueId = localStorage.getItem("uniqueId");
      if (uniqueId !== message.results.uniqueId) {
        logoutUser();
      }
    };
    setSocketObj(newSocket);
    newSocket.emit("getCoins", { user_id: user ? user?.user?._id : "" });
    newSocket.on("listenGetCoin", coinListener);
    newSocket.on("listenForceLogout", forceLogout);
    newSocket.on("newMessage", (inbox) => {
      console.log(inbox, user, "use");
      if (inbox?.receiver == user?.user?._id) {
        setTotalCount((prev) => {
          return prev + 1;
        });
      }
    });
    newSocket.on("disconnect", function () {
      setRefreshAmountLoader(true);
      setCheckRefresh(true);
    });
    return () => newSocket.disconnect();
  };

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && user && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  const launchEGTCasino = async (request) => {
    if (request?.platForm || request?.gameType || request?.casinoType) {
      const { status, data: response_users1 } = await apiPost(
        apiPath.easytogoCasinoAmountAdd,
        {
          prod: request?.platForm,
          type: request?.gameType,
        }
      );
      if (status === 200) {
        if (response_users1.success) {
          const { status, data: response_users } = await apiPost(
            apiPath.doLoginAndLaunchEasyToCasino,
            {
              prod: request?.platForm,
              type: request?.gameType,
              gameCode: request?.casinoType,
              gameid: request?.gameid || false,
            }
          );
          if (status === 200 && response_users.status) {
            if (response_users.data.err === 1) {
              localStorage.setItem("launchEGTCasino", request?.platForm);
              window.location.href = response_users.data.url;
            }
          }
        }
      }
    }
  };
  const launchCasino = async (request) => {
    if (request?.platForm || request?.gameType || request?.casinoType) {
      const { status, data: response_users1 } = await apiPost(
        apiPath.casinoAmountAdd,
        { amount: 0 }
      );
      if (status === 200) {
        if (response_users1.success) {
          const { status, data: response_users } = await apiPost(
            request?.platForm && request?.gameType && request?.casinoType
              ? apiPath.doLoginAndLaunchGame
              : apiPath.casinoWalletLogin,
            request?.platForm && request?.gameType && request?.casinoType
              ? {
                  userId: response_users1?.results?.aeCasinoUserId,
                  platForm: request?.platForm == "1" ? "" : request?.platForm,
                  gameType: request?.gameType,
                  gameCode: request?.casinoType,
                }
              : {
                  userId: response_users1?.results?.aeCasinoUserId,
                  gameType: request?.gameType,
                  platForm: request?.platForm == "1" ? "" : request?.platForm,
                }
          );
          if (status === 200) {
            if (response_users.status) {
              if (response_users.data.status === "0000") {
                window.location.href = response_users.data.url;
              }
            }
          }
        }
      }
    }
  };
  // doLoginAndLaunchEasyToCasinoNew => poker and avaitaor

  const dolaunchCasinoNew = async (request) => {
    if (request?.gameCode) {
      const { status, data: response_users1 } = await apiPost(
        request?.type == "first"
          ? apiPath.doLoginAndLaunchIQCasino
          : apiPath.doLoginAndLaunchEasyToCasinoNormal,
        {
          gameid: request?.gameCode,
          legacy: request?.legacy || "",
        }
      );
      if (status === 200) {
        if (response_users1.status) {
          window.location.href = response_users1.data;
        } else {
          toast.error(
            response_users1?.message == "success"
              ? "Casino is in maintenance mode if you have any query please contact upline"
              : response_users1?.message ||
                  "Casino is in maintenance mode if you have any query please contact upline"
          );
        }
      }
    }
  };


  const dolaunchCasinoRyoal = async (request) => {
    const { status, data: response_users1 } = await apiPost(
      apiPath.doLoginAndLaunchRoyaCasino,
      {
        game_code: request?.game_code,
        game_id: request?.game_id,
        game_name: request?.game_name,
        provider: request?.provider_name,
        category: request?.category,
        deviceType: "mobile",
        isLobby: request?.category == "LOBBY" ? true : false,
      }
    );
    if (status === 200) {
      if (response_users1.status) {
        window.location.href = response_users1.data;
      } else {
        toast.error(
          response_users1?.message == "success"
            ? "Casino is in maintenance mode if you have any query please contact upline"
            : response_users1?.message ||
                "Casino is in maintenance mode if you have any query please contact upline"
        );
      }
    }
  };
  let contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    showSidebar,
    setShowSidebar,
    handleShowSidebar,
    setGameTab,
    gameTab,
    keyTime,
    setKeyTime,
    loginError,
    profileData,
    setShowPosition,
    showPosition,
    userCoins,
    setUserCoins,
    amounutRefresh,
    scrollHeight,
    setScrollHeight,
    showDate,
    setShowDate,
    plDate,
    setPlDate,
    plDateType,
    setPlDateType,
    getProfileData,
    refreshAmountLoader,
    setSearchTab,
    searchTab,
    showWidget,
    setShowWidget,
    setActiveWidget,
    activeWidget,
    messagelist: message,
    announcement,
    setAnnouncement,
    launchEGTCasino,
    launchCasino,
    setUser,
    isChangeDate,
    setIsChangeDate,
    messageLotter,
    setMessageLottery,
    sokcetId,
    setSocketId,
    active,
    setActive,
    loaderUser,
    setLoaderUser,
    registerUser,
    banner,
    setRefreshAmountLoader,
    totalCount,
    evolutionModal,
    setEvolutionModal,dolaunchCasinoNew,dolaunchCasinoRyoal
  };
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
