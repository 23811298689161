import React from "react";
import { Col, Row,Button } from "react-bootstrap";
import SportsCard from "../components/SportsCard";
import CasinoCard from "../components/CasinoCard";
import LiveCasino from "../components/LiveCasino";
import { useNavigate } from "react-router-dom";
import { FaDownload, FaUpload } from "react-icons/fa";
import GameSlider from "../components/GameSlider";
const BeforeLogin = () => {
  const navigate = useNavigate();
  return (
    <div className="live-casnios">
      {/* <SportsCard/> */}
      <CasinoCard />

      <div
        style={{
          background: "linear-gradient(90deg,#fff 50%,#d4b94e)",
          borderRadius: "7px",
        }}
        className=" d-flex justify-content-evenly align-items-center pt-2 pb-2 mb-2"
      >
        <Button
          onClick={() => navigate("/withdraw")}
          style={{
            width: "45%",
            background: "#0f2327",
            border: "none",
            borderRadius: "5px",
          }}
          className="btn btn-primary d-flex justify-content-center align-items-center"
        >
          <FaDownload style={{ marginRight: "10px" }} /> Withdraw
        </Button>
        <Button
          onClick={() => navigate("/deposit")}
          style={{
            width: "45%",
            background: "linear-gradient(#022c43, #18b0c8)",
            border: "none",
            borderRadius: "5px",
          }}
          className="btn btn-primary d-flex justify-content-center align-items-center"
        >
          <FaUpload style={{ marginRight: "10px" }} /> Deposit
        </Button>{" "}
      </div>

      <GameSlider />
      {/* <LiveCasino /> */}
    </div>
  );
};

export default BeforeLogin;
