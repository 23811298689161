import React, { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
const BackLayBookmaker = ({
  component,
  handelChange,
  odds_lay,
  odds_back,
  index,
  item,
  limit,
  betPlaceAccept,
}) => {
  const { betSlipObject, showBetDialog } = useContext(BetSlipContext);
  let backClass = `back ${
    Number(
      document.getElementById("back_odds_bookmaker_" + index)?.title?.trim()
    ) !== Number(odds_back[0]?.rt)
      ? "spark-back-new"
      : ""
  } ${
    betSlipObject?.teamName == item?.RunnerName &&
    betSlipObject?.odds == odds_back[0]?.rt &&
    showBetDialog
      ? "back-active"
      : ""
  }`;
  let layClass = `lay ${
    Number(document.getElementById("lay_odds_bookmaker_" + index)?.trim()) !==
    Number(odds_lay[0]?.rt)
      ? "spark-lay-new"
      : ""
  } ${
    betSlipObject?.teamName == item?.RunnerName &&
    betSlipObject?.odds == odds_lay[0]?.rt &&
    showBetDialog
      ? "lay-active"
      : ""
  }`;
  let oddsCheck = odds_lay?.length > 0 || odds_back?.length > 0;

  let check = (odds) => {
    if (betPlaceAccept) {
      if (Number(odds) > 0) {
        if (Number(odds) > limit?.oddsLimit) {
          return "single-disabled";
        } else {
          return "";
        }
      } else {
        return "single-disabled";
      }
    } else {
      return "single-disabled";
    }
  };

  let checkDisabled = (odds) => {
    if (Number(odds) > 0) {
      if (Number(odds) > limit?.oddsLimit) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  let backCheck = check(odds_back[0]?.rt) || "";
  let layCheck = check(odds_lay[0]?.rt) || "";

  return (
    <div
      className={`lay-back-table ${
        ["wifiBookmaker", "bookmaker"].includes(component)
          ? "bg-bookmaker-market"
          : ""
      }`}
    >
      <div
        title={`${Number(odds_back[0]?.rt) > 0 ? odds_back[0]?.rt : ""}`}
        id={"back_odds_bookmaker_" + index}
        onClick={() => {
          if (betPlaceAccept) {
            if (!checkDisabled(odds_back[0]?.rt)) {
              handelChange(odds_back[0], "back", item?.RunnerName, item, index);
            }
          }
        }}
        className={backClass}
      >
        <span className={backCheck}>
          {backCheck == "single-disabled" && <span className="disabled"></span>}
          <strong>{odds_back[0]?.rt}</strong>
          {["wifiBookmaker", "bookmaker"].includes(component) ? (
            ""
          ) : (
            <span className="d-block">{odds_back[0]?.bv}</span>
          )}
        </span>
      </div>
      <div
        title={`${Number(odds_lay[0]?.rt) > 0 ? odds_lay[0]?.rt : ""}`}
        id={"lay_odds_boomaker_" + index}
        onClick={() => {
          if (betPlaceAccept) {
            if (!checkDisabled(odds_lay[0]?.rt)) {
              handelChange(odds_lay[0], "lay", item?.RunnerName, item, index);
            }
          }
        }}
        className={layClass}
      >
        <span className={layCheck}>
          {layCheck == "single-disabled" && <span className="disabled"></span>}
          <strong>{odds_lay[0]?.rt}</strong>
          {["wifiBookmaker", "bookmaker"].includes(component) ? (
            ""
          ) : (
            <span className="d-block">{odds_lay[0]?.bv}</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default BackLayBookmaker;
