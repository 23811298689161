import React, { useState, useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { FaArrowsAltV } from "react-icons/fa";
import { isEmpty } from "lodash";
import { priceFormat } from "../Utils/constants";
import BackLay from "./BackLay";
import { useNavigate } from "react-router-dom";
import useScroll from "./Scroll";
import { AiOutlineStar } from "react-icons/ai";
import { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import BackLayBookmaker from "./BackLayBookmaker";
const BookmakerToss = ({
  backOdds,
  layOdds,
  bookmaker,
  user,
  profileData,
  detail,
  setBetSlipObject,
  setShowBetDialog,
  betSlipObject,
  setLimit,
  displayType,
  showBetDialog,
  scrollHeight,
  socketUrl,
  bookmakerSuspended,
  ms,
}) => {
  const { setMessage } = useContext(BetSlipContext);
  const naviagte = useNavigate();
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [localLimit, setLocalLimit] = useState({
    maxAmount: 100000,
    minAmount: 1,
    maxProfit: 1000000,
  });
  //   useEffect(() => {
  //     let obj = profileData?.matchSetting?.find(
  //       (res) =>
  //         res?.type == "bookmaker" &&
  //         (profileData?.arrayCheck == "details"
  //           ? true
  //           : res?.sportType == detail?.gameType)
  //     );
  //     if (!isEmpty(obj)) {
  //       setLocalLimit({
  //         ...obj,
  //         maxAmount: 100000,
  //         minAmount: 1,
  //         maxProfit: 1000000,
  //       });
  //     } else {
  //       setLocalLimit({
  //         maxProfit: 1000000,
  //         maxAmount: 100000,
  //         minAmount: 1,
  //       });
  //     }
  //   }, []);
  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      setScrollId(index);
      setShowBetDialog(true);
      setLimit(localLimit);
      setBetSlipObject((prev) => ({
        ...prev,
        odds: 98,
        teamName: team,
        betType: type,
        socketUrl: socketUrl,
        playType: "toss",
        object: {
          ...item,
          ...odds,
          teamName: team,
          eventId: detail?.eventId,
          marketId: detail?.marketId,
        },
      }));
    } else {
      naviagte("/login");
    }
  };
  return (
    <div className="bookmaker p-2">
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div
              className="d-flex align-items-center"
              style={{ paddingLeft: "10px" }}
            >
              {/* <div className="fave bg-star-sprit-black animate"></div> */}
              <AiOutlineStar size={20} style={{ margin: "0px 4px" }} />
              {"Toss"}
            </div>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <div className="match-odd-table bg-white p-1 py-1 pe-0">
              <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
                <span className="text-center w-100">Back</span>
              </div>

              <ul className="mb-2 rounded position-relative p-0">
                {bookmaker?.map((item, index) => {
                  return (
                    <li
                      ref={index == scrollId ? myRef : null}
                      style={{ scrollMarginTop: scrollHeight }}
                    >
                      <h2>
                        {item?.RunnerName || item?.runnerName}{" "}
                        {(Math?.abs(item?.position) > 0 ||
                          Math.abs(item?.newPosition) > 0) && (
                          <span
                            className={`d-block py-1 ${
                              item?.position > 0
                                ? "text-green team-red-score"
                                : "team-red-score"
                            }`}
                          >
                            {Math?.abs(item?.position) > 0
                              ? Math?.abs(item?.position).toFixed(2)
                              : ""}
                            {Math.abs(item?.newPosition) > 0 && (
                              <span
                                className={
                                  item?.newPosition > 0 ? "text-green" : ""
                                }
                              >
                                {" "}
                                {">"} {Math.abs(item?.newPosition).toFixed(2)}
                              </span>
                            )}
                          </span>
                        )}
                      </h2>
                      <div className={`lay-back-table toss-back`}>
                        <div
                          onClick={() => {
                            handelChange(
                              98,
                              "back",
                              item?.RunnerName,
                              item,
                              index
                            );
                          }}
                          className={`back ${
                            betSlipObject?.teamName == item?.RunnerName &&
                            betSlipObject?.odds == 98 &&
                            betSlipObject?.playType == "toss" &&
                            showBetDialog
                              ? "back-active"
                              : ""
                          }`}
                        >
                          <span>
                            <strong>98</strong>
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="market-depth-sec d-flex justify-content-end">
                <div className="d-flex align-items-center min-max">
                  <FaArrowsAltV />
                  <span>min/max</span>
                  <strong>
                    {localLimit?.minAmount || 1}/
                    {priceFormat(localLimit?.maxAmount) || 100}
                  </strong>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default BookmakerToss;
