import { useNavigate, Link, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Form,
  Button,
  InputGroup,
  ButtonGroup,
  Container,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { inRange, isEmpty } from "lodash";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import LayoutComponent from "../components/shared/Layout";
import ConfirmPin from "../components/ConfirmPin";
import { toast } from "react-toastify";
const WithDraw = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const {
    userCoins,
    amounutRefresh,
    user,
    sendMobileOTP,
    getProfileData,
    profileData,
    setSeconds,
    setMinutes,
    seconds,
    minutes,
    getSendOtpStatus,
    setSendOtpStatus,
  } = useContext(AuthContext);
  const [checkbox, setCheckBox] = useState(false);
  const [otpSend, setOtpSend] = useState(false);
  const [bank, setBank] = useState([]);
  const [confirmPin, setConfirmPin] = useState(false);
  const [pin, setPin] = useState("");
  const [afterData, setAfterData] = useState({});
  const [verifyEdit, setVerifyEdit] = useState({});
  const [verify, setVerify] = useState({});
  const handleCloseVerify = () => {
    setVerifyEdit({ status: false });
  };
  const onSubmit = async (body) => {
    // setConfirmPin(true);
    setAfterData(body);
  };

  const getOTP = () => {};
  const AfterPinSubmit = async (body) => {
    if (checkbox) {
      setLoader(true);
      body = {
        amount: body?.amount,
        bank: body?.bank,
        ifsc_code: body?.ifsc_code,
        AccountName: body?.accountName,
        BankAccount: body?.accountNumber,
        phone_number: body?.mobile,
        otp: body?.verificationCode,
        pin: body?.password,
      };

      const { status, data } = await apiPost(apiPath.withdrawal, body);
      if (status == 200) {
        if (data?.success) {
          amounutRefresh();
          reset();
          setAfterData({});
          setOtpSend(false);
          setPin("");
          setCheckBox(false);
          setConfirmPin(false);
          setLoader(false);
          toast.success(data?.message);
        } else {
          reset();
          setOtpSend(false);
          setCheckBox(false);
          setLoader(false);
          toast.error(data?.message);
        }
      } else {
        reset();
        setOtpSend(false);
        setCheckBox(false);
        setLoader(false);
        toast.error(data?.message);
      }
    } else {
      toast.error("Please Select Terms and Condition");
    }
  };

  const getBank = async () => {
    const { status, data } = await apiPost(apiPath.getBank, {
      type: "withdrawal",
    });
    if (status == 200) {
      if (data?.success) {
        setBank(data?.results?.bankList);
      }
    }
  };

  const userSendOtp = async (value) => {
    const { status, data } = await apiPost(apiPath.sendOtp, {
      phone_number: value,
    });
    if (status == 200) {
      if (data?.success) {
        setOtpSend(true);
      }
    }
  };
  const getBankDetail = async () => {
    const { status, data } = await apiPost(apiPath.getBankDetail);
    if (status == 200) {
      if (data?.success) {
        setValue("ifsc_code", data?.results?.ifsc_code);
        setValue("accountName", data?.results?.AccountName);
        setValue("accountNumber", data?.results?.BankAccount);
      }
    }
  };

  useEffect(() => {
    getBank();
    getProfileData();
  }, []);

  const inputRef = useRef(null);

  const [isLoader1, setLoader1] = useState(false);
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    control: control1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm({});

  const [isLoader, setLoader] = useState(false);
  const onSubmit1 = async (body) => {
    setLoader(true);
    const { status, data } = await apiPost(apiPath.verifyOtp, {
      phone_number:
        inputRef?.current?.state.selectedCountry?.countryCode +
        body?.mobile?.substring(
          inputRef?.current?.state.selectedCountry?.countryCode?.length,
          body?.mobile?.toString()?.length
        ),
      otp: body?.verificationCode,
    });
    if (status === 200) {
      if (data.success) {
        setLoader(false);
        handleCloseVerify();
        getProfileData();
        reset();
        setSendOtpStatus(false);
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const [show, setShow] = useState(false);
  return (
    <LayoutComponent visibilityType={true} newType={true}>
      <Container>
        <div
          style={{ marginTop: "70px" }}
          className="main dw-p withdraw-box page-content-box w-100 bg-gradual-black"
        >
          <div className="width70">
            <div
              style={{
                background: "white",
                padding: "10px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              <div className="d-flex flex-column align-items-start ">
                <span style={{ fontWeight: "600", fontSize: "16px" }}>
                  Withdrawal Amount
                </span>
                <span
                  style={{
                    fontSize: "1.2rem",
                    color: "black",
                  }}
                >
                  NPR{" "}
                  <span style={{ fontWeight: "600", paddingLeft: "4px" }}>
                    {userCoins?.balance?.toFixed(2) -
                      profileData?.availableTurnoverFinal?.toFixed(2) >
                    0
                      ? (
                          userCoins?.balance?.toFixed(2) -
                          profileData?.availableTurnoverFinal?.toFixed(2)
                        ).toFixed(2)
                      : 0}
                  </span>
                </span>
              </div>
            </div>{" "}
            <div
              style={{
                background: "white",
                padding: "10px",
                borderRadius: "5px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div className="d-flex flex-column align-items-start ">
                <span style={{ fontWeight: "600", fontSize: "16px" }}>
                  Holding Amount
                </span>
                <span
                  style={{
                    fontSize: "1.2rem",
                    color: "black",
                  }}
                >
                  NPR{" "}
                  <span style={{ fontWeight: "600", paddingLeft: "4px" }}>
                    {Number(profileData?.availableTurnoverFinal).toFixed(2) ||
                      0}
                  </span>
                </span>
              </div>
              <Button
                onClick={() => navigate("/turnover")}
                className="btn-submit w-25"
              >
                TurnOver
              </Button>
            </div>
            <div
              style={{
                background: "white",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <h5>WithDraw Funds</h5>
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  padding: "0",
                  margin: "0",
                }}
              >
                <p className="p-0 m-0">
                  1. This form is for withdrawing the amount from the main
                  wallet only.
                </p>
                {show && (
                  <>
                    <p className="p-0 m-0">
                      2. The bonus wallet amount cannot be withdrawn by this
                      form.
                    </p>
                    <p className="p-0 m-0">
                      3. Do not put Withdraw request without betting with
                      deposit amount. Such activity will be identified as
                      Suspicious
                    </p>
                    <p className="p-0 m-0">
                      4. If multiple users are using same withdraw account then
                      all the linked users will be blocked.
                    </p>
                    <p className="p-0 m-0">
                      5. Paytm account numbers always start with 91.
                    </p>
                    <p className="p-0 m-0">
                      6. Avoid utilizing Airtel Payment Bank, Jio Payment Bank,
                      and Paytm Payment Bank for withdrawal transactions.
                    </p>
                  </>
                )}
                  <span
                  onClick={() => setShow(!show)}
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "flex-end",
                    fontWeight: "600",
                  }}
                >
                  ...Show {!show ? 'More' : 'Less'}
                </span>
              </div>
            </div>
            <h5 className="mt-2 mb-2">Please fill in all required fields</h5>
            <div className="d-flex justify-content-between p-1">
              <Button
                style={{
                  background: "#0f2327",
                  border: "none",
                  borderRadius: "0",
                }}
              >
                Use New Account
              </Button>{" "}
              <Button
                onClick={() => getBankDetail()}
                style={{
                  background: "#0f2327",
                  border: "none",
                  borderRadius: "0",
                }}
              >
                Use Previous Account
              </Button>
            </div>
            <div
              className="usrTrans-wrapper common-box form-f mb-66 mt-2"
              style={{
                background: "white",
                borderRadius: "5px",
                padding: "5px",
              }}
            >
              <div
                className="withdraw-form usrTrans-form"
                style={{ marginBottom: "90px" }}
              >
                <Form
                  onSubmit={handleSubmit(AfterPinSubmit)}
                  id="deposit_form"
                  class="deposit_form"
                >
                  <div class="usrTrans-seperate mt-0 p-1 deposit-amount">
                    <div class="transaction-title">
                      <span>{"Amount"}</span>
                      <span class="important-icon">*</span>
                    </div>
                    <div class="transaction-option m-auto">
                      <input
                        {...register("amount", {
                          required: {
                            value: true,
                            message: "Please enter amount",
                          },
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Please enter valid number",
                          },
                          validate: (value) => {
                            if (value > Number.parseInt(userCoins?.balance)) {
                              return "Amount should be less than or equal to main balance";
                            } else if (value > 49999) {
                              return "Max Withdraw should be greater than 50,000";
                            } else if (value < 500) {
                              return "Min Withdraw should be 500";
                            }
                          },
                        })}
                        type="text"
                        class="text-input"
                        id="depositAmt"
                        placeholder="0.00"
                      />
                    </div>
                    {errors?.amount?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.amount?.message}
                      </div>
                    )}
                  </div>
                  <div class="usrTrans-seperate mt-0 p-1 deposit-amount">
                    <div class="transaction-title">
                      <span>{"IFSC Code"}</span>
                      <span class="important-icon">*</span>
                    </div>
                    <div class="transaction-option m-auto">
                      <input
                        {...register("ifsc_code", {
                          required: {
                            value: true,
                            message: "Please enter ifsc code",
                          },
                        })}
                        type="text"
                        class="text-input"
                        placeholder="0.00"
                      />
                    </div>
                    {errors?.ifsc_code?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.ifsc_code?.message}
                      </div>
                    )}
                  </div>
                  {/* <div class="usrTrans-seperate mt-0 p-1 deposit-amount">
                    <div class="transaction-title">
                      <span>{"Bank"}</span>
                      <span class="important-icon">*</span>
                    </div>
                    <div class="transaction-option m-auto">
                      <select
                        {...register("bank", {
                          required: {
                            value: true,
                            message: "Please select bank",
                          },
                          onChange: (e) => {
                            getBankDetail(e?.target?.value);
                          },
                        })}
                        id="depositBankId"
                        class="gatewayBankSelect"
                      >
                        <option value="">{"Select Bank"}</option>
                        {bank?.length > 0 &&
                          bank?.map((res) => {
                            return (
                              <option value={res?._id}>{res?.bank_name}</option>
                            );
                          })}
                      </select>
                    </div>
                    {errors?.bank?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.bank?.message}
                      </div>
                    )}
                  </div>{" "} */}
                  <div class="usrTrans-seperate mt-0 p-1 deposit-amount">
                    <div class="transaction-title">
                      <span>{"Account Number"}</span>{" "}
                      <span class="important-icon">*</span>
                      <span class="copyBtn bg-gradient-secondary">
                        <i class="fas fa-copy"></i>
                      </span>
                    </div>
                    <div class="transaction-option m-auto">
                      <input
                        {...register("accountNumber", {
                          required: {
                            value: true,
                            message: "Please enter account number",
                          },
                        })}
                        type="number"
                        class="text-input"
                        id="depositAccName"
                      />
                    </div>{" "}
                    {errors?.accountNumber?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.accountNumber?.message}
                      </div>
                    )}
                  </div>
                  <div class="usrTrans-seperate mt-0 p-1 deposit-amount">
                    <div class="transaction-title">
                      <span>{"Confirm Account Number"}</span>{" "}
                      <span class="important-icon">*</span>
                      <span class="copyBtn bg-gradient-secondary">
                        <i class="fas fa-copy"></i>
                      </span>
                    </div>
                    <div class="transaction-option m-auto">
                      <input
                        {...register("confirmAccountNumber", {
                          required: "Please enter confirm account number",
                          validate: (value) => {
                            if (value == watch("accountNumber")) {
                              return true;
                            } else {
                              return "confirm account number is not same";
                            }
                          },
                        })}
                        // disabled
                        type="number"
                        class="text-input"
                        id="depositAccName"
                      />
                    </div>{" "}
                    {errors?.confirmAccountNumber?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.confirmAccountNumber?.message}
                      </div>
                    )}
                  </div>
                  <div class="usrTrans-seperate mt-0 p-1 deposit-amount">
                    <div class="transaction-title">
                      <span>{"Account Name"}</span>{" "}
                      <span class="important-icon">*</span>
                      <span class="copyBtn bg-gradient-secondary">
                        <i class="fas fa-copy"></i>
                      </span>
                    </div>
                    <div class="transaction-option m-auto">
                      <input
                        {...register("accountName", {
                          required: {
                            value: true,
                            message: "Please enter account name",
                          },
                        })}
                        // disabled
                        class="text-input"
                        id="depositAccName"
                      />
                    </div>{" "}
                    {errors?.accountName?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.accountName?.message}
                      </div>
                    )}
                  </div>
                  <div class="usrTrans-seperate mt-0 p-1 deposit-amount">
                    <div className="transaction-title">
                      <span>{otpSend ? "OTP" : "Mobile Number"}</span>{" "}
                      <span class="important-icon">*</span>
                      <span className="important-icon"></span>
                    </div>
                    <div className="transaction-option m-auto">
                      {otpSend ? (
                        <Form.Control
                          type="text"
                          placeholder="Enter OTP"
                          maxlength="6"
                          className={
                            errors1.verificationCode ? " is-invalid " : ""
                          }
                          {...register("verificationCode", {
                            required: "Please enter OTP",
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Please enter a valid number",
                            },
                          })}
                        />
                      ) : (
                        <Controller
                          control={control}
                          name="mobile"
                          rules={{
                            required: "Please enter mobile number.",
                            validate: (value) => {
                              let inputValue = value
                                ?.toString()
                                ?.slice(
                                  inputRef?.current?.state?.selectedCountry
                                    ?.countryCode?.length,
                                  value?.length
                                );
                              if (inputValue?.length < 10) {
                                return "Mobile number must contain 10 digit";
                              } else if (inputValue?.length > 12) {
                                return "Mobile number should not exceed 12 digit";
                              } else {
                                return true;
                              }
                            },
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <>
                              <PhoneInput
                                {...field}
                                inputExtraProps={{
                                  ref,
                                  required: true,
                                  autoFocus: true,
                                }}
                                ref={inputRef}
                                inputStyle={{
                                  width: "100%",
                                  height: "38px",
                                }}
                                countryCodeEditable={false}
                                disableDropdown={true}
                                country={"in"}
                              />
                            </>
                          )}
                        />
                      )}
                    </div>
                    <Button
                      disabled={otpSend}
                      onClick={() => {
                        if (!isEmpty(watch("mobile"))) {
                          let value = watch("mobile")
                            ?.toString()
                            ?.slice(
                              inputRef?.current?.state?.selectedCountry
                                ?.countryCode?.length,
                              watch("mobile")?.length
                            );
                          if (inRange(value?.length, 9, 12)) {
                            userSendOtp(watch("mobile"));
                          } else {
                            toast.error("Mobile number must contain 10 digit");
                          }
                        } else {
                          toast.error("Please enter mobile number");
                        }
                      }}
                      style={{ color: "white", marginRight: "5px" }}
                      className="theme-btn py-1 px-3 fs-6 mt-2"
                    >
                      {otpSend ? "Resend OTP" : "Get OTP"}
                    </Button>
                  </div>{" "}
                  <div class="usrTrans-seperate mt-0 p-1 deposit-amount">
                    <div class="transaction-title">
                      <span>Your Password</span>{" "}
                      <span class="important-icon">*</span>
                      <span class="copyBtn bg-gradient-secondary">
                        <i class="fas fa-copy"></i>
                      </span>
                    </div>
                    <div class="transaction-option m-auto">
                      <Form.Control
                        type={"password"}
                        placeholder="Password"
                        maxLength={15}
                        {...register("password", {
                          required: "Please enter password",
                          minLength: {
                            value: 8,
                            message:
                              "Password should contain atleast 8 characters",
                          },
                          maxLength: {
                            value: 16,
                            message:
                              "Password should contain maximum 16 characters",
                          },
                        })}
                      />
                    </div>{" "}
                    {errors?.password?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.password?.message}
                      </div>
                    )}
                  </div>
                  <div className="d-flex w-100 justify-content-start mt-2 p-2">
                    <input
                      checked={checkbox}
                      onClick={(e) => setCheckBox(e.target.checked)}
                      type="checkbox"
                      style={{ marginRight: "10px", width: "22px" }}
                    />
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      I have read and agree with&nbsp;
                      <span>the terms of payment and withdrawal policy.</span>
                    </span>
                  </div>
                  <div className="usrTrans-seperate">
                    <div className="transaction-option">
                      <div className="transaction-btn">
                        <button
                          type="submit"
                          className="btn-submit bg-gradient-primary"
                        >
                          {"WITHDRAW"}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {verifyEdit?.status && (
          <Modal centered show={verifyEdit?.status} onHide={handleCloseVerify}>
            <Modal.Header closeButton>
              <Modal.Title>Verify OTP</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit1(onSubmit1)}>
              <Modal.Body>
                {verifyEdit?.phoneStatus == "add" && (
                  <>
                    <Form.Group className="form-group d-flex mb-3">
                      <Controller
                        className="form-group d-flex"
                        control={control1}
                        name="mobile"
                        rules={{
                          required: "Please enter mobile number.",
                          validate: (value) => {
                            let inputValue = value
                              ?.toString()
                              ?.slice(
                                inputRef?.current?.state?.selectedCountry
                                  ?.countryCode?.length,
                                value?.length
                              );
                            if (inputValue?.length < 10) {
                              return "Mobile number must contain 10 digit";
                            } else if (inputValue?.length > 12) {
                              return "Mobile number should not exceed 12 digit";
                            } else {
                              return true;
                            }
                          },
                        }}
                        render={({ field: { ref, ...field } }) => (
                          <>
                            <PhoneInput
                              {...field}
                              inputExtraProps={{
                                ref,
                                required: true,
                                autoFocus: true,
                              }}
                              ref={inputRef}
                              inputStyle={{
                                width: "100%",
                                height: "38px",
                              }}
                              countryCodeEditable={false}
                              disableDropdown={true}
                              country={"in"}
                            />
                          </>
                        )}
                      />
                    </Form.Group>
                    {errors1?.mobile?.message && (
                      <div className="text-danger">
                        {errors1?.mobile?.message}
                      </div>
                    )}
                  </>
                )}

                <div
                  style={{ position: "relative", width: `100%` }}
                  className="mb-2"
                >
                  <Form.Group className="d-flex ">
                    <div
                      style={{ position: "relative", width: `100%` }}
                      className="mb-2"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter verification code"
                        maxlength="6"
                        onChange={(e) => {
                          setVerifyEdit({
                            ...verifyEdit,
                            verificationCode: e.target.value,
                          });
                        }}
                        className={
                          errors1.verificationCode ? " is-invalid " : ""
                        }
                        {...register1("verificationCode", {
                          required: "Please enter verification code",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                    </div>
                  </Form.Group>
                  {errors1?.verificationCode?.message && (
                    <div className="text-danger">
                      {errors1?.verificationCode?.message}
                    </div>
                  )}
                </div>

                {errors?.verificationCode?.message && (
                  <div className="text-danger">
                    {errors?.verificationCode?.message}
                  </div>
                )}

                <Button
                  disabled={seconds > 0 || minutes > 0}
                  onClick={() => {
                    userSendOtp(watch("mobile"));
                  }}
                  style={{
                    color: "white",
                  }}
                  className="theme-btn py-1 px-3 fs-6"
                >
                  {"Resend Otp"}
                </Button>

                <div className="countdown-text">
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p>Didn't recieve code?</p>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn-submit bg-gradient-primary"
                  disabled={isLoader1 ? "disabled" : ""}
                >
                  <span> {isLoader1 ? "Loading..." : "Confirm"}</span>
                </button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
        {confirmPin && !isEmpty(afterData) && (
          <ConfirmPin
            show={confirmPin}
            handelClose={() => setConfirmPin(false)}
            pin={pin}
            setPin={setPin}
            onSubmit={AfterPinSubmit}
          />
        )}
      </Container>
    </LayoutComponent>
  );
};

export default WithDraw;
