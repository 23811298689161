import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { FaArrowsAltV } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import StackSetting from "./StackSetting";
import BetSlipContext from "../context/BetSlipContext";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { inRange, isEmpty } from "lodash";
import loading from "../assets/images/loading_bet.webp";
import WifiLoader from "./WifiLoader";
const PlaysBet = () => {
  const {
    setShowBetDialog,
    betSlipObject,
    setBetSlipObject,
    setResetBetPosition,
    handelPlaceBetFair,
    handelPlaceFancy,
    handelPlaceSportBook,
    handelPlaceBookmaker,
    limit,
    betLoader,
    handelPlaceTie,
    handelPlaceLineMarket,
    handelPlaceToss,handelPlaceBookmakerWifi
  } = useContext(BetSlipContext);
  const { user, profileData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showStackSetting, setShowStackSetting] = useState(false);
  const [bidPriceDisabled, setBidPricDisabled] = useState(true);
  const handleStackSetting = () => {
    setShowStackSetting(!showStackSetting);
  };
  const [keyboard, setKeyboard] = useState({
    focus: "price",
  });
  const numberArray = [
    { key: 100, value: 100 },
    { key: 1000, value: 1000 },
    { key: 10000, value: 10000 },
    { key: 50000, value: 50000 },
    { key: 100000, value: "1 LAKH" },
    { key: 500000, value: "5 LAKH" },
    { key: 1000000, value: "10 LAKH" },
    { key: 2500000, value: "25 LAKH" },
  ];

  const numberArrayCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "00", "."];
  const handelNumberChange = (item) => {
    if (keyboard?.focus == "odds") {
      setBetSlipObject({
        ...betSlipObject,
        odds: Number(betSlipObject?.odds) + Number(item),
      });
    } else if (keyboard?.focus == "price") {
      setBetSlipObject({
        ...betSlipObject,
        bidPrice: Number(betSlipObject?.bidPrice) + Number(item),
      });
    }
  };

  const handelNumberChangeAddMinus = (type, focus) => {
    let obj = {
      1: 0.01,
      2: 0.02,
      3: 0.05,
      4: 0.1,
      5: 0.1,
      6: 0.2,
      7: 0.2,
      8: 0.2,
      9: 0.2,
      10: 0.5,
    };
    if (focus == "odds") {
      if (parseFloat(betSlipObject?.odds?.toString().split(".")[0]) > 10) {
        setBetSlipObject({
          ...betSlipObject,
          odds:
            type == "plus"
              ? Number(betSlipObject?.odds) + 0.5
              : Number(betSlipObject?.odds) - 0.5,
        });
      } else {
        setBetSlipObject({
          ...betSlipObject,
          odds:
            type == "plus"
              ? (
                  Math.round(
                    (Number(parseFloat(betSlipObject?.odds)) +
                      obj[
                        parseFloat(
                          betSlipObject?.odds?.toString().split(".")[0]
                        )
                      ]) *
                      100
                  ) / 100
                )?.toFixed(2)
              : (
                  Math.round(
                    (Number(parseFloat(betSlipObject?.odds)) -
                      obj[
                        parseFloat(
                          betSlipObject?.odds?.toString().split(".")[0]
                        )
                      ]) *
                      100
                  ) / 100
                )?.toFixed(2),
        });
      }
    } else if (focus == "price") {
      setBetSlipObject({
        ...betSlipObject,
        bidPrice:
          type == "plus"
            ? Number(betSlipObject?.bidPrice) + 5
            : Number(betSlipObject?.bidPrice) - 5 <= 0
            ? 1
            : Number(betSlipObject?.bidPrice) - 5,
      });
    }
  };

  const clear = () => {
    if (keyboard?.focus == "odds") {
      let str = betSlipObject?.odds?.toString();
      setBetSlipObject({
        ...betSlipObject,
        odds: str.substring(0, str.length - 1),
      });
    } else if (keyboard?.focus == "price") {
      let str = betSlipObject?.bidPrice?.toString();
      setBetSlipObject({
        ...betSlipObject,
        bidPrice: str.substring(0, str.length - 1),
      });
    }
  };

  const handelDialPad = (item) => {
    if (keyboard?.focus == "odds") {
      setBetSlipObject({
        ...betSlipObject,
        odds: (Number(betSlipObject?.odds) + Number(item))?.toFixed(2),
      });
    } else if (keyboard?.focus == "price") {
      setBetSlipObject({
        ...betSlipObject,
        bidPrice: betSlipObject?.bidPrice + item,
      });
    }
  };

  const placeBet = () => {
    if (!isEmpty(user)) {
      if (betSlipObject?.playType == "betFair") {
        handelPlaceBetFair();
      } else if (betSlipObject?.playType == "fancy") {
        handelPlaceFancy();
      } else if (betSlipObject?.playType == "sportBook") {
        handelPlaceSportBook();
      } else if (betSlipObject?.playType == "bookmaker") {
        handelPlaceBookmaker();
      } else if (betSlipObject?.playType == "tie") {
        handelPlaceTie();
      } else if (betSlipObject?.playType == "toss") {
        handelPlaceToss();
      } else if (betSlipObject?.playType == "line") {
        handelPlaceLineMarket();
      }else if (betSlipObject?.playType == "wifiBookmaker") {
        handelPlaceBookmakerWifi();
      }
    } else {
      setShowBetDialog(false);
      navigate("/login");
    }
  };

  useEffect(() => {
    if (
      inRange(
        Number(betSlipObject?.bidPrice || 0),
        limit?.minAmount || 1,
        limit?.maxAmount || 100
      )
    ) {
      setBidPricDisabled(false);
    } else {
      setBidPricDisabled(true);
    }
  }, [betSlipObject, limit]);

  let array =
    profileData?.stake?.length > 0
      ? profileData?.stake?.length == 4
        ? [...profileData?.stake, ...profileData?.editStake.slice(4, 8)]
        : profileData?.stake
      : profileData?.editStake;

  // let array =
  //   betSlipObject?.playType == "bookmaker" || betSlipObject?.playType == "fancy"
  //     ? profileData?.stake?.length > 0
  //       ? profileData?.stake?.length == 4
  //         ? [...profileData?.stake, ...profileData?.editStake.slice(4, 8)]
  //         : profileData?.stake
  //       : profileData?.editStake
  //     : (profileData?.stake?.length > 0
  //         ? profileData?.stake
  //         : profileData?.editStake
  //       )?.slice(0, 4);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (!betLoader) {
          setShowBetDialog(false);
          setResetBetPosition(true);
        }
      }}
    >
      <div disabled={betLoader} className="footer-bets plays-bet">
        <div className="player-header d-flex justify-content-between mb-3 align-items-center">
          <div className="d-flex align-items-center">
            {betSlipObject?.playType !== "sportBook" ||
              (betSlipObject?.playType !== "line" && (
                <span
                  className={`bet-name ${
                    betSlipObject?.betType == "back" ||
                    betSlipObject?.betType == "Yes"
                      ? "back"
                      : "lay"
                  }`}
                >
                  {betSlipObject?.betType}
                </span>
              ))}
            <h6 className="mb-0 fs-4 fw-bold">
              {betSlipObject?.teamName || betSlipObject?.object?.runnerName}
            </h6>
          </div>
          <button
            onClick={() => {
              setShowBetDialog(false);
              setResetBetPosition(true);
            }}
            className="bg-transparent border-0 p-0 fs-3"
          >
            <RxCross2 />
          </button>
        </div>

        <div className="player-body">
          <Form className="border-0">
            <Row className="gx-2 mb-2 keyboard-div">
              <Col xs={6}>
                <div className="add-bet text-center">
                  <span className="d-block">Odds</span>
                  <div className="d-flex align-items-center">
                    <Button
                      // disabled={
                      //   betSlipObject?.playType == "betFair" ? false : true
                      // }
                      type="button"
                      disabled={true}
                      // onClick={
                      // (e) => e.preventDefault()
                      // handelNumberChangeAddMinus("minus", "odds")
                      // }
                    >
                      -
                    </Button>
                    <input
                      type="text"
                      inputmode="none"
                      className="input-form-control"
                      maxLength={7}
                      // disabled={
                      //   betSlipObject?.playType == "betFair" ? false : true
                      // }
                      disabled={true}
                      value={
                        betSlipObject?.playType == "fancy"
                          ? `${betSlipObject?.odds}/${betSlipObject?.object?.pt}`
                          : betSlipObject?.odds
                      }
                      // onClick={(e) => {
                      //   e.target.blur();
                      //   setKeyboard({ ...keyboard, focus: "odds" });
                      // }}
                      // onKeyDown={(e) => e.preventDefault()}
                    />
                    <Button
                      type="button"
                      // disabled={
                      //   betSlipObject?.playType == "betFair" ? false : true
                      // }
                      disabled={true}
                      // onClick={() => handelNumberChangeAddMinus("plus", "odds")}
                    >
                      +
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={6}>
                <div className="add-bet text-center">
                  <span className="d-block">Stake</span>
                  <div className="d-flex align-items-center">
                    <Button
                      disabled={bidPriceDisabled}
                      onClick={() =>
                        handelNumberChangeAddMinus("minus", "price")
                      }
                    >
                      -
                    </Button>
                    <input
                      type="number"
                      inputmode="none"
                      className="input-form-control"
                      onKeyDown={(e) => e.preventDefault()}
                      value={betSlipObject?.bidPrice}
                      onClick={(e) => {
                        e.target.blur();
                        setKeyboard({ ...keyboard, focus: "price" });
                      }}
                      maxLength={7}
                    />
                    <Button
                      disabled={bidPriceDisabled}
                      onClick={() =>
                        handelNumberChangeAddMinus("plus", "price")
                      }
                    >
                      +
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="fix-bet-price d-flex align-items-center">
              <div
                className={`fix-bet-btn  d-flex align-items-center w-100 flex-wrap fix-bet-btn-new `}
              >
                {numberArray?.map((item) => {
                  return (
                    <Button
                      onClick={() => {
                        handelNumberChange(item?.key);
                      }}
                      className="bg-yellow btn"
                    >
                      +{item?.value}
                    </Button>
                  );
                })}
              </div>
              {/* <div>
                <Button
                  className="bg-yellow"
                  onClick={() => handleStackSetting()}
                >
                  <AiFillSetting className="fs-4" />
                </Button>
              </div> */}
            </div>

            <div className="betting-numbers d-flex align-items-center mb-2">
              <div className="d-flex flex-wrap bet-numeric">
                {numberArrayCount?.map((res) => {
                  return (
                    <Button onClick={() => handelDialPad(res)} className="btn">
                      {res}
                    </Button>
                  );
                })}
              </div>
              <div className="cross-bet">
                <Button
                  onClick={() => {
                    clear();
                  }}
                  className="btn h-100"
                >
                  <img src="../../assets/images/casino-icon/cross.png" alt="" />
                </Button>
              </div>
            </div>
            {/*             
            <div className="d-flex align-items-center min-max justify-content-end mb-3">
              <FaArrowsAltV />
              <span>min/max</span>
              <strong>
                {limit?.minAmount || 1}/{limit?.maxAmount || 100}
              </strong>
            </div> */}

            <Button
              disabled={betSlipObject?.isBetDisabled ? true : bidPriceDisabled}
              onClick={() => {
                placeBet();
              }}
              className="bg-yellow w-100 fs-5 py-2 fw-bold place-bet-button"
            >
              Place Bet
            </Button>
          </Form>
        </div>
        {/* {betLoader && (
          <div className="loading-bet">
            <img src={loading} />
          </div>
        )} */}

        {betLoader && (
          <WifiLoader />
          // <div className="loading-bet-new">
          //   <div class="wifi-symbol">
          //     <div class="wifi-circle first"></div>
          //     <div class="wifi-circle second"></div>
          //     <div class="wifi-circle third"></div>
          //     <div class="wifi-circle fourth"></div>
          //   </div>
          // </div>
        )}
        {showStackSetting && (
          <StackSetting handleStackSetting={handleStackSetting} />
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default PlaysBet;
